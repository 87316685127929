<template>
  <div>
    <el-dialog :visible.sync="modalShow" :close-on-click-modal="false" :width="modalWidth" top="5px" append-to-body>
      <template #title>
        <h3 class="m-0">
          {{ actionText + '出貨退貨' }}
        </h3>
      </template>
      <el-form
        v-if="modalShow"
        :ref="formRefs"
        :model="row"
        :rules="rules"
        :disabled="!$store.getters['permission/check']([51])"
        label-width="100px"
      >
        <div class="card">
          <div v-if="row.sno" class="card-header px-2">
            <h5 class="m-0">
              出貨退貨編號<span class="text-monospace">{{ row.sno }}</span>
            </h5>
          </div>
          <div class="card-body p-3">
            <div class="form-row">
              <el-form-item class="col-md-6 col-lg-4" label="客戶名稱" prop="customer_id">
                <el-select
                  v-model.number="row.customer_id"
                  :disabled="row.sno !== null || !choose"
                  placeholder="請先選擇客戶"
                  filterable
                  @change="onCustomerChange"
                >
                  <el-option
                    v-for="item in $store.getters['customer/consumers']"
                    :key="item.id"
                    :label="item.name"
                    :value="Number(item.id)"
                  />
                </el-select>
              </el-form-item>
              <el-form-item v-show="row.order_id" class="col" label="訂單編號">
                <h6 class="text-monospace">{{ row.order && row.order.sno }}</h6>
              </el-form-item>
              <el-form-item v-show="row.order_id" class="col" label="訂單數量">
                <h6>{{ row.order && row.order.quantity }} 個</h6>
              </el-form-item>
            </div>
            <div v-show="row.customer_id && row.shipment_id">
              <div class="form-row">
                <el-form-item class="col-12" label="產品名稱">
                  <h6>
                    {{ row.product && `(${row.product.alias_id}) ${row.product.name}` }}
                    <a
                      v-if="$store.getters['permission/check']([51]) && choose"
                      href="#"
                      @click.prevent="onCustomerChange(row.customer_id)"
                    >
                      <fa icon="search" fixed-width />重新選擇
                    </a>
                  </h6>
                </el-form-item>
                <el-form-item class="col-md-6 col-lg-4" label="出貨編號">
                  <h6 class="text-monospace">{{ row.shipment && row.shipment.sno }}</h6>
                </el-form-item>
                <el-form-item class="col-md-6 col-lg-4" label="出貨日期">
                  <h6>{{ row.shipment && row.shipment.date }}</h6>
                </el-form-item>
                <el-form-item class="col-md-6 col-lg-4" label="出貨數量">
                  <h6>{{ row.shipment && row.shipment.quantity }} 個</h6>
                </el-form-item>
                <el-form-item class="col-md-6 col-lg-4" label="處置方式" prop="disposal">
                  <el-radio-group v-model="row.disposal">
                    <el-radio :label="0">扣款</el-radio>
                    <el-radio :label="1">折讓</el-radio>
                    <el-radio :label="2">補送</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item class="col-md-6 col-lg-4" label="後續處理" prop="subsequent">
                  <el-radio-group v-model="row.subsequent" @change="onSubsequentChange">
                    <el-radio :label="1">重工</el-radio>
                    <el-radio :label="0">報廢</el-radio>
                  </el-radio-group>
                </el-form-item>
                <div class="w-100"></div>
                <el-form-item class="col-md-6 col-lg-4" label="退貨日期" prop="date">
                  <el-date-picker v-model="row.date" value-format="yyyy-MM-dd" type="date" placeholder="退貨日期" />
                </el-form-item>
                <el-form-item class="col-md-6 col-lg-4" label="退貨數量" prop="quantity">
                  <el-input v-model.number="row.quantity" :min="1" type="number" placeholder="退貨數量">
                    <template #append> 個 </template>
                  </el-input>
                </el-form-item>
                <el-form-item class="col-md-6 col-lg-4" label="產品單價" prop="unit_price">
                  <el-input v-model.number="row.unit_price" type="number" :min="0" placeholder="單價">
                    <template #prepend>
                      {{ row.customer && row.customer.currency | currencySymbol }}
                    </template>
                  </el-input>
                </el-form-item>
                <el-form-item class="col-md-6 col-lg-4" label="入庫倉儲" prop="warehouse_id">
                  <el-select v-model.number="row.warehouse_id" placeholder="入庫倉儲">
                    <el-option
                      v-for="item in returnWarehouses"
                      :key="item.id"
                      :label="item.name"
                      :value="Number(item.id)"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item class="col" label="退貨原因" prop="reason">
                  <el-input v-model="row.reason" placeholder="退貨原因" />
                </el-form-item>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row mt-3">
          <div class="col text-right">
            <b-btn variant="secondary" @click.prevent="modalShow = false">
              <fa icon="times-circle" fixed-width />取消
            </b-btn>
            <b-btn
              v-show="row.customer_id && row.shipment_id"
              type="submit"
              variant="primary"
              @click.prevent="onSubmit"
            >
              <fa icon="edit" fixed-width />{{ actionText }}
            </b-btn>
          </div>
        </div>
      </el-form>
    </el-dialog>
    <shipment-choose ref="shipmentChoose" @shipment-choose="onChoose" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { modify } from '@/plugins/mixins'
import { returnSubsequent, returnDisposal } from '@/plugins/consts'

export default {
  name: 'ShipmentReturnSetting',
  mixins: [modify],
  props: {
    choose: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      structure: {
        sno: null,
        order_id: null,
        customer_id: null,
        subsequent: 1,
        disposal: 0,
        shipment_id: null,
        warehouse_id: null,
        date: null,
        quantity: null,
        unit_price: null,
        reason: null,
        customer: {
          name: null,
        },
        order: {
          sno: null,
        },
        product: {
          alias_id: null,
          name: null,
        },
        shipment: {
          sno: null,
        },
      },
      rules: {
        customer_id: [{ required: true, message: '請選擇入庫倉儲', trigger: 'change' }],
        disposal: [{ required: true, message: '請選擇處置方式', trigger: 'blur' }],
        subsequent: [{ required: true, message: '請選擇後續處理方式', trigger: 'blur' }],
        date: [{ required: true, message: '請選擇退貨日期', trigger: 'change' }],
        quantity: [{ required: true, type: 'integer', min: 1, message: '請輸入出貨數量', trigger: 'change' }],
        unit_price: [
          { required: true, message: '請輸入單價', trigger: 'blur' },
          { type: 'number', min: 0, message: '單價最小為 0', trigger: 'blur' },
        ],
        warehouse_id: [{ required: true, message: '請選擇入庫倉儲', trigger: 'change' }],
        reason: [{ required: true, message: '請輸入退貨原因', trigger: 'blur' }],
      },
      returnSubsequent,
      returnDisposal,
    }
  },
  computed: {
    returnWarehouses() {
      return this.row.subsequent == 1
        ? this.$store.getters['warehouse/rework']
        : this.$store.getters['warehouse/scrapped']
    },
  },
  methods: {
    ...mapActions('shipmentReturn', ['fetchOne', 'doCreate', 'doUpdate']),
    onCustomerChange(val) {
      if (val === '') {
        this.$message.error('請先選擇客戶')
        return false
      }

      this.$refs.shipmentChoose.init(val)
    },
    onChoose(shipment) {
      Object.assign(this.row, {
        customer_id: shipment.customer_id,
        shipment_id: shipment.id,
        order_id: shipment.order_id,
        order: shipment.order,
        product: shipment.product,
        shipment: shipment,
        unit_price: +shipment.unit_price,
      })
      this.modalShow = true
    },
    onSubsequentChange() {
      this.row.warehouse_id = null
    },
  },
}
</script>
