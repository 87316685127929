<template>
  <div>
    <el-dialog :visible.sync="modalShow" :close-on-click-modal="false" :width="modalWidth" top="5px" append-to-body>
      <template #title>
        <h3 class="m-0">
          {{ actionText + '進貨退貨' }}
        </h3>
      </template>
      <el-form
        v-if="modalShow"
        :ref="formRefs"
        :model="row"
        :rules="rules"
        :disabled="!$store.getters['permission/check']([43])"
        label-width="120px"
      >
        <div class="card">
          <div v-if="row.sno" class="card-header px-2">
            <h5 class="m-0">
              進貨退貨編號：<span class="text-monospace">{{ row.sno }}</span>
            </h5>
          </div>
          <div class="card-body p-3">
            <div class="form-row">
              <el-form-item class="col-md-6 col-lg-4" label="供料廠商" prop="customer_id">
                <el-select
                  v-model.number="row.customer_id"
                  :disabled="row.sno !== null"
                  placeholder="請先選擇供料廠商"
                  filterable
                  @change="onCustomerChange"
                >
                  <el-option
                    v-for="item in $store.getters['customer/vendors']"
                    :key="item.id"
                    :label="item.name"
                    :value="Number(item.id)"
                  />
                </el-select>
              </el-form-item>
              <el-form-item v-show="row.purchase_id" class="col-md-6 col-lg-4" label="採購編號">
                <h6 class="text-monospace">{{ row.purchase && row.purchase.sno }}</h6>
              </el-form-item>
              <el-form-item v-show="row.receive_id" class="col-md-6 col-lg-4" label="進貨編號">
                <h6 class="text-monospace">{{ row.receive && row.receive.sno }}</h6>
              </el-form-item>
            </div>
            <div v-show="row.customer_id && row.receive_id">
              <div class="form-row">
                <el-form-item class="col-12" label="材料名稱">
                  <h6>
                    {{ row.material | materialName }}
                    <a
                      v-if="!hide && $store.getters['permission/check']([43])"
                      href="#"
                      @click.prevent="onCustomerChange(row.customer_id)"
                    >
                      <fa icon="search" fixed-width />重新選擇
                    </a>
                  </h6>
                </el-form-item>
                <div class="w-100"></div>
                <el-form-item class="col-md-6 col-lg-4" label="進貨日期">
                  <h6>{{ row.receive && row.receive.date }}</h6>
                </el-form-item>
                <el-form-item class="col-md-6 col-lg-4" label="進貨數量">
                  <h6>{{ row.receive && row.receive.quantity }} 個</h6>
                </el-form-item>
                <el-form-item v-if="$store.getters['permission/check']([1])" class="col-md-6 col-lg-4" label="進貨單價">
                  <h6>{{ row.receive && row.receive.unit_price | currency }}</h6>
                </el-form-item>
                <el-form-item class="col-md-6 col-lg-4" label="退貨日期" prop="date">
                  <el-date-picker v-model="row.date" type="date" value-format="yyyy-MM-dd" placeholder="退貨日期" />
                </el-form-item>
                <el-form-item class="col-md-6 col-lg-4" label="退貨數量" prop="quantity">
                  <el-input v-model.number="row.quantity" :min="1" type="number" placeholder="退貨數量">
                    <template #append> 個 </template>
                  </el-input>
                </el-form-item>
                <el-form-item class="col" label="退貨原因" prop="reason">
                  <el-input v-model="row.reason" placeholder="退貨原因" />
                </el-form-item>
                <el-form-item v-if="$store.getters['permission/check']([1])" class="col-md-6 col-lg-4" label="小記金額">
                  <h6>
                    {{ subTotal | currency }}
                  </h6>
                </el-form-item>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row mt-3">
          <div class="col text-right">
            <b-btn variant="secondary" @click.prevent="modalShow = false">
              <fa icon="times-circle" fixed-width />取消
            </b-btn>
            <b-btn
              v-if="$store.getters['permission/check']([43])"
              type="submit"
              variant="primary"
              @click.prevent="onSubmit"
            >
              <fa icon="edit" fixed-width />{{ actionText }}
            </b-btn>
          </div>
        </div>
      </el-form>
    </el-dialog>
    <receive-choose ref="receiveChoose" @done="onReceiveChosen" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { modify } from '@/plugins/mixins'

export default {
  name: 'ReceiveReturnSetting',
  mixins: [modify],
  props: {
    hide: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      structure: {
        sno: null,
        purchase_id: null,
        customer_id: null,
        receive_id: null,
        warehouse_id: null,
        date: null,
        quantity: null,
        reason: null,
        receive: {
          sno: null,
        },
        purchase: {
          sno: null,
        },
        material: {},
      },
      rules: {
        customer_id: [{ required: true, message: '請選擇入庫倉儲', trigger: 'change' }],
        subsequent: [{ required: true, message: '請選擇後續處理方式', trigger: 'blur' }],
        date: [{ required: true, message: '請選擇退貨日期', trigger: 'change' }],
        quantity: [{ required: true, type: 'integer', min: 1, message: '請輸入進貨數量', trigger: 'change' }],
        warehouse_id: [{ required: true, message: '請選擇入庫倉儲', trigger: 'change' }],
        reason: [{ required: true, message: '請輸入退貨原因', trigger: 'blur' }],
      },
    }
  },
  computed: {
    subTotal() {
      if (!this.row.quantity || !this.row.receive?.unit_price || !this.row.receive?.unit_weight) {
        return
      }

      return (
        this.row.quantity *
        this.row.receive.unit_price *
        (this.row.receive.is_measure_price ? this.row.receive.unit_weight : 1)
      )
    },
  },
  methods: {
    ...mapActions('receiveReturn', ['fetchOne', 'doCreate', 'doUpdate']),
    onCustomerChange(val) {
      if (val === '') {
        this.$message.error('請先選擇客戶')
        return false
      }

      this.$refs.receiveChoose.init(val)
    },
    onReceiveChosen(receive) {
      Object.assign(this.row, {
        receive_id: receive.id,
        purchase_id: receive.purchase.id,
        purchase_item_id: receive.purchase_item_id,
        customer_id: receive.customer_id,
        material_id: receive.material_id,
        receive: receive,
        material: receive.material,
        purchase: receive.purchase,
      })
    },
  },
}
</script>
