<template>
  <div class="table-responsive">
    <table v-for="(item, index) in items" :key="index" class="table table-bordered table-striped table-sm display">
      <tbody>
        <tr>
          <td style="width: 13%">採購編號</td>
          <td style="width: 20%" class="text-monospace">{{ item.purchase && item.purchase.sno }}</td>
          <td style="width: 13%">種類</td>
          <td style="width: 20%">
            <b-badge :variant="colors[item.type]">{{ purchaseItemTypes[item.type] }}</b-badge>
          </td>
          <td style="width: 13%">狀態</td>
          <td>
            <b-badge :variant="colors[item.status]">{{ purchaseItemStatus[item.status] }}</b-badge>
          </td>
        </tr>
        <tr>
          <td>供料廠商</td>
          <td>{{ item.purchase && item.purchase.customer.name }}</td>
          <td>材料名稱</td>
          <td>{{ item.material | materialName }}</td>
          <td>採購數量</td>
          <td>{{ item.quantity }}</td>
        </tr>
        <tr>
          <td>送貨地點</td>
          <td>{{ item.target && item.target.name }}</td>
          <td>入庫到</td>
          <td>{{ item.warehouse && item.warehouse.name }}</td>
          <td>備註</td>
          <td>{{ item.comment }}</td>
        </tr>
        <tr v-if="item.receives && item.receives.length > 0">
          <td>進貨記錄</td>
          <td class="p-0" colspan="5">
            <table class="table table-striped table-bordered table-sm m-0">
              <thead>
                <tr>
                  <th style="width: 180px">進貨編號</th>
                  <th>廠商訂單號</th>
                  <th>到貨日期</th>
                  <th>承辦人</th>
                  <th class="text-right">到貨數量</th>
                  <th class="text-right">每單位重量</th>
                  <th class="text-right">單價</th>
                  <th>備註</th>
                  <th style="width: 50px">操作</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="receive in item.receives" :key="receive.id" class="bg-white">
                  <td class="text-monospace">
                    <a href="#" @click.prevent="$emit('receive-update', receive.id)">
                      {{ receive.sno }} <fa icon="edit" />
                    </a>
                  </td>
                  <td>{{ receive.customer_order_no }}</td>
                  <td>{{ receive.date }}</td>
                  <td>{{ receive.user && receive.user.name }}</td>
                  <td class="text-right">{{ receive.quantity }}</td>
                  <td class="text-right">{{ receive.unit_weight | measure(receive.measure) }}</td>
                  <td class="text-right">{{ receive.unit_price | currency }}</td>
                  <td>{{ receive.comment }}</td>
                  <td>
                    <b-btn variant="danger" size="sm" @click.prevent="$emit('receive-delete', receive.id)">
                      <fa icon="trash" fixed-width />
                    </b-btn>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { colors, purchaseItemTypes, purchaseItemStatus } from '@/plugins/consts'

export default {
  name: 'PurchaseItemList',
  props: {
    items: {
      type: Array,
      default() {
        return [
          {
            purchase: { sno: null },
            target: { name: null },
          },
        ]
      },
    },
  },
  data() {
    return {
      colors,
      purchaseItemTypes,
      purchaseItemStatus,
    }
  },
}
</script>
