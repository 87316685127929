<template>
  <el-dialog :visible.sync="modalShow" :close-on-click-modal="false" :width="modalWidth" top="5px" append-to-body>
    <template #title>
      <h3 class="m-0">產品盤點</h3>
    </template>
    <div class="alert alert-warning" role="alert">
      <strong>注意</strong>：如果庫存數量正確，請「保留空白」，有輸入「校正庫存」的欄位才會做「庫存校正」
    </div>
    <el-form v-if="modalShow" :ref="formRefs" :model="row" label-position="top" size="mini">
      <h4>產品庫存</h4>
      <table class="table table-border table-hover table-sm">
        <thead>
          <tr>
            <th>產品名稱</th>
            <th style="width: 140px">倉儲位置</th>
            <th style="width: 40px"></th>
            <th class="text-right" style="width: 100px">系統庫存</th>
            <th class="text-right" style="width: 100px">校正庫存</th>
            <th style="width: 44px" />
          </tr>
        </thead>
        <tbody>
          <tr v-for="(quantity, index) in row.product.quantity" :key="index" class="vm">
            <template v-if="index === 0">
              <td>{{ `(${row.product.alias_id}) ${row.product.name}` }}</td>
            </template>
            <template v-else>
              <td />
            </template>
            <td class="p-0">
              <el-form-item
                :rules="{ required: true, message: ' ', trigger: 'change' }"
                :prop="`product.quantity[${index}].id`"
                class="m-0 p-0"
              >
                <el-select
                  v-model.number="quantity.id"
                  placeholder="倉儲位置"
                  filterable
                  @change="onWarehouseChange(quantity)"
                >
                  <el-option
                    v-for="warehouse in $store.getters['warehouse/inventory']"
                    :key="warehouse.id"
                    :label="warehouse.name"
                    :value="warehouse.id"
                  />
                </el-select>
              </el-form-item>
            </td>
            <td>
              <b-badge v-if="quantity.is_default" variant="teal">預設</b-badge>
            </td>
            <td class="text-right">{{ quantity.qty }}</td>
            <td class="p-0">
              <el-form-item
                :rules="{
                  type: 'integer',
                  min: 0,
                  message: ' ',
                  trigger: 'change',
                  transform: (val) => Number(val),
                }"
                :prop="`product.quantity[${index}].new_qty`"
                class="m-0 p-0"
              >
                <el-input v-model.number="quantity.new_qty" :min="0" type="number" />
              </el-form-item>
            </td>
            <td class="text-center">
              <span
                v-if="quantity.is_default"
                class="text-success"
                @click.prevent="onAddWarehouse(row.product.quantity)"
              >
                <fa icon="plus-circle" class="fa-lg" fixed-width />
              </span>
              <span v-else class="text-danger" @click.prevent="onRemoveWarehouse(row.product.quantity, index)">
                <fa icon="times-circle" class="fa-lg" fixed-width />
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <h4>配件庫存</h4>
      <div v-if="row.product.parts && row.product.parts.length > 0" :key="`part-${row.product.id}`">
        <table class="table table-border table-hover table-striped table-sm m-0">
          <thead>
            <tr>
              <th class="border-bottom-0">配件名稱</th>
              <th class="border-bottom-0" style="width: 140px">倉儲位置</th>
              <th class="border-bottom-0" style="width: 40px"></th>
              <th class="text-right border-bottom-0" style="width: 100px">系統庫存</th>
              <th class="text-right border-bottom-0" style="width: 100px">校正庫存</th>
              <th class="border-bottom-0" style="width: 44px" />
            </tr>
          </thead>
        </table>
        <table
          v-for="(part, index) in row.product.parts"
          :key="part.id"
          class="table table-border table-hover table-sm m-0"
        >
          <tbody>
            <tr v-for="(quantity, index2) in part.quantity" :key="index2" class="vm">
              <template v-if="index2 === 0">
                <td>{{ `${part.alias_id}` }}</td>
              </template>
              <template v-else>
                <td />
              </template>
              <td class="p-0" style="width: 140px">
                <el-form-item
                  :key="`paw-${part.id}`"
                  :rules="{ required: true, message: ' ', trigger: 'change' }"
                  :prop="`product.parts[${index}].quantity[${index2}].id`"
                  class="m-0 p-0"
                >
                  <el-select
                    v-model.number="quantity.id"
                    placeholder="倉儲位置"
                    filterable
                    @change="onWarehouseChange(quantity)"
                  >
                    <el-option
                      v-for="warehouse in $store.getters['warehouse/part']"
                      :key="warehouse.id"
                      :label="warehouse.name"
                      :value="warehouse.id"
                    />
                  </el-select>
                </el-form-item>
              </td>
              <td style="width: 40px">
                <b-badge v-if="quantity.is_default" variant="teal">預設</b-badge>
              </td>
              <td class="text-right" style="width: 100px">{{ quantity.qty }}</td>
              <td class="p-0" style="width: 100px">
                <el-form-item
                  :key="`paq-${part.id}`"
                  :rules="{
                    type: 'integer',
                    min: 0,
                    message: ' ',
                    trigger: 'change',
                    transform: (val) => Number(val),
                  }"
                  :prop="`product.parts[${index}].quantity[${index2}].new_qty`"
                  class="m-0 p-0"
                >
                  <el-input v-model.number="quantity.new_qty" :min="0" type="number" />
                </el-form-item>
              </td>
              <td class="text-center" style="width: 40px">
                <span v-if="quantity.is_default" class="text-success" @click.prevent="onAddWarehouse(part.quantity)">
                  <fa icon="plus-circle" class="fa-lg" fixed-width />
                </span>
                <span v-else class="text-danger" @click.prevent="onRemoveWarehouse(part.quantity, index2)">
                  <fa icon="times-circle" class="fa-lg" fixed-width />
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p v-else>該產品目前尚無任何配件</p>
      <div v-if="partsHasMaterial && partsHasMaterial.length > 0" :key="`material-${row.product.id}`">
        <h4 class="mt-3">材料庫存</h4>
        <table class="table table-border table-hover table-striped table-sm m-0">
          <thead>
            <tr>
              <th class="border-bottom-0">材料名稱</th>
              <th class="border-bottom-0" style="width: 140px">倉儲位置</th>
              <th class="border-bottom-0" style="width: 40px"></th>
              <th class="text-right border-bottom-0" style="width: 100px">系統庫存</th>
              <th class="text-right border-bottom-0" style="width: 100px">校正庫存</th>
              <th class="border-bottom-0" style="width: 44px" />
            </tr>
          </thead>
        </table>
        <table
          v-for="part in partsHasMaterial"
          :key="part.material.id"
          class="table table-border table-hover table-sm m-0"
        >
          <tbody>
            <tr v-for="(quantity, index2) in part.material.quantity" :key="index2" class="vm">
              <template v-if="index2 === 0">
                <td>{{ part.material | materialName }}</td>
              </template>
              <template v-else>
                <td />
              </template>
              <td class="p-0" style="width: 140px">
                <el-form-item
                  :key="`mw1-${part.material_id}`"
                  :rules="{ required: true, message: ' ', trigger: 'change' }"
                  :prop="`product.parts[${row.product.parts.indexOf(part)}].material.quantity[${index2}].id`"
                  class="m-0 p-0"
                >
                  <el-select
                    :key="`mw2-${part.material_id}`"
                    v-model.number="quantity.id"
                    placeholder="倉儲位置"
                    filterable
                    @change="onWarehouseChange(quantity)"
                  >
                    <el-option
                      v-for="warehouse in $store.getters['warehouse/material']"
                      :key="warehouse.id"
                      :label="warehouse.name"
                      :value="warehouse.id"
                    />
                  </el-select>
                </el-form-item>
              </td>
              <td style="width: 40px">
                <b-badge v-if="quantity.is_default" variant="teal">預設</b-badge>
              </td>
              <td class="text-right" style="width: 100px">{{ quantity.qty }}</td>
              <td class="p-0" style="width: 100px">
                <el-form-item
                  :key="`mq1-${part.material_id}`"
                  :rules="{
                    type: 'integer',
                    min: 0,
                    message: ' ',
                    trigger: 'change',
                    transform: (val) => Number(val),
                  }"
                  :prop="`product.parts[${row.product.parts.indexOf(part)}].material.quantity[${index2}].new_qty`"
                  class="m-0 p-0"
                >
                  <el-input :key="`mq2-${part.material_id}`" v-model.number="quantity.new_qty" :min="0" type="number" />
                </el-form-item>
              </td>
              <td class="text-center" style="width: 40px">
                <span
                  v-if="quantity.is_default"
                  class="text-success"
                  @click.prevent="onAddWarehouse(part.material.quantity)"
                >
                  <fa icon="plus-circle" class="fa-lg" fixed-width />
                </span>
                <span v-else class="text-danger" @click.prevent="onRemoveWarehouse(part.material.quantity, index2)">
                  <fa icon="times-circle" class="fa-lg" fixed-width />
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="form-row mt-3">
        <div class="col text-right">
          <b-btn variant="secondary" @click.prevent="modalShow = false">
            <fa icon="times-circle" fixed-width />取消
          </b-btn>
          <b-btn type="submit" variant="primary" @click.prevent.stop="onSubmit">
            <fa icon="edit" fixed-width />修改
          </b-btn>
        </div>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
import { orderBy } from 'lodash-es'
import { mapActions } from 'vuex'
import { modify } from '@/plugins/mixins'

export default {
  name: 'ProductInventoryOne',
  mixins: [modify],
  data() {
    return {
      structure: {
        product: {
          parts: [],
        },
      },
      modalMaxWidth: 1000,
    }
  },
  computed: {
    partsHasMaterial() {
      return orderBy(
        this.row.product.parts?.filter((x) => x.material_id !== null),
        ['material.is_plate', 'material.type', 'material.name'],
        ['desc', 'asc', 'asc']
      )
    },
  },
  created() {
    this.submitAction = this.doInventory
  },
  methods: {
    ...mapActions('product', ['fetchOne', 'doInventory']),
    onUpdate(id) {
      this.resetRow()
      this.formAction = 'update'
      this.fetchOne(id).then((product) => {
        // 配件
        product.parts.forEach((part) => {
          // 材料預設庫存
          if (part.material) {
            if (part.material.quantity.find((qty) => qty.id == part.material.warehouse_id) === undefined) {
              part.material.quantity.push({ id: part.material.warehouse_id, qty: 0 })
            }

            // 配件排序
            part.material.quantity = orderBy(
              part.material.quantity.map((q) => {
                q.new_qty = null
                q.is_default = +q.id === part.material.warehouse_id
                return q
              }),
              ['is_default', 'id'],
              ['desc', 'asc']
            )
          }

          // 配件預設庫存
          if (part.quantity.find((qty) => qty.id == part.warehouse_id) === undefined) {
            part.quantity.push({ id: part.warehouse_id, qty: 0 })
          }

          // 配件排序
          part.quantity = orderBy(
            part.quantity.map((q) => {
              q.new_qty = null
              q.is_default = +q.id === part.warehouse_id
              return q
            }),
            ['is_default', 'id'],
            ['desc', 'asc']
          )
        })

        // 預設庫存
        if (product.quantity.find((qty) => qty.id == product.warehouse_id) === undefined) {
          product.quantity.push({ id: +product.warehouse_id, qty: 0, is_default: true })
        }

        // 排序
        product.quantity = orderBy(
          product.quantity.map((q) => {
            q.new_qty = null
            q.is_default = +q.id === +product.warehouse_id
            return q
          }),
          ['is_default', 'id'],
          ['desc', 'asc']
        )

        this.$set(this.row, 'product', product)
        this.modalShow = true
      })
    },
    onAddWarehouse(quantity) {
      quantity.push({
        id: null,
        qty: null,
        new_qty: null,
        is_default: false,
      })
    },
    onRemoveWarehouse(quantity, index) {
      quantity.splice(index, 1)
    },
    onWarehouseChange(quantity) {
      if (quantity.is_default) {
        quantity.new_qty = quantity.qty
      }
    },
  },
}
</script>
