<template>
  <el-dialog :visible.sync="modalShow" :close-on-click-modal="false" :width="modalWidth" top="5px" append-to-body>
    <template #title>
      <h3 class="m-0">
        {{ `${actionText}預設製程` }}
      </h3>
    </template>
    <el-form
      v-if="modalShow"
      :ref="formRefs"
      :model="row"
      :rules="rules"
      :disabled="!$store.getters['permission/check']([75])"
      label-width="100px"
    >
      <div class="card">
        <div class="card-body p-3">
          <div class="alert alert-danger" role="alert">
            <strong>注意</strong>
            ：種類為「委外加工」時，不應有「本廠」，其餘種類均需有「本廠」；請不要「合併製程」，如「沖孔切邊」應修正為「沖孔」+「切邊」兩個製程
          </div>
          <div class="form-row">
            <el-form-item class="col-md-6" label="製程種類" prop="type">
              <el-select v-model.number="row.type" placeholder="- 請選擇 -" @change="onTypeChange">
                <el-option
                  v-for="(item, index) in defaultProcessTypes"
                  :key="index"
                  :label="item"
                  :value="Number(index)"
                />
              </el-select>
            </el-form-item>
            <el-form-item class="col-md-6" label="製程名稱" prop="name">
              <!-- <el-input v-model.trim="row.name" placeholder="製程名稱" /> -->
              <el-autocomplete
                v-model.trim="row.name"
                placeholder="製程名稱"
                :fetch-suggestions="querySearch"
                @select="(val) => (row.name = val.name)"
              >
                <template #default="{ item }">
                  {{ item.name }}
                </template>
              </el-autocomplete>
            </el-form-item>
            <el-form-item class="col-12" label="委託廠商" prop="customers">
              <el-select v-model.number="row.customers" multiple filterable value-key="id" placeholder="- 請選擇 -">
                <el-option v-for="item in outsources" :key="item.id" :label="item.name" :value="item" />
              </el-select>
            </el-form-item>
          </div>
        </div>
      </div>
      <div class="form-row mt-3">
        <div class="col text-right">
          <b-btn variant="secondary" @click.prevent="modalShow = false"><fa icon="times-circle" />取消</b-btn>
          <b-btn
            v-if="$store.getters['permission/check']([75])"
            type="submit"
            variant="primary"
            @click.prevent="onSubmit"
          >
            <fa icon="edit" fixed-width />{{ actionText }}
          </b-btn>
        </div>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
import { reject } from 'lodash-es'
import { mapActions } from 'vuex'
import { modify, unique } from '@/plugins/mixins'
import { defaultProcessTypes } from '@/plugins/consts'

export default {
  name: 'DefaultProcessSetting',
  mixins: [modify, unique],
  data() {
    return {
      structure: {
        id: null,
        type: null,
        name: null,
        customers: [],
      },
      rules: {
        type: [{ required: true, message: '請選擇製程種類', trigger: 'change' }],
        name: [
          { required: true, message: '請輸入製程名稱', trigger: 'blur' },
          { validator: this.doCheckUnique, message: '製程名稱重複，請重新確認', trigger: 'change' },
        ],
        customers: [{ required: true, type: 'array', message: '請選擇委託廠商', trigger: 'change' }],
      },
      defaultProcessTypes,
      modalMaxWidth: 800,
    }
  },
  computed: {
    outsources() {
      let outsources = this.$store.getters['customer/outsources']
      if (this.row.type == 4) {
        outsources = reject(outsources, { id: 0 })
      }

      if (this.row.type == 0) {
        outsources = [outsources.find((item) => item.id == 0)]
      }

      return outsources
    },
  },
  methods: {
    ...mapActions('defaultProcess', ['fetchOne', 'doCreate', 'doUpdate', 'checkUnique']),
    querySearch(string, cb) {
      const processes = this.$store.getters['defaultProcess/all']
      const results = string ? processes.filter((item) => item.name.includes(string)) : processes
      cb(results)
    },
    onTypeChange(val) {
      this.row.customers = []
      if (val != 4) {
        this.row.customers.push(this.$store.getters['customer/outsources'].find((item) => item.id == 0))
      }
    },
  },
}
</script>
