<template>
  <div>
    <el-popover v-if="quantity.length > 0" ref="pop" placement="top-start" trigger="hover">
      <template #default>
        <table class="table table-striped table-sm m-0" style="font-size: 0.875rem">
          <thead>
            <tr>
              <th class="w-50">倉儲名稱</th>
              <th class="text-right">庫存數量</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in available" :key="`ava-${index}`">
              <td>{{ $store.getters['warehouse/name'](item.id) }}</td>
              <td class="text-right">{{ item.qty }}</td>
            </tr>
            <tr class="table-info">
              <td>有效數量</td>
              <td class="text-right">{{ availableQuantity }}</td>
            </tr>
            <tr v-if="required" class="table-warning">
              <td>未出貨</td>
              <td class="text-right">{{ required }}</td>
            </tr>
            <tr v-for="(item, index) in unavailable" :key="`unava-${index}`">
              <td>{{ $store.getters['warehouse/name'](item.id) }}</td>
              <td class="text-right">{{ item.qty }}</td>
            </tr>
          </tbody>
        </table>
      </template>
    </el-popover>
    <span v-popover:pop :style="{ cursor: availableQuantity > 0 ? 'help' : '' }">{{ availableQuantity }}</span>
  </div>
</template>

<script>
export default {
  name: 'Quantity',
  props: {
    quantity: {
      type: Array,
      default() {
        return []
      },
    },
    required: {
      type: Number,
      default: null,
    },
  },
  computed: {
    available() {
      return (
        this.quantity.filter(
          (item) => !this.$store.getters['warehouse/returns'].map((x) => x.id).includes(item['id'])
        ) || []
      )
    },
    unavailable() {
      return (
        this.quantity.filter((item) =>
          this.$store.getters['warehouse/returns'].map((x) => x.id).includes(item['id'])
        ) || []
      )
    },
    availableQuantity() {
      return this.available.map((x) => x.qty).reduce((a, b) => a + b, 0)
    },
  },
}
</script>
