<template>
  <el-dialog :visible.sync="modalShow" :close-on-click-modal="false" :width="modalWidth" top="5px" append-to-body>
    <template #title>
      <h3 class="m-0">
        {{ actionText + '應收帳款' }}
      </h3>
    </template>
    <el-form
      v-if="modalShow"
      :ref="formRefs"
      :model="row"
      :rules="rules"
      :disabled="!$store.getters['permission/check']([56])"
      label-width="100px"
    >
      <div class="card">
        <div class="card-header">
          <h5 class="m-0">
            應收帳款編號：<span class="text-monospace">{{ row.sno }}</span>
          </h5>
        </div>
        <div class="card-body p-3">
          <div class="form-row">
            <el-form-item class="col-md-6 col-lg-4" label="廠商名稱">
              <h6>{{ row.customer && row.customer.name }}</h6>
            </el-form-item>
            <el-form-item class="col-md-6 col-lg-4" label="種類">
              <h6>
                <b-badge :variant="colors[row.type + 1]">{{ accountsTypes[row.type] }}</b-badge>
              </h6>
            </el-form-item>
            <el-form-item class="col-md-6 col-lg-4" prop="status" label="狀態">
              <el-select v-model.number="row.status" placeholder="- 請選擇 -">
                <el-option
                  v-for="(item, index) in accountsReceivableStatus"
                  :key="index"
                  :label="item"
                  :value="Number(index)"
                />
              </el-select>
            </el-form-item>
            <el-form-item class="col-md-6 col-lg-4" label="應收金額" prop="receivable">
              <el-input v-model.number="row.receivable" type="number" placeholder="應收金額">
                <template #prepend>
                  {{ row.currency | currencySymbol }}
                </template>
              </el-input>
            </el-form-item>
            <el-form-item class="col-md-6 col-lg-4" label="應稅" prop="is_invoice">
              <el-switch
                v-model.number="row.is_invoice"
                :active-value="1"
                :inactive-value="0"
                active-text="是"
                inactive-text="否"
              />
            </el-form-item>
            <el-form-item class="col-md-6 col-lg-4" label="扣％" prop="is_discount">
              <el-switch
                v-model.number="row.is_discount"
                :active-value="1"
                :inactive-value="0"
                active-text="是"
                inactive-text="否"
              />
            </el-form-item>
            <el-form-item class="col-md-6 col-lg-4" label="小計金額">
              <h6>{{ subTotal | currency(row.currency) }}</h6>
            </el-form-item>
            <el-form-item class="col-md-6 col-lg-4" label="發票稅金">
              <h6>{{ row.tax | currency(row.currency) }}</h6>
            </el-form-item>
            <el-form-item class="col-md-6 col-lg-4" label="扣％金額">
              <h6>{{ row.discount | currency(row.currency) }}</h6>
            </el-form-item>
            <el-form-item class="col-md-6 col-lg-4" label="結算月份" prop="estimate_receive_date">
              <el-date-picker
                v-model="row.estimate_receive_date"
                type="month"
                format="yyyy-MM"
                value-format="yyyy-MM"
                placeholder="結算月份"
              />
            </el-form-item>
            <el-form-item
              :rules="[
                { required: row.status === 1, message: '請輸入已收金額', trigger: 'blur' },
                { type: 'number', message: '請輸入數字', trigger: 'change' },
              ]"
              class="col-md-6 col-lg-4"
              label="已收金額"
              prop="received"
            >
              <el-input v-model.number="row.received" type="number" placeholder="已收金額">
                <template #prepend>
                  {{ row.currency | currencySymbol }}
                </template>
              </el-input>
            </el-form-item>
            <el-form-item
              :rules="[{ required: row.status === 1, message: ' ', trigger: 'change' }]"
              class="col-md-6 col-lg-4"
              label="銷帳日期"
              prop="actual_receive_date"
            >
              <el-date-picker
                v-model="row.actual_receive_date"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="銷帳日期"
              />
            </el-form-item>
            <el-form-item class="col" label="備註" prop="comment">
              <el-input v-model.trim="row.comment" placeholder="備註" />
            </el-form-item>
          </div>
        </div>
      </div>
      <div v-if="row.targetable" class="mt-3">
        <h4>詳細內容</h4>
        <component :is="targetComponent" :target="row.targetable" @done="initial(row.id)" />
      </div>
      <div class="form-row">
        <div class="col text-right">
          <b-btn variant="secondary" @click.prevent="modalShow = false">
            <fa icon="times-circle" fixed-width />取消
          </b-btn>
          <b-btn
            v-if="$store.getters['permission/check']([56])"
            type="submit"
            variant="primary"
            @click.prevent="onSubmit"
          >
            <fa icon="edit" fixed-width />{{ actionText }}
          </b-btn>
        </div>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import { colors, accountsTypes, accountsReceivableStatus } from '@/plugins/consts'
import { modify } from '@/plugins/mixins'

export default {
  name: 'AccountsReceivableSetting',
  mixins: [modify],
  data() {
    return {
      structure: {
        targetable_type: 'App\\Models\\Shipment',
        currency: 0,
        is_invoice: 1,
        is_discount: 0,
        status: 2,
        receivable: 0,
        customer: {
          name: null,
          tax: 5,
          tax_discount: 3,
        },
      },
      rules: {
        is_invoice: [{ required: true, message: ' ', trigger: 'blur' }],
        is_discount: [{ required: true, message: ' ', trigger: 'blur' }],
        receivable: [{ required: true, message: ' ', trigger: 'blur' }],
        estimate_receive_date: [{ required: true, message: ' ', trigger: 'change' }],
      },
      colors,
      accountsTypes,
      accountsReceivableStatus,
    }
  },
  computed: {
    targetComponent() {
      switch (this.row.targetable_type) {
        case 'App\\Models\\Shipment':
          return 'AccountsReceivableShipment'
        case 'App\\Models\\ShipmentReturn':
          return 'AccountsReceivableShipmentReturn'
        default:
          return 'AccountsReceivableOrder'
      }
    },
    subTotal() {
      return this.row.receivable + this.row.tax - this.row.discount
    },
  },
  watch: {
    'row.is_invoice': {
      handler(val) {
        this.row.tax = val ? (this.row.receivable * this.row.customer.tax) / 100 : 0
      },
      immediate: true,
    },
    'row.is_discount': {
      handler(val) {
        this.row.discount = val ? (this.row.receivable * this.row.customer.tax_discount) / 100 : 0
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('accountsReceivable', ['fetchOne', 'doUpdate']),
    initial(id) {
      this.init(id)
      this.isDirty = true
    },
  },
}
</script>
