import NProgress from 'nprogress'
import router from '@/pages/Index/router'
import store from '@/store'
import initEcho from '@/plugins/laravel-echo'
import { sync } from 'vuex-router-sync'
import { Message } from 'element-ui'
import { omit, snakeCase } from 'lodash-es'

sync(store, router)

NProgress.configure({ showSpinner: false })

const whiteList = ['/login']
router.beforeEach(async (to, from, next) => {
  // console.log(to, from)
  NProgress.start()

  if (to.meta?.title) {
    document.title = to.meta.title
  }

  // 是否有 token
  if (!store.getters['auth/token']) {
    if (whiteList.includes(to.path)) {
      next()
    } else {
      next({ path: '/login' })
    }
  } else {
    if (to.path === '/login') {
      next({ path: '/dashboard' })
    } else {
      // 如果 vuex state 沒有 user 的資料，取得資料
      if (!store.getters['auth/check']) {
        try {
          let data = await store.dispatch('auth/fetchUser')
          await store.dispatch('permission/initRoutes', data.me.permissions)
          const preset = omit(data, 'me')
          await Object.keys(preset).forEach((key) => {
            store.commit(`${key}/SET_${snakeCase(key).toUpperCase()}`, preset[key])
          })
          await initEcho()
          next({ ...to, replace: true })
        } catch (err) {
          console.log(err)
          await store.dispatch('auth/resetAuth')
          Message.error('登入驗證失敗，請重新登入')
          next({ path: '/login' })
        }
      } else {
        next()
      }
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})

export { router, store }
