<template>
  <div>
    <el-popover
      v-if="prices.length > 0"
      ref="productPrice"
      :placement="placement"
      title="產品單價變更記錄"
      trigger="click"
    >
      <template #default>
        <table class="table table-striped table-sm m-0" style="font-size: 0.875rem">
          <thead>
            <tr>
              <th style="width: 70px">承辦人</th>
              <th style="width: 110px">變更時間</th>
              <th style="width: 120px">變更原因</th>
              <th style="width: 60px" class="text-right">舊單價</th>
              <th style="width: 60px" class="text-right">新單價</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in prices.slice(0, 5)" :key="index">
              <td>{{ item.user && item.user.name }}</td>
              <td>{{ item.created_at | Ymd }}</td>
              <td>{{ item.reason }}</td>
              <td class="text-right">{{ item.old_price }}</td>
              <td class="text-right">{{ item.new_price }}</td>
            </tr>
          </tbody>
        </table>
      </template>
    </el-popover>
    <a v-if="prices.length > 0" v-popover:productPrice style="cursor: help" @click.prevent>
      {{ unitPrice | currency(currencyValue, 3) }}
    </a>
    <span v-else>{{ unitPrice | currency(currencyValue, 3) }}</span>
  </div>
</template>

<script>
export default {
  name: 'ProductPrice',
  props: {
    prices: {
      type: Array,
      default() {
        return []
      },
    },
    unitPrice: { type: Number, default: null },
    currencyValue: { type: Number, default: 0, required: true },
    placement: { type: String, default: 'left-end' },
  },
}
</script>
