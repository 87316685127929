<template>
  <el-dialog :visible.sync="modalShow" title="配件庫存記錄" :width="modalWidth" top="5px" append-to-body>
    <el-table v-loading="isLoading" :data="rows" stripe border @sort-change="onSort">
      <el-table-column label="建立時間" prop="created_at" width="210" sortable="custom" class-name="text-monospace" />
      <el-table-column v-slot="{ row }" label="種類" align="center" width="80">
        <span :class="['badge', `badge-${colors[prefix(row.stockable_type)].color}`]">
          {{ colors[prefix(row.stockable_type)].type }}
        </span>
      </el-table-column>
      <el-table-column v-slot="{ row }" label="編號" width="150" class-name="text-monospace">
        {{ row.is_inventory ? '' : row.stockable && row.stockable.sno }}
      </el-table-column>
      <el-table-column prop="user.name" label="承辦人" width="100" />
      <el-table-column prop="warehouse.name" label="倉儲" width="120" />
      <el-table-column label="數量" prop="quantity" align="right" />
    </el-table>
    <pagination-modal :pagination="pagination" @page-change="(val) => (query.page = val)" />

    <div class="mt-3 text-right">
      <b-btn variant="secondary" @click.prevent="modalShow = false"> <fa icon="times-circle" fixed-width />關閉 </b-btn>
    </div>
  </el-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import { fetchData, modalControl } from '@/plugins/mixins'

export default {
  name: 'PartStockHistory',
  mixins: [fetchData, modalControl],
  data() {
    return {
      query: { part_id: null },
      colors: {
        OP: { color: 'primary', type: '加工完成' },
        XX: { color: 'success', type: '盤點' },
      },
      modalMaxWidth: 800,
    }
  },
  watch: {
    'query.page'() {
      this.getAll()
    },
  },
  methods: {
    ...mapActions('stockPart', ['fetchPage']),
    init(id) {
      if (!id) {
        return false
      }

      this.query.page = 1
      this.query.part_id = id
      this.getAll()
      this.modalShow = true
    },
    onSort(val) {
      this.$set(this.query, 'orderBy', (val.order && val.prop) || 'id')
      this.$set(this.query, 'sortedBy', val.order === 'ascending' ? 'asc' : 'desc')
      this.getAll()
    },
    // TODO: check this
    prefix(type) {
      switch (type) {
        case 'App\\Models\\ProcessOut':
          return 'OP'
        default:
          return 'XX'
      }
    },
    onDone() {
      this.isDirty = true
      if (this.query.page !== 1) {
        this.query.page = 1
      } else {
        this.getAll()
      }
    },
  },
}
</script>
