<template>
  <div>
    <el-popover
      v-if="history.length > 0"
      ref="deliveryDate"
      :placement="placement"
      title="交貨日期變更記錄"
      trigger="click"
    >
      <template #default>
        <table class="table table-striped table-sm m-0" style="font-size: 0.875rem">
          <thead>
            <tr>
              <th style="width: 160px">變更時間</th>
              <th style="width: 80px">承辦人</th>
              <th style="width: 120px">原定日期</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in history" :key="index">
              <td>{{ item.created_at | YmdHis }}</td>
              <td>{{ item.causer && item.causer.name }}</td>
              <td>{{ item.properties && item.properties.old.delivery_date }}</td>
            </tr>
          </tbody>
        </table>
      </template>
    </el-popover>
    <a v-if="history.length > 0" v-popover:deliveryDate style="cursor: help" @click.prevent>
      {{ deliveryDate }}<span v-if="isRush">🔥</span>
    </a>
    <span v-else>{{ deliveryDate }}<span v-if="isRush">🔥</span></span>
  </div>
</template>

<script>
export default {
  name: 'OrderDeliveryDate',
  props: {
    history: {
      type: Array,
      default: () => [],
    },
    deliveryDate: {
      type: String,
      default: '',
    },
    isRush: {
      type: Number,
      default: 0,
    },
    placement: {
      type: String,
      default: 'left-start',
    },
  },
}
</script>
