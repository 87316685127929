<template>
  <el-dialog :visible.sync="modalShow" :close-on-click-modal="false" :width="modalWidth" top="5px" append-to-body>
    <template #title>
      <h3 class="m-0">
        {{ `${actionText}倉儲` }}
      </h3>
    </template>
    <el-form
      v-if="modalShow"
      :ref="formRefs"
      :model="row"
      :rules="rules"
      :disabled="!$store.getters['permission/check']([79])"
      label-width="100px"
    >
      <div class="card">
        <div class="card-body p-3">
          <div class="form-row">
            <el-form-item class="col-md-6" label="倉儲種類" prop="type">
              <el-select v-model.number="row.type" placeholder="- 請選擇 -">
                <el-option v-for="(item, index) in warehouseTypes" :key="index" :label="item" :value="Number(index)" />
              </el-select>
            </el-form-item>
            <el-form-item class="col-md-6" label="倉儲名稱" prop="name">
              <el-input v-model.trim="row.name" placeholder="倉儲名稱" />
            </el-form-item>
            <el-form-item class="col-md-6" label="負責人" prop="user_id">
              <el-select v-model.number="row.user_id" placeholder="- 請選擇 -">
                <el-option
                  v-for="item in $store.getters['user/all']"
                  :key="item.id"
                  :label="item.name"
                  :value="Number(item.id)"
                />
              </el-select>
            </el-form-item>
            <el-form-item class="col-12" label="備註" prop="comment">
              <el-input v-model.trim="row.comment" type="textarea" placeholder="備註" />
            </el-form-item>
          </div>
        </div>
      </div>
      <div class="form-row mt-3">
        <div class="col text-right">
          <b-btn variant="secondary" @click.prevent="modalShow = false">
            <fa icon="times-circle" fixed-width />取消
          </b-btn>
          <b-btn
            v-if="$store.getters['permission/check']([79])"
            type="submit"
            variant="primary"
            @click.prevent="onSubmit"
          >
            <fa icon="edit" fixed-width />{{ actionText }}
          </b-btn>
        </div>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import { warehouseTypes } from '@/plugins/consts'
import { modify } from '@/plugins/mixins'

export default {
  name: 'WarehouseSetting',
  mixins: [modify],
  data() {
    return {
      structure: {
        type: null,
        name: null,
        user_id: null,
        comment: null,
      },
      rules: {
        type: [{ required: true, message: '請選擇倉儲種類', trigger: 'change' }],
        name: [{ required: true, message: '請輸入倉儲名稱', trigger: 'blur' }],
        user_id: [{ required: true, message: '請選擇負責人', trigger: 'change' }],
      },
      warehouseTypes,
      modalMaxWidth: 800,
    }
  },
  methods: {
    ...mapActions('warehouse', ['fetchOne', 'doCreate', 'doUpdate']),
  },
}
</script>
