<template>
  <el-dialog :visible.sync="modalShow" :close-on-click-modal="false" :width="modalWidth" top="5px" append-to-body>
    <template #title>
      <h3 class="m-0">
        {{ actionText + '採購' }}
      </h3>
    </template>
    <el-form
      v-if="modalShow"
      :ref="formRefs"
      :model="row"
      :rules="rules"
      :disabled="[0, 1].includes(row.status) || !$store.getters['permission/check']([29])"
      label-width="100px"
    >
      <div class="card">
        <div v-if="formAction !== 'create'" class="card-header">
          <h5 class="text-monospace m-0">採購單號：{{ row.sno }}</h5>
        </div>
        <div class="card-body p-3">
          <div class="form-row">
            <el-form-item class="col-md-6 col-lg-4" label="供料廠商" prop="customer_id">
              <el-select
                v-model.number="row.customer_id"
                placeholder="- 請選擇供料廠商 -"
                filterable
                @change="onCustomerChange"
              >
                <el-option
                  v-for="item in $store.getters['customer/vendors']"
                  :key="item.id"
                  :label="item.name"
                  :value="Number(item.id)"
                />
              </el-select>
            </el-form-item>
            <el-form-item class="col-md-6 col-lg-4" label="狀態" prop="status">
              <el-select v-model.number="row.status">
                <el-option v-for="(item, index) in purchaseStatus" :key="index" :label="item" :value="Number(index)" />
              </el-select>
            </el-form-item>
            <el-form-item class="col-md-6 col-lg-4" label="預付金額" prop="prepaid">
              <el-input v-model.number="row.prepaid" :min="0" type="number" placeholder="預付金額">
                <template #prepend>
                  {{ row.currency | currencySymbol }}
                </template>
              </el-input>
            </el-form-item>
            <div class="w-100"></div>
            <el-form-item class="col" label="備註" prop="comment">
              <el-input v-model.trim="row.comment" placeholder="備註" />
            </el-form-item>
          </div>
        </div>
      </div>
      <h5 class="d-flex mt-3">
        <span>採購項目</span>
        <b-btn v-if="formAction === 'create'" class="ml-auto" variant="success" size="sm" @click.prevent="onAddItem()">
          <fa icon="plus-circle" fixed-width />新增項目
        </b-btn>
      </h5>
      <purchase-setting-item
        :items.sync="row.items"
        :currency-val="row.currency"
        :form-action="formAction"
        @remove-item="onRemoveItem"
      />

      <div class="form-row mt-3">
        <div class="col d-flex justify-content-between">
          <div v-if="row.status === 2 && formAction !== 'create'" class="text-left">
            <b-btn
              v-if="$store.getters['permission/check']([29])"
              variant="success"
              @click.prevent="onStatus(row.id, 3)"
            >
              <fa icon="check-circle" fixed-width />允許
            </b-btn>
            <b-btn
              v-if="$store.getters['permission/check']([29])"
              variant="danger"
              @click.prevent="onStatus(row.id, 0)"
            >
              <fa icon="ban" fixed-width />駁回
            </b-btn>
          </div>
          <div class="ml-auto text-right">
            <b-btn variant="secondary" @click.prevent="modalShow = false">
              <fa icon="times-circle" fixed-width />取消
            </b-btn>
            <b-btn
              v-if="row.status > 1 && $store.getters['permission/check']([29])"
              type="submit"
              variant="primary"
              @click.prevent="onSubmit"
            >
              <fa icon="edit" fixed-width />{{ actionText }}
            </b-btn>
          </div>
        </div>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import { purchaseStatus, purchaseItemTypes, purchaseItemStatus, colors } from '@/plugins/consts'
import { modify } from '@/plugins/mixins'

export default {
  name: 'PurchaseSetting',
  mixins: [modify],
  data() {
    return {
      structure: {
        status: 3,
        customer_id: null,
        currency: 0,
        prepaid: 0,
        comment: null,
        items: [
          {
            status: 4,
            type: 1,
            purchase_id: null,
            ticket_id: null,
            process_id: null,
            target_id: null,
            warehouse_id: null,
            material_id: null,
            quantity: null,
            delivery_date: null,
            comment: null,
            receives: [],
          },
        ],
      },
      rules: {
        status: [{ required: true, message: '請選擇狀態', trigger: 'change' }],
        customer_id: [{ required: true, message: '請選擇客戶', trigger: 'blur' }],
        prepaid: [{ required: true, type: 'integer', message: '請輸入預付金額', trigger: 'blur' }],
      },
      purchaseStatus,
      purchaseItemTypes,
      purchaseItemStatus,
      colors,
    }
  },
  watch: {
    'row.status'(val) {
      if ([2, 3].includes(val) && this.formAction === 'create') {
        this.row.items.forEach((item) => {
          item.status = val === 3 ? 4 : 3
        })
      }
    },
  },
  methods: {
    ...mapActions('purchase', ['fetchOne', 'doCreate', 'doUpdate', 'doStatus']),
    onCustomerChange(val) {
      this.$set(
        this.row,
        'currency',
        this.$store.getters['customer/all'].find((item) => item.id == +val)?.currency || 0
      )
    },
    onAddItem() {
      this.row.items.push({
        status: this.row.status === 3 ? 4 : 3,
        type: 1,
        purchase_id: null,
        ticket_id: null,
        process_id: null,
        target_id: null,
        warehouse_id: null,
        material_id: null,
        quantity: null,
        delivery_date: null,
        comment: null,
        receives: [],
      })
    },
    onRemoveItem(index) {
      this.row.items.splice(index, 1)
      if (this.row.items.length < 1) {
        this.onAddItem()
      }
    },
    onStatus(id, status) {
      const text = status ? '通過' : '駁回'
      this.$confirm(`是否確定${text}？`, '注意', {
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      })
        .then(() => {
          this.doStatus({ id, status })
            .then(() => {
              this.$message.success('處理完成')
              this.isDirty = true
              this.modalShow = false
            })
            .catch((err) => {
              console.log(err)
            })
        })
        .catch(() => {
          this.$message.info('已取消')
        })
    },
  },
}
</script>
