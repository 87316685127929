<template>
  <table class="table table-bordered table-striped table-sm">
    <thead>
      <tr>
        <th style="width: 170px">出貨編號</th>
        <th>出貨日期</th>
        <th>種類</th>
        <th>承辦人</th>
        <th>出貨倉儲</th>
        <th class="text-center">送貨方式</th>
        <th class="text-right">出貨數量</th>
        <th class="text-right">樣品數量</th>
        <th>備註</th>
        <th style="width: 90px">操作</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="shipment in items" :key="shipment.id">
        <td class="text-monospace">
          <a href="#" @click.prevent="$emit('shipment-update', shipment.id)">{{ shipment.sno }} <fa icon="edit" /></a>
        </td>
        <td>{{ shipment.date }}</td>
        <td class="text-center">
          <b-badge :variant="colors[shipment.type]">
            {{ shipmentType[shipment.type] }}
          </b-badge>
        </td>
        <td>{{ shipment.user && shipment.user.name }}</td>
        <td>{{ shipment.warehouse && shipment.warehouse.name }}</td>
        <td class="text-center">
          <b-badge :variant="colors[shipment.delivery_method + 1]">
            {{ deliveryMethods[shipment.delivery_method] }}
          </b-badge>
        </td>
        <td class="text-right">{{ shipment.quantity }}</td>
        <td class="text-right">{{ shipment.sample_quantity }}</td>
        <td>{{ shipment.comment }}</td>
        <td>
          <b-btn variant="primary" size="sm" @click.prevent="$emit('shipment-return-create', shipment.id)">
            <fa icon="exchange-alt" fixed-width />
          </b-btn>
          <b-btn variant="danger" size="sm" @click.prevent="$emit('shipment-delete', shipment.id)">
            <fa icon="trash" fixed-width />
          </b-btn>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { colors, deliveryMethods, shipmentType } from '@/plugins/consts'
export default {
  name: 'ShipmentList',
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      colors,
      deliveryMethods,
      shipmentType,
    }
  },
}
</script>
