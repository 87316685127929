<template>
  <el-dialog :visible.sync="modalShow" :close-on-click-modal="false" :width="modalWidth" top="5px" append-to-body>
    <template #title>
      <h3 class="m-0">選擇出貨</h3>
    </template>
    <div class="w-50 mb-3">
      <el-input
        v-model.trim="query.search"
        placeholder="搜尋出貨編號、訂單編號、產品編號或名稱"
        clearable
        @change="getAll()"
      />
    </div>
    <el-table
      ref="shipmentTable"
      v-loading="isLoading"
      :data="rows"
      stripe
      border
      @sort-change="onSort"
      @selection-change="onSelected"
    >
      <el-table-column v-if="multi" type="selection" width="40" align="center" />
      <el-table-column prop="sno" label="出貨編號" width="130" class-name="text-monospace" sortable="custom" />
      <el-table-column prop="order.sno" label="訂單編號" width="130" class-name="text-monospace" />
      <el-table-column label="出貨日期" prop="date" width="120" sortable="custom" />
      <el-table-column v-slot="{ row }" label="種類" width="85" align="center">
        <b-badge :variant="colors[row.type]">
          {{ shipmentType[row.type] }}
        </b-badge>
      </el-table-column>
      <el-table-column v-slot="{ row }" label="產品名稱">
        {{ row.product && `(${row.product.alias_id}) ${row.product.name}` }}
      </el-table-column>
      <el-table-column label="出貨數量" prop="quantity" width="85" align="right" />
      <el-table-column v-if="!multi" v-slot="{ row }" label="選取" width="85">
        <b-btn size="sm" variant="primary" @click.prevent="onChoosen(row)">
          <fa icon="check-circle" fixed-width />選取
        </b-btn>
      </el-table-column>
    </el-table>
    <pagination-modal :pagination="pagination" @page-change="(val) => (query.page = val)" />
    <div class="mt-3 text-right">
      <b-btn v-show="multi" :disabled="selected.length == 0" type="submit" variant="primary" @click.prevent="onMulti">
        <fa icon="edit" fixed-width />選取
      </b-btn>
      <b-btn variant="secondary" @click.prevent="modalShow = false"><fa icon="times-circle" fixed-width />關閉</b-btn>
    </div>
  </el-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import { fetchData, modalControl } from '@/plugins/mixins'
import { colors, shipmentType } from '@/plugins/consts'
import { differenceBy, intersectionBy, uniqBy } from 'lodash-es'

export default {
  name: 'ShipmentChoose',
  mixins: [fetchData, modalControl],
  props: {
    multi: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      query: { customer_id: null, orderBy: 'date', sortedBy: 'desc' },
      selected: [],
      modalMaxWidth: 900,
      colors,
      shipmentType,
    }
  },
  watch: {
    'query.page'() {
      this.getAll()
    },
    rows: {
      handler(val) {
        let intersection = intersectionBy(this.selected, val, 'id') || []
        this.$nextTick(() => {
          intersection.forEach((row) => {
            let index = this.rows.findIndex((r) => r.id === row.id)
            this.$refs.shipmentTable.toggleRowSelection(this.rows[index], true)
          })
        })
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    ...mapActions('shipment', ['fetchPage']),
    init(customerId) {
      if (customerId === '') {
        return false
      }

      this.query.page = 1
      this.query.customer_id = customerId
      this.getAll()
      this.modalShow = true
    },
    onSort(val) {
      this.$set(this.query, 'orderBy', (val.order && val.prop) || 'id')
      this.$set(this.query, 'sortedBy', val.order === 'ascending' ? 'asc' : 'desc')
      this.getAll()
    },
    onChoosen(shipment) {
      this.query.search = null
      this.$emit('shipment-choose', shipment)
      this.modalShow = false
    },
    onSelected(checked) {
      this.selected = differenceBy(
        uniqBy([].concat(this.selected).concat(intersectionBy(this.rows, checked, 'id')), 'id'),
        differenceBy(this.rows, checked, 'id'),
        'id'
      )
    },
    onMulti() {
      if (this.selected.length < 1) {
        this.$message.error('請選擇出貨項目')
        return false
      }

      this.query.search = null
      this.$emit('shipment-multi-choose', this.selected)
      this.selected = []
      this.modalShow = false
    },
  },
}
</script>
