<template>
  <div>
    <el-dialog
      :visible.sync="modalShow"
      :close-on-click-modal="false"
      :title="actionText + typeText"
      width="600px"
      top="15px"
      append-to-body
    >
      <el-form v-if="modalShow" :ref="formRefs" :model="row" :rules="rules" label-width="140px">
        <div class="form-row">
          <el-form-item class="col-sm-12" label="種類" prop="type">
            <el-radio-group v-model="row.type" @change="typeChange">
              <el-radio :label="0">加工完成</el-radio>
              <el-radio :label="1">報廢</el-radio>
              <el-radio :label="2">重工</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <div class="form-row">
          <el-form-item class="col-sm-8" :label="`${typeText}倉儲`" prop="warehouse_id">
            <el-select v-model.number="row.warehouse_id" :placeholder="`- 請選擇${typeText}倉儲 -`">
              <el-option v-for="item in warehouses" :key="item.id" :label="item.name" :value="Number(item.id)" />
            </el-select>
          </el-form-item>
        </div>
        <div class="form-row">
          <el-form-item
            class="col-sm-8"
            :label="`${typeText}數量`"
            prop="quantity"
            :rules="[
              { required: true, message: '請輸入數量', trigger: 'change' },
              { type: 'number', min: 1, message: '數量最少為 1', trigger: 'change' },
            ]"
          >
            <el-input v-model.number="row.quantity" :min="1" type="number" :placeholder="`${typeText}數量`">
              <template #append> 個 </template>
            </el-input>
          </el-form-item>
        </div>
        <div class="form-row">
          <el-form-item class="col-sm-8" :label="`${typeText}日期`" prop="created_at">
            <el-date-picker
              v-model="row.created_at"
              type="date"
              value-format="yyyy-MM-dd"
              :placeholder="`${typeText}點交日期`"
            />
          </el-form-item>
        </div>
        <div class="form-row">
          <el-form-item class="col-sm-12" label="備註" prop="comment">
            <el-input v-model.trim="row.comment" placeholder="備註" />
          </el-form-item>
        </div>
        <div class="form-row">
          <el-form-item class="col-sm-12 text-right">
            <b-btn variant="secondary" @click.prevent="modalShow = false">
              <fa icon="times-circle" fixed-width />取消
            </b-btn>
            <b-btn type="submit" variant="primary" @click.prevent="onSubmit">
              <fa icon="edit" fixed-width />{{ actionText }}
            </b-btn>
          </el-form-item>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { modify } from '@/plugins/mixins'

export default {
  name: 'ProcessOutgoingSetting',
  mixins: [modify],
  props: {
    proc: {
      type: Object,
      default() {
        return {
          id: null,
        }
      },
    },
  },
  data() {
    return {
      structure: {
        type: 0,
        process_id: this.proc.id,
        warehouse_id: null,
        quantity: null,
        created_at: null,
        source: {},
        comment: null,
      },
      rules: {
        type: [{ required: true, message: `請選擇種類`, trigger: 'change' }],
        warehouse_id: [{ required: true, message: `請選擇倉儲`, trigger: 'change' }],
        created_at: [{ required: true, message: '請選擇日期', trigger: 'blur' }],
      },
      warehouses: this.$store.getters['warehouse/part'],
    }
  },
  computed: {
    typeText() {
      let text
      if (this.row.type === 1) {
        text = '報廢'
      } else if (this.row.type === 2) {
        text = '重工'
      } else {
        text = '加工完成'
      }
      return text
    },
  },
  watch: {
    'proc.id': {
      handler(n) {
        this.$set(this.structure, 'process_id', n)
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('processOutgoing', ['fetchOne', 'doCreate', 'doUpdate']),
    typeChange(val) {
      this.warehouses = val == 1 ? this.$store.getters['warehouse/scrapped'] : this.$store.getters['warehouse/part']
    },
  },
}
</script>
