<template>
  <el-dialog :visible.sync="modalShow" :close-on-click-modal="false" :width="modalWidth" top="5px">
    <template #title>
      <h3 class="m-0">安排生產製程</h3>
    </template>
    <el-form v-if="modalShow" :ref="formRefs" :model="row" :rules="rules" size="mini">
      <h5>訂單產品</h5>
      <table class="table table-bordered table-striped table-sm display">
        <tbody>
          <tr>
            <td style="width: 13%">製造訂單編號</td>
            <td style="width: 20%" class="text-monospace">{{ row.sno }}</td>
            <td style="width: 13%">產品名稱</td>
            <td colspan="3">{{ row.product && `(${row.product.alias_id}) ${row.product.name}` }}</td>
          </tr>
          <tr class="table-warning vm">
            <td>訂貨數量</td>
            <td>{{ row.quantity }} 個</td>
            <td>庫存數量</td>
            <td style="width: 20%">
              <el-tooltip content="如果庫存無法確定時，請設定爲 0" placement="top">
                <el-form-item
                  :rules="[
                    {
                      type: 'number',
                      min: 0,
                      message: ' ',
                      trigger: 'change',
                      transform: (val) => Number(val),
                    },
                  ]"
                  class="m-0 p-0"
                  prop="product.total_quantity"
                >
                  <el-input
                    v-model.number="row.product.total_quantity"
                    type="number"
                    :min="0"
                    placeholder="庫存量"
                    @input="calculator"
                  >
                    <template #append>個</template>
                  </el-input>
                </el-form-item>
              </el-tooltip>
            </td>
            <td style="width: 13%">安全庫存量</td>
            <td>
              <el-form-item
                :rules="{
                  type: 'number',
                  min: 0,
                  message: ' ',
                  trigger: 'change',
                  transform: (val) => Number(val),
                }"
                class="m-0 p-0"
                prop="product.safety_stock"
              >
                <el-input
                  v-model.number="row.product.safety_stock"
                  type="number"
                  :min="0"
                  placeholder="安全庫存量"
                  @input="calculator"
                >
                  <template #append>個</template>
                </el-input>
              </el-form-item>
            </td>
          </tr>
          <tr class="vm">
            <td>安排生產數量</td>
            <td>{{ row.arrange_quantity }} 個</td>
            <td>交貨日期</td>
            <td>{{ row.delivery_date }}</td>
            <td>備註</td>
            <td>
              <el-form-item class="m-0 p-0" prop="comment">
                <el-input v-model.trim="row.comment" placeholder="備註" />
              </el-form-item>
            </td>
          </tr>
        </tbody>
      </table>
      <h5>配件與生產製程 {{ cal }}</h5>
      <template v-if="basicPart.length > 0">
        <table
          v-for="(part, index) in basicPart"
          :key="part.id"
          class="table table-bordered table-striped table-sm display"
        >
          <tbody>
            <tr class="vm">
              <td style="width: 13%">配件編號</td>
              <td style="width: 20%">{{ part.alias_id }}</td>
              <td style="width: 13%">配件名稱</td>
              <td style="width: 20%">{{ part.name }}</td>
              <td style="width: 13%">組合用量</td>
              <td>
                <el-form-item
                  :prop="`parts[${index}].usage`"
                  :rules="{
                    required: true,
                    type: 'number',
                    min: 1,
                    message: ' ',
                    trigger: 'change',
                    transform: (val) => Number(val),
                  }"
                  class="m-0 p-0"
                >
                  <el-input v-model.number="part.usage" type="number" :min="1" placeholder="組合用量" @input="onUsage">
                    <template #append>個</template>
                  </el-input>
                </el-form-item>
              </td>
            </tr>
            <tr class="vm">
              <td>材料名稱</td>
              <td colspan="3">{{ part.material | materialName }}</td>
              <td>材料單數</td>
              <td>
                <el-form-item
                  :prop="`parts[${index}].material_to_quantity`"
                  :rules="{
                    required: true,
                    type: 'number',
                    min: 1,
                    message: ' ',
                    trigger: 'change',
                    transform: (val) => Number(val),
                  }"
                  class="m-0 p-0"
                >
                  <el-input
                    v-model.number="part.material_to_quantity"
                    :disabled="!part.is_mold"
                    type="number"
                    :min="1"
                    placeholder="材料單數"
                    @input="calculator"
                  >
                    <template #append>個</template>
                  </el-input>
                </el-form-item>
              </td>
            </tr>
            <tr class="vm">
              <td>需求數量</td>
              <td>{{ part.require_quantity }} 個</td>
              <td>庫存數量</td>
              <td>
                <el-tooltip content="如果庫存無法確定時，請設定爲 0" placement="top">
                  <el-form-item
                    :prop="`parts[${index}].total_quantity`"
                    :rules="[
                      { type: 'number', min: 0, message: ' ', trigger: 'change', transform: (val) => Number(val) },
                    ]"
                    class="m-0 p-0"
                  >
                    <el-input
                      v-model.number="part.total_quantity"
                      type="number"
                      :min="0"
                      placeholder="庫存數量"
                      @input="calculator"
                    >
                      <template #append>個</template>
                    </el-input>
                  </el-form-item>
                </el-tooltip>
              </td>
              <td>安全庫存量</td>
              <td>
                <el-form-item
                  :prop="`parts[${index}].safety_stock`"
                  :rules="{
                    type: 'number',
                    min: 0,
                    message: ' ',
                    trigger: 'change',
                    transform: (val) => Number(val),
                  }"
                  class="m-0 p-0"
                >
                  <el-input
                    v-model.number="part.safety_stock"
                    :step="part.material_to_quantity"
                    type="number"
                    :min="0"
                    placeholder="安全庫存量"
                    @input="calculator"
                  >
                    <template #append>個</template>
                  </el-input>
                </el-form-item>
              </td>
            </tr>
            <tr class="vm">
              <td>安排生產數量</td>
              <td>{{ part.arrange_quantity }} 個</td>
              <td>採購數量</td>
              <td>{{ part.purchase_quantity }} 個</td>
              <td>完成後入庫到</td>
              <td>
                <el-form-item
                  :prop="`parts[${index}].warehouse_id`"
                  :rules="{ required: part.arrange_quantity > 0, message: ' ', trigger: 'change' }"
                  class="m-0 p-0"
                >
                  <el-select
                    v-model.number="part.warehouse_id"
                    :disabled="part.arrange_quantity <= 0"
                    placeholder="- 請選擇 -"
                  >
                    <el-option
                      v-for="item in $store.getters['warehouse/part']"
                      :key="item.id"
                      :label="item.name"
                      :value="Number(item.id)"
                    />
                  </el-select>
                </el-form-item>
              </td>
            </tr>
            <tr v-if="part.arrange_quantity > 0">
              <td>生產製程</td>
              <td class="p-0" colspan="5">
                <template v-if="part.process[0].id !== 16">
                  <table class="table table-sm m-0">
                    <thead>
                      <tr>
                        <th style="width: 20%">加工製程</th>
                        <th style="width: 20%">委外廠商</th>
                        <th style="width: 18%">委託數量</th>
                        <th style="width: 18%">交貨時間</th>
                        <th>備註</th>
                        <th style="width: 43px" />
                      </tr>
                    </thead>
                  </table>
                  <table
                    v-for="(proc, index2) in row.processes[part.part_id]"
                    :key="index2"
                    class="table table-bordered table-sm m-0"
                  >
                    <tbody>
                      <tr v-for="(batch, index3) in proc.batch" :key="index3" class="vm">
                        <td class="table-light" style="width: 20%">{{ index3 === 0 ? proc.name : '' }}</td>
                        <td class="table-light" style="width: 20%">
                          <el-form-item
                            :prop="`processes[${part.part_id}][${index2}].batch[${index3}].customer_id`"
                            :rules="{ required: true, message: ' ', trigger: 'change' }"
                            class="m-0 p-0"
                          >
                            <el-select v-model.number="batch.customer_id" placeholder="- 請選擇 -">
                              <el-option
                                v-for="vendor in proc.customers"
                                :key="vendor.id"
                                :label="vendor.name"
                                :value="Number(vendor.id)"
                              />
                            </el-select>
                          </el-form-item>
                        </td>
                        <td style="width: 18%" class="table-light pt-0 pb-0 pl-3 pr-3">
                          <el-slider
                            v-model="batch.quantity"
                            :step="calculateStep(part)"
                            :min="0"
                            :max="part.arrange_quantity"
                            :format-tooltip="
                              (val) =>
                                `${val} / ${part.arrange_quantity} (${Math.round(
                                  (val / part.arrange_quantity) * 100
                                )}%)`
                            "
                            show-stops
                            @change="onChangeSlider(proc, index3)"
                          />
                        </td>
                        <td class="table-light" style="width: 18%">
                          <el-form-item
                            :prop="`processes[${part.part_id}][${index2}].batch[${index3}].delivery_date`"
                            :rules="{ required: true, message: ' ', trigger: 'change' }"
                            class="m-0 p-0"
                          >
                            <el-date-picker
                              v-model="batch.delivery_date"
                              type="date"
                              value-format="yyyy-MM-dd"
                              placeholder="交貨日期"
                            />
                          </el-form-item>
                        </td>
                        <td class="table-light">
                          <el-form-item
                            :prop="`processes[${part.part_id}][${index2}].batch[${index3}].comment`"
                            class="m-0 p-0"
                          >
                            <el-input v-model="batch.comment" placeholder="備註" />
                          </el-form-item>
                        </td>
                        <td class="table-light" style="width: 43px">
                          <template v-if="index3 === 0">
                            <b-btn
                              v-show="proc.customers.length > 1 && part.purchase_quantity > proc.batch.length"
                              variant="success"
                              size="sm"
                              @click.prevent="onAddBatch(proc)"
                            >
                              <fa icon="plus-circle" />
                            </b-btn>
                          </template>
                          <b-btn v-else variant="danger" size="sm" @click.prevent="onRemoveBatch(proc, index3)">
                            <fa icon="trash-alt" />
                          </b-btn>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </template>
                <b-badge v-else class="ml-1 mt-2" variant="dark">無需加工</b-badge>
              </td>
            </tr>
          </tbody>
        </table>
      </template>

      <template v-if="combinedPart.length > 0">
        <h5>合併配件與生產製程</h5>
        <table
          v-for="(part, index) in combinedPart"
          :key="part.id"
          class="table table-bordered table-striped table-sm display"
        >
          <tbody>
            <tr class="vm">
              <td style="width: 13%">配件編號</td>
              <td style="width: 53%" colspan="3">{{ part.alias_id }}</td>
              <td style="width: 13%">組合用量</td>
              <td>
                <el-form-item
                  :prop="`parts[${index}].usage`"
                  :rules="{ required: true, type: 'number', min: 1, message: ' ', trigger: 'change' }"
                  class="m-0 p-0"
                >
                  <el-input v-model.number="part.usage" type="number" :min="1" placeholder="組合用量" @input="onUsage">
                    <template #append>個</template>
                  </el-input>
                </el-form-item>
              </td>
            </tr>
            <tr>
              <td>配件名稱</td>
              <td colspan="5">{{ part.name }}</td>
            </tr>
            <tr class="vm">
              <td style="width: 13%">需求數量</td>
              <td style="width: 20%">{{ part.require_quantity }} 個</td>
              <td style="width: 13%">安排生產數量</td>
              <td style="width: 20%">{{ part.arrange_quantity }} 個</td>
              <td style="width: 13%">完成後入庫到</td>
              <td>
                <el-form-item
                  :prop="`parts[${index}].warehouse_id`"
                  :rules="{ required: part.arrange_quantity > 0, message: ' ', trigger: 'change' }"
                  class="m-0 p-0"
                >
                  <el-select
                    v-model.number="part.warehouse_id"
                    :disabled="part.arrange_quantity <= 0"
                    placeholder="- 請選擇 -"
                  >
                    <el-option
                      v-for="item in $store.getters['warehouse/part']"
                      :key="item.id"
                      :label="item.name"
                      :value="Number(item.id)"
                    />
                  </el-select>
                </el-form-item>
              </td>
            </tr>
            <tr v-if="part.arrange_quantity > 0">
              <td>生產製程</td>
              <td class="p-0" colspan="5">
                <template v-if="part.process[0].id !== 16">
                  <table class="table table-bordered table-sm m-0">
                    <thead>
                      <tr>
                        <th style="width: 20%">加工製程</th>
                        <th style="width: 20%">委外廠商</th>
                        <th style="width: 18%">委託數量</th>
                        <th style="width: 18%">交貨時間</th>
                        <th>備註</th>
                        <th style="width: 43px" />
                      </tr>
                    </thead>
                  </table>
                  <table
                    v-for="(proc, index2) in row.processes[part.part_id]"
                    :key="index2"
                    class="table table-bordered table-sm m-0"
                  >
                    <tbody>
                      <tr v-for="(batch, index3) in proc.batch" :key="index3" class="vm">
                        <td class="table-light" style="width: 20%">{{ index3 === 0 ? proc.name : '' }}</td>
                        <td class="table-light" style="width: 20%">
                          <el-form-item
                            :prop="`processes[${part.part_id}][${index2}].batch[${index3}].customer_id`"
                            :rules="{ required: true, message: ' ', trigger: 'change' }"
                            class="m-0 p-0"
                          >
                            <el-select v-model.number="batch.customer_id" placeholder="- 請選擇 -">
                              <el-option
                                v-for="vendor in proc.customers"
                                :key="vendor.id"
                                :label="vendor.name"
                                :value="Number(vendor.id)"
                              />
                            </el-select>
                          </el-form-item>
                        </td>
                        <td style="width: 18%" class="table-light pt-0 pb-0 pl-3 pr-3">
                          <el-slider
                            v-model="batch.quantity"
                            :step="calculateStep(part)"
                            :min="0"
                            :max="part.arrange_quantity"
                            :format-tooltip="
                              (val) =>
                                `${val} / ${part.arrange_quantity} (${Math.round(
                                  (val / part.arrange_quantity) * 100
                                )}%)`
                            "
                            show-stops
                            @change="onChangeSlider(proc, index3)"
                          />
                        </td>
                        <td class="table-light" style="width: 18%">
                          <el-form-item
                            :prop="`processes[${part.part_id}][${index2}].batch[${index3}].delivery_date`"
                            class="m-0 p-0"
                          >
                            <el-date-picker
                              v-model="batch.delivery_date"
                              type="date"
                              value-format="yyyy-MM-dd"
                              placeholder="交貨日期"
                            />
                          </el-form-item>
                        </td>
                        <td class="table-light">
                          <el-form-item
                            :prop="`processes[${part.part_id}][${index2}].batch[${index3}].comment`"
                            class="m-0 p-0"
                          >
                            <el-input v-model="batch.comment" placeholder="備註" />
                          </el-form-item>
                        </td>
                        <td class="table-light" style="width: 43px">
                          <template v-if="index3 === 0">
                            <b-btn
                              v-if="proc.customers.length > 1"
                              variant="success"
                              size="sm"
                              @click.prevent="onAddBatch(proc)"
                            >
                              <fa icon="plus-circle" />
                            </b-btn>
                          </template>
                          <b-btn v-else variant="danger" size="sm" @click.prevent="onRemoveBatch(proc, index3)">
                            <fa icon="trash-alt" />
                          </b-btn>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </template>
                <b-badge v-else variant="dark">無需加工</b-badge>
              </td>
            </tr>
          </tbody>
        </table>
      </template>
      <div class="form-row">
        <el-form-item class="col-sm-12 text-right">
          <b-btn variant="secondary" @click.prevent="modalShow = false">
            <fa icon="times-circle" fixed-width />取消
          </b-btn>
          <b-btn type="submit" variant="primary" @click.prevent="onSubmit"> <fa icon="edit" fixed-width />確定 </b-btn>
        </el-form-item>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
import { format, addDays, max } from 'date-fns'
import { mapActions } from 'vuex'
import { modify } from '@/plugins/mixins'
import { forEach, min, sortBy, sumBy, uniq } from 'lodash-es'

export default {
  name: 'OrderArrange',
  mixins: [modify],
  data() {
    return {
      structure: {
        quantity: null,
        arrange_quantity: null,
        product: {
          id: null,
          alias_id: null,
          name: null,
          quantity: null,
          total_quantity: 0,
          safety_stock: null,
        },
        parts: [],
        processes: {},
      },
      rules: {},
      cal: [],
    }
  },
  computed: {
    basicPart() {
      return this.row.parts.filter((part) => part.parent_id.length === 0) || []
    },
    combinedPart() {
      return this.row.parts.filter((part) => part.parent_id.length > 0) || []
    },
  },
  methods: {
    ...mapActions('order', ['fetchOne', 'doArrange']),
    init(orderId) {
      if (!orderId) {
        this.$message.error('請提供訂單編號')
        return false
      }
      this.submitAction = this.doArrange

      this.fetchOne(orderId).then((result) => {
        // calculate product total quantity
        result.product.total_quantity = this.availableQuantity(result.product.quantity)
        forEach(result.parts, (v) => {
          v.warehouse_id = v.child_id === null ? 14 : v.material?.is_plate ? 10 : 15 // 14:包裝, 15:組立
          v.child_usage = 1
          v.arrange_quantity = 0
          v.unit_quantity = 0
          v.require_quantity = 0
          v.purchase_quantity = 0
          v.total_quantity = this.availableQuantity(v.quantity)
        })
        this.$set(this.$data, 'row', result)
        this.makeProcessesBatch()
        this.onUsage().then(() => {
          this.modalShow = true
        })
      })
    },
    availableQuantity(quantity) {
      return sumBy(
        quantity.filter((item) => !this.$store.getters['warehouse/returns'].map((x) => x.id).includes(item['id'])),
        'qty'
      )
    },
    updateParts(parts) {
      forEach(parts, (v) => {
        v.child_usage = 1
        v.arrange_quantity = 0
        v.unit_quantity = 0
        v.require_quantity = 0
        v.purchase_quantity = 0
      })
      return parts
    },
    makeProcessesBatch() {
      let processes = {}
      let parts = [].concat(this.row.parts)
      let deliveryDate1 = addDays(new Date(), 3)
      let maxDate = addDays(new Date(), 3)

      forEach(parts, (part) => {
        if (processes[part.part_id] === undefined) {
          processes[part.part_id] = []
        }

        if (part.is_combined) {
          deliveryDate1 = maxDate
        }

        let deliveryDate2 = deliveryDate1

        forEach(part.process, (proc) => {
          const defaultProcess = this.$store.getters['defaultProcess/all'].find((x) => x.id === +proc.id)
          const customerId =
            proc.vendor_id && proc.vendor_id !== null
              ? proc.vendor_id * 1
              : defaultProcess.customers.length === 1
              ? defaultProcess.customers[0].id
              : defaultProcess.customers.find((x) => x.id === 0)
              ? 0
              : null

          deliveryDate2 = addDays(deliveryDate2, 1)

          processes[part.part_id].push({
            id: proc.id,
            customers: defaultProcess.customers,
            name: defaultProcess.name,
            type: defaultProcess.type,
            quantity: part.arrange_quantity,
            batch: [
              {
                id: proc.id,
                customer_id: customerId,
                quantity: part.arrange_quantity,
                delivery_date: format(deliveryDate2, 'yyyy-MM-dd'),
                comment: proc.comment,
              },
            ],
          })

          maxDate = max([deliveryDate2, maxDate])
        })
      })
      this.$set(this.row, 'processes', processes)
    },
    updateBatchQuantity() {
      // let processes = {}
      forEach(this.row.parts, (part) => {
        forEach(this.row.processes[part.part_id], (proc) => {
          if (part.arrange_quantity !== proc.quantity) {
            proc.quantity = part.arrange_quantity
            forEach(proc.batch, (batch, index) => {
              batch.quantity = index === 0 ? part.arrange_quantity : 0
            })
          }
        })
      })
    },
    async onUsage() {
      forEach(this.row.parts, (v) => {
        v.child_usage = 1
      })

      if (this.row.parts.length) {
        const last = sortBy(this.row.parts, ['parent_id']).pop()
        await this.childUsage(last.part_id, last.usage)
      }
      await this.calculator()
    },
    childUsage(partId, usage) {
      let part = this.row.parts.find((x) => x.part_id === +partId)
      part.child_usage = usage * part.usage
      forEach(part.parent_id, (pid) => {
        this.childUsage(pid, part.child_usage)
      })
    },
    getArrangeProduction() {
      let arrangeQty =
        Number(this.row.quantity) + Number(this.row.product.safety_stock) - Number(this.row.product.total_quantity)

      if (arrangeQty <= 0) {
        arrangeQty = 0
      }

      let cal = []
      forEach(this.basicPart, (v) => {
        const qty = Math.ceil(arrangeQty / v.material_to_quantity)
        v.unit_quantity = qty * v.material_to_quantity
        if (v.material_to_quantity > 1 || v.is_mold) {
          cal.push(v.unit_quantity)
        }
      })

      // 如果都沒有需要下料的使用訂單的訂購數量
      if (cal.length === 0) {
        cal = [this.row.quantity]
      }
      this.cal = cal
      return min(cal) || 0
    },
    calculator() {
      this.row.arrange_quantity = this.getArrangeProduction()
      forEach(this.row.parts, (part, index) => {
        const qty =
          part.material_to_quantity > 1
            ? Math.ceil(this.row.arrange_quantity / part.material_to_quantity)
            : this.row.arrange_quantity
        part.unit_quantity = qty * part.material_to_quantity || 0
        part.require_quantity = part.child_usage * part.unit_quantity
        part.arrange_quantity =
          Math.ceil((part.require_quantity - part.total_quantity + part.safety_stock) / part.material_to_quantity) *
          part.material_to_quantity
        if (part.arrange_quantity <= 0) {
          part.arrange_quantity = 0
        }

        part.purchase_quantity = part.is_combined ? 0 : part.arrange_quantity / part.material_to_quantity
        this.$set(this.row.parts, index, part)
      })
      this.updateBatchQuantity()
    },
    onAddBatch(process) {
      process.batch.push({
        id: process.id,
        customer_id: null,
        quantity: process.arrange_quantity,
        delivery_date: process.batch[0]?.delivery_date,
        comment: null,
      })
    },
    onRemoveBatch(process, index) {
      process.batch.splice(index, 1)
      this.onChangeSlider(process, process.batch.length - 1)
    },
    calculateStep(part) {
      // if (part.purchase_quantity < 2) {
      //     return part.material_to_quantity
      // }

      let arr = []
      arr = this.yinshifenjie(part.arrange_quantity, arr)
      arr.push(part.material_to_quantity)
      arr = uniq(arr.sort((a, b) => b - a))

      let step = part.arrange_quantity
      forEach(arr, (val) => {
        if (part.material_to_quantity % val === 0) {
          step = val
          return false
        }
      })
      return step
    },
    yinshifenjie(n, arr) {
      let i = 2
      if (n === 1) {
        // arr.push(1)
      } else {
        while (n > 1) {
          let a = n % i
          if (a === 0) {
            n = n / i
            arr.push(n)
            this.yinshifenjie(n, arr)
            break
          } else {
            i++
          }
        }
      }
      return arr
    },
    onChangeSlider(process, index) {
      let reduce = process.quantity - process.batch[index].quantity
      forEach(process.batch, (batch, key) => {
        if (key === index) {
          return
        }

        if (batch.quantity >= reduce) {
          batch.quantity = reduce
        }
        reduce -= batch.quantity
      })
    },
    checkBatchQuantity() {
      let check = []
      forEach(this.row.processes, (processes) => {
        forEach(processes, (proc) => {
          if (proc.quantity !== sumBy(proc.batch, 'quantity')) {
            check.push(proc)
          }
        })
      })
      return check.length > 0
    },
    onBeforeSubmit() {
      if (this.checkBatchQuantity()) {
        this.$message.error('請重新確認批次委託數量')
        return false
      }
    },
  },
}
</script>
