<template>
  <el-scrollbar wrap-class="scrollbar-wrapper">
    <el-menu
      :default-active="$route.name"
      :collapse="!sidebar"
      mode="vertical"
      background-color="#343a40"
      text-color="#fff"
      active-text-color="#007bff"
    >
      <sidebar-item v-for="route in routers" :key="route.name" :item="route" />
    </el-menu>
  </el-scrollbar>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Sidebar',
  computed: {
    ...mapGetters('app', ['sidebar']),
    ...mapGetters('permission', ['routers']),
  },
}
</script>
