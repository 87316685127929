<template>
  <div class="w-100">
    <table class="table table-bordered table-striped table-sm display">
      <tbody>
        <tr class="vm">
          <td style="width: 13%">出貨編號</td>
          <td style="width: 20%" class="text-monospace">
            <a href="#" @click.prevent="onShipment(target.id)">{{ target.sno }} <fa icon="edit" /> </a>
          </td>
          <td style="width: 13%">訂單編號</td>
          <td style="width: 20%" class="text-monospace">{{ target.order && target.order.sno }}</td>
          <td style="width: 13%">出貨日期</td>
          <td style="width: 20%">{{ target.date }}</td>
        </tr>
        <tr>
          <td>客戶訂單編號</td>
          <td>{{ target.order && target.order.customer_order_id }}</td>
          <td>產品名稱</td>
          <td colspan="3">{{ target.product && `(${target.product.alias_id}) ${target.product.name}` }}</td>
        </tr>
        <tr>
          <td>種類</td>
          <td>
            <b-badge :variant="colors[target.type]">
              {{ shipmentType[target.type] }}
            </b-badge>
          </td>
          <td>送貨方式</td>
          <td>
            <b-badge :variant="colors[target.delivery_method + 1]">
              {{ deliveryMethods[target.delivery_method] }}
            </b-badge>
          </td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>出貨數量</td>
          <td>{{ target.quantity }}</td>
          <td>樣品數量</td>
          <td>{{ target.sample_quantity }}</td>
          <td>產品單價</td>
          <td>{{ target.unit_price | currency(target.order.currency) }}</td>
        </tr>
        <tr>
          <td>承辦人</td>
          <td>{{ target.user && target.user.name }}</td>
          <td>出貨倉儲</td>
          <td>{{ target.warehouse && target.warehouse.name }}</td>
          <td>建立時間</td>
          <td>{{ target.created_at }}</td>
        </tr>
        <tr>
          <td>備註</td>
          <td colspan="5">{{ target.comment }}</td>
        </tr>
      </tbody>
    </table>
    <shipment-setting ref="shipmentSetting" @done="$emit('done')" />
  </div>
</template>

<script>
import { colors, deliveryMethods, shipmentType } from '@/plugins/consts'

export default {
  name: 'AccountsReceivableShipment',
  props: {
    target: {
      type: Object,
      default() {
        return {
          sno: null,
          order: {},
          product: {},
          user: {},
          warehouse: {},
        }
      },
    },
  },
  data() {
    return {
      colors,
      deliveryMethods,
      shipmentType,
    }
  },
  methods: {
    onShipment(id) {
      this.$refs.shipmentSetting.init(id)
    },
  },
}
</script>
