<template>
  <el-dialog :visible.sync="modalShow" :close-on-click-modal="false" :width="modalWidth" top="5px" append-to-body>
    <template #title>
      <h3 class="m-0">新增模具訂單</h3>
    </template>
    <el-form v-if="modalShow" :ref="formRefs" :model="row" label-width="120px">
      <div class="form-row">
        <el-form-item
          :rules="{ required: true, message: '請選擇模具生產商', trigger: 'change' }"
          class="col-md-6"
          label="模具生產商"
          prop="vendor_id"
        >
          <el-select v-model.number="row.vendor_id" placeholder="- 請選擇 -">
            <el-option
              v-for="vendor in $store.getters['customer/molds']"
              :key="vendor.id"
              :label="vendor.name"
              :value="Number(vendor.id)"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          :rules="{ required: true, message: '請選擇所有權人', trigger: 'change' }"
          class="col-md-6"
          label="所有權人"
          prop="owner_id"
        >
          <el-switch
            v-model.number="row.owner_id"
            :active-value="0"
            :inactive-value="1"
            active-text="本廠"
            inactive-text="客戶"
          />
        </el-form-item>
      </div>
      <div class="form-row">
        <el-form-item class="col" label="備註" prop="comment">
          <el-input v-model.trim="row.comment" placeholder="備註" />
        </el-form-item>
      </div>
      <div class="form-row mt-3">
        <div class="col-md-6">
          <h5>現有模具與零件</h5>
          <table class="table table-bordered m-0">
            <thead>
              <tr>
                <th style="width: 50%">模具名稱</th>
                <th style="width: 50%">模具廠商編號</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="item in molds">
                <tr v-if="item.status === 1" :key="'mold-' + item.id" class="vm table-warning">
                  <td>{{ item.name }}</td>
                  <td>{{ item.vendor_alias_id }}</td>
                </tr>
                <template v-for="unit in item.units">
                  <tr v-if="unit.status === 1" :key="'unit-' + unit.id" class="vm">
                    <td colspan="2">
                      <div class="form-check ml-5">
                        <input
                          :id="`unit-${item.id}-${unit.id}`"
                          v-model="unitIds"
                          :value="unit.id"
                          type="checkbox"
                          class="form-check-input"
                          @change="onChangeUnit"
                        />
                        <label :for="`unit-${item.id}-${unit.id}`" class="form-check-label"> {{ unit.name }} </label>
                      </div>
                    </td>
                  </tr>
                </template>
              </template>
            </tbody>
          </table>
        </div>
        <div class="col-md-6">
          <h5>新增的模具</h5>
          <table class="table table-bordered table-sm m-0">
            <thead>
              <tr class="vm">
                <th>模具名稱</th>
                <th style="width: 40%">零件名稱</th>
                <th class="text-right" style="width: 50px">
                  <b-btn variant="success" size="sm" @click.prevent="onAddMold">
                    <fa icon="plus-circle" fixed-width />
                  </b-btn>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(nm1, index) in row.newMolds" :key="nm1.id" class="vm">
                <td class="p-1">
                  <el-form-item
                    :prop="'newMolds[' + index + '].name'"
                    :rules="{ required: true, message: ' ', trigger: 'blur' }"
                    class="m-0 p-0"
                    label-width="0"
                  >
                    <el-input v-model.number="nm1.name" size="small" placeholder="模具名稱" />
                  </el-form-item>
                </td>
                <td />
                <td class="text-right">
                  <b-btn variant="danger" size="sm" @click.prevent="onRemoveMold(nm1.id)">
                    <fa icon="trash-alt" fixed-width />
                  </b-btn>
                </td>
              </tr>
              <template v-for="nm2 in row.oldMolds">
                <template v-for="um in nm2.units">
                  <tr :key="um.id" class="vm">
                    <td>{{ nm2.name }}</td>
                    <td>{{ um.name }}</td>
                    <td class="text-right">
                      <b-btn variant="danger" size="sm" @click.prevent="onRemoveUnit(um.id)">
                        <fa icon="trash-alt" fixed-width />
                      </b-btn>
                    </td>
                  </tr>
                </template>
              </template>
            </tbody>
          </table>
        </div>
      </div>
      <div class="form-row mt-3">
        <div class="col text-right">
          <b-btn variant="secondary" @click.prevent="modalShow = false">
            <fa icon="times-circle" fixed-width />取消
          </b-btn>
          <b-btn type="submit" variant="primary" @click.prevent="onSubmit"> <fa icon="edit" fixed-width />新增 </b-btn>
        </div>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
import { now, reject, pull } from 'lodash-es'
import { modify } from '@/plugins/mixins'
import { mapActions } from 'vuex'

export default {
  name: 'MoldOrderCreate',
  mixins: [modify],
  data() {
    return {
      structure: {
        product_id: null,
        part_id: null,
        vendor_id: null,
        owner_id: 0,
        newMolds: [],
        oldMolds: [],
        comment: null,
      },
      molds: [],
      unitIds: [],
    }
  },
  methods: {
    ...mapActions('moldOrder', ['doCreate']),
    init(part) {
      this.onCreate()
      this.row.product_id = part.product.id
      this.row.part_id = part.id
      this.molds = part.molds
      this.unitIds = []
    },
    onChangeUnit() {
      const self = this
      const molds = JSON.parse(JSON.stringify(this.molds))
      let filteredMold = molds.filter((mold) => {
        const units = mold.units.filter((unit) => {
          return self.unitIds.includes(unit.id)
        })
        return units.length > 0
      })

      filteredMold.forEach((mold) => {
        mold.units = mold.units.filter((unit) => {
          return self.unitIds.includes(unit.id)
        })
      })
      this.row.oldMolds = filteredMold
    },
    onAddMold() {
      this.row.newMolds.push({
        id: now(),
        part_id: this.row.part_id,
        name: null,
      })
    },
    onRemoveMold(moldId) {
      this.row.newMolds = reject(this.row.newMolds, { id: +moldId })
    },
    onRemoveUnit(unitId) {
      this.unitIds = pull(this.unitIds, unitId)
      this.onChangeUnit()
    },
    onBeforeSubmit() {
      if (this.row.newMolds.length + this.row.oldMolds.length < 1) {
        this.$message.error('請至少選擇一項模具或零件進行新增')
        return false
      }
    },
  },
}
</script>
