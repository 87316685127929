<template>
  <div>
    <el-dialog :visible.sync="modalShow" :close-on-click-modal="false" :width="modalWidth" top="5px" append-to-body>
      <template #title>
        <h3 class="m-0">入庫重工設定</h3>
      </template>
      <el-form
        v-if="modalShow"
        :ref="formRefs"
        :model="row"
        :rules="rules"
        label-width="120px"
        :disabled="!$store.getters['permission/check']([35])"
      >
        <div class="card">
          <div v-if="row.rework && row.rework.id" class="card-header px-2">
            <h5 class="m-0">
              重工編號：<span class="text-monospace">{{ row.rework && row.rework.sno }}</span>
            </h5>
          </div>
          <div class="card-body p-3">
            <div class="form-row">
              <el-form-item class="col-md-6 col-lg-4" label="客戶名稱">
                <h6>{{ row.customer && row.customer.name }}</h6>
              </el-form-item>
              <el-form-item class="col-md-6 col-lg-4" label="製造訂單號">
                <h6 class="text-monospace">{{ row.order && row.order.sno }}</h6>
              </el-form-item>
              <el-form-item class="col-md-6 col-lg-4" label="入庫編號">
                <h6 class="text-monospace">{{ row.sno }}</h6>
              </el-form-item>
              <div class="w-100"></div>
              <el-form-item class="col-md-6 col-lg-8" label="產品名稱">
                <h6>{{ row.product && `(${row.product.alias_id}) ${row.product.name}` }}</h6>
              </el-form-item>
              <el-form-item class="col-md-6 col-lg-4" label="產品現有庫存">
                <h6>
                  <a href="#" @click.prevent="$refs.productStockHistory.init(row.product.id)">
                    <quantity :quantity="row.product.quantity" :required="row.product.required_quantity" />
                  </a>
                </h6>
              </el-form-item>
              <div class="w-100"></div>
              <el-form-item class="col-md-6 col-lg-4" label="入庫數量">
                <h6>{{ row.quantity }} 個</h6>
              </el-form-item>
              <el-form-item class="col-md-6 col-lg-4" label="入庫日期">
                <h6>{{ row.date }}</h6>
              </el-form-item>
              <el-form-item class="col-md-6 col-lg-4" label="入庫倉儲">
                <h6>{{ row.warehouse && row.warehouse.name }}</h6>
              </el-form-item>
              <div class="w-100"></div>
              <el-form-item
                class="col-md-6 col-lg-4"
                label="重工數量"
                prop="rework.quantity"
                :rules="[
                  { required: true, message: '請輸入重工數量', trigger: 'blur' },
                  { type: 'number', min: 1, message: `請確認重工數量`, trigger: 'change' },
                ]"
              >
                <el-input v-model.number="row.rework.quantity" type="number" placeholder="重工數量" autofocus>
                  <template #append> 個 </template>
                </el-input>
              </el-form-item>
              <el-form-item class="col-md-6 col-lg-4" label="重工入庫日期" prop="rework.date">
                <el-date-picker
                  v-model="row.rework.date"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="重工入庫日期"
                />
              </el-form-item>
              <el-form-item class="col-md-6 col-lg-4" label="重工入庫倉儲" prop="rework.warehouse_id">
                <el-select v-model.number="row.rework.warehouse_id" placeholder="- 請選擇重工入庫倉儲 -" filterable>
                  <el-option
                    v-for="item in $store.getters['warehouse/rework']"
                    :key="item.id"
                    :label="item.name"
                    :value="Number(item.id)"
                  />
                </el-select>
              </el-form-item>
              <el-form-item class="col" label="重工原因" prop="rework.comment">
                <el-input v-model.trim="row.rework.comment" placeholder="重工原因" />
              </el-form-item>
            </div>
          </div>
        </div>
        <div class="form-row mt-3">
          <div class="col text-right">
            <b-btn variant="secondary" @click.prevent="modalShow = false">
              <fa icon="times-circle" fixed-width />取消
            </b-btn>
            <b-btn
              v-if="$store.getters['permission/check']([35])"
              type="submit"
              variant="primary"
              @click.prevent="onSubmit"
            >
              <fa icon="edit" fixed-width />修改
            </b-btn>
          </div>
        </div>
      </el-form>
    </el-dialog>
    <product-stock-history ref="productStockHistory" :is-inventory="false" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { modify } from '@/plugins/mixins'

export default {
  name: 'PrepareRework',
  mixins: [modify],
  data() {
    return {
      structure: {
        id: null,
        sno: null,
        order: {
          id: null,
          sno: null,
        },
        customer: {
          id: null,
          name: null,
        },
        product: {
          id: null,
          alias_id: null,
          name: null,
        },
        rework: {
          id: null,
          sno: null,
          date: null,
          warehouse_id: null,
          quantity: null,
          comment: null,
        },
        quantity: null,
      },
      rules: {
        'rework.date': [{ required: true, message: '請選擇重工入庫日期', trigger: 'change' }],
        'rework.warehouse_id': [{ required: true, message: '請選擇重工入庫倉儲', trigger: 'change' }],
        'rework.comment': [{ required: true, message: '請選擇重工原因', trigger: 'change' }],
      },
    }
  },
  created() {
    this.submitAction = this.doRework
  },
  methods: {
    ...mapActions('prepare', ['fetchOne', 'doRework']),
  },
}
</script>
