<template>
  <div>
    <el-dialog :visible.sync="modalShow" :close-on-click-modal="false" :width="modalWidth" top="5px">
      <template #title>
        <h3 class="m-0">新增製造訂單</h3>
      </template>
      <el-form v-if="modalShow" :ref="formRefs" :model="row" :rules="rules" label-width="100px">
        <div class="card">
          <div class="card-body p-3">
            <div class="form-row">
              <el-form-item class="col-md-6 col-lg-4" label="客戶" prop="customer_id">
                <el-select
                  v-model.number="row.customer_id"
                  placeholder="- 請選擇客戶 -"
                  filterable
                  @change="onCustomerChange"
                >
                  <el-option
                    v-for="item in $store.getters['customer/consumers']"
                    :key="item.id"
                    :label="item.name"
                    :value="Number(item.id)"
                  />
                </el-select>
              </el-form-item>
              <el-form-item
                v-show="row.customer_id"
                class="col-md-6 col-lg-4"
                label="客戶訂單號"
                prop="customer_order_id"
              >
                <el-input v-model.trim="row.customer_order_id" placeholder="客戶訂單號" />
              </el-form-item>
              <el-form-item v-show="row.customer_id" class="col-md-6 col-lg-4" label="交貨日期" prop="delivery_date">
                <el-date-picker
                  v-model="row.delivery_date"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="交貨日期"
                />
              </el-form-item>
              <div class="w-100"></div>
              <el-form-item v-show="row.customer_id" class="col-md-6 col-lg-4" label="貨幣" prop="currency">
                <el-radio-group v-model="row.currency">
                  <el-radio :label="0">臺幣</el-radio>
                  <el-radio :label="1">美金</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item v-show="row.customer_id" class="col-md-6 col-lg-4" label="應稅" prop="is_invoice">
                <el-switch
                  v-model.number="row.is_invoice"
                  :active-value="1"
                  :inactive-value="0"
                  active-text="是"
                  inactive-text="否"
                />
              </el-form-item>
            </div>
          </div>
        </div>
        <h4 class="d-flex mt-3">
          <span>產品</span>
          <b-btn size="sm" variant="info" class="ml-auto" @click.prevent="onProductChoose">
            <fa icon="crosshairs" fixed-width />選擇現有產品
          </b-btn>
        </h4>
        <div v-for="(product, index) in row.products" :key="index" class="card w-100 mb-3">
          <div class="card-header d-flex align-items-center px-2 py-2">
            <h6 class="flex-grow-1 m-0">{{ `(${product.alias_id}) ${product.name}` }}</h6>
            <div class="d-flex align-items-center ml-3">
              <h6 class="m-0 mr-3">庫存量：{{ product.total_quantity }}</h6>
              <b-btn size="sm" variant="danger" @click.prevent="onProductRemove(index)">
                <fa icon="trash-alt" fixed-width />
              </b-btn>
            </div>
          </div>
          <div class="card-body p-3">
            <div class="form-row">
              <el-form-item
                class="col-md-6 col-lg-4"
                label="單價"
                :prop="`products[${index}].unit_price`"
                :rules="[
                  { required: true, message: '請確認產品單價', trigger: 'blur' },
                  { type: 'number', validator: greaterThan, message: '單價必須大於 0', trigger: 'change' },
                ]"
              >
                <el-input v-model.number="product.unit_price" :min="0" type="number" placeholder="單價">
                  <template #prepend>
                    {{ row.currency | currencySymbol }}
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item
                class="col-md-6 col-lg-4"
                label="訂貨數量"
                :prop="`products[${index}].quantity`"
                :rules="[
                  { required: true, message: '請輸入訂貨數量', trigger: 'blur' },
                  { type: 'number', min: 1, message: '訂貨數量最少爲 1', trigger: 'change' },
                ]"
              >
                <el-input v-model.number="product.quantity" :min="1" type="number" placeholder="訂貨數量">
                  <template #append> 個 </template>
                </el-input>
              </el-form-item>
              <el-form-item
                class="col-md-6 col-lg-4"
                label="模具費用"
                :prop="`products[${index}].mold_price`"
                :rules="[{ required: false, type: 'number', min: 0, message: '請確認模具費用', trigger: 'blur' }]"
              >
                <el-input v-model.number="product.mold_price" :min="0" type="number" placeholder="模具費用">
                  <template #prepend>
                    {{ row.currency | currencySymbol }}
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item
                class="col-md-6 col-lg-4"
                label="交貨方式"
                :prop="`products[${index}].delivery_method`"
                :rules="[{ required: true, message: '請選擇交貨方式', trigger: 'change' }]"
              >
                <el-select v-model.number="product.delivery_method" placeholder="- 請選擇 -" filterable>
                  <el-option
                    v-for="(item, index2) in deliveryMethods"
                    :key="index2"
                    :label="item"
                    :value="Number(index2)"
                  />
                </el-select>
              </el-form-item>
              <el-form-item
                class="col-md-6 col-lg-4"
                label="訂金"
                :prop="`products[${index}].deposit`"
                :rules="[{ required: true, type: 'number', min: 0, message: '請確認訂金', trigger: 'blur' }]"
              >
                <el-input v-model.number="product.deposit" :min="0" type="number" placeholder="訂金">
                  <template #prepend>
                    {{ row.currency | currencySymbol }}
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item
                class="col-md-6 col-lg-4"
                label="急件"
                :prop="`products[${index}].is_rush`"
                :rules="[{ required: true, message: '請選擇是否急件', trigger: 'change' }]"
              >
                <el-switch
                  v-model.number="product.is_rush"
                  :active-value="1"
                  :inactive-value="0"
                  active-text="是"
                  inactive-text="否"
                />
              </el-form-item>
              <el-form-item class="col" label="備註" prop="comment">
                <el-input v-model="product.comment" placeholder="備註" />
              </el-form-item>
            </div>
          </div>
        </div>
        <h4 v-if="row.products.length > 1" class="d-flex mt-3">
          <b-btn size="sm" variant="info" class="ml-auto" @click.prevent="onProductChoose">
            <fa icon="crosshairs" fixed-width />選擇現有產品
          </b-btn>
        </h4>
        <div class="form-row mt-3">
          <div class="col text-right">
            <b-btn variant="secondary" @click.prevent="modalShow = false">
              <fa icon="times-circle" fixed-width />取消
            </b-btn>
            <b-btn type="submit" variant="primary" @click.prevent="onSubmit">
              <fa icon="edit" fixed-width />新增
            </b-btn>
          </div>
        </div>
      </el-form>
    </el-dialog>
    <product-choose ref="productChoose" :customer-id="row.customer_id" @done="onProductChosen" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { modify, greaterThan } from '@/plugins/mixins'
import { deliveryMethods } from '@/plugins/consts'
export default {
  name: 'OrderCreate',
  mixins: [modify, greaterThan],
  props: {
    customerId: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {
      structure: {
        id: null,
        customer_id: this.customerId,
        customer_order_id: null,
        currency: 0,
        is_invoice: 1,
        products: [],
      },
      rules: {
        customer_id: [{ required: true, message: '請選擇訂單客戶', trigger: 'change' }],
        delivery_date: [{ required: true, message: '請選擇交貨日期', trigger: 'change' }],
        currency: [{ required: true, message: '請選擇訂單貨幣', trigger: 'blur' }],
        is_invoice: [{ required: true, message: '請選擇是否應稅', trigger: 'blur' }],
      },
      deliveryMethods,
    }
  },
  watch: {
    customerId: {
      handler(n) {
        this.$set(this.structure, 'customer_id', n)
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('order', ['doCreate']),
    onAfterCreate() {
      this.onCustomerChange(this.customerId)
    },
    onCustomerChange(val) {
      if (val) {
        const customer = this.$store.getters['customer/all'].find((item) => item.id == +val)
        this.row.customer_id = customer.id
        this.row.currency = customer.currency
        this.row.is_invoice = customer.is_invoice
        this.row.products = []
      }
    },
    onProductChoose() {
      if (!this.row.customer_id) {
        this.$message.error('請先選擇客戶')
        return false
      }

      const pid = this.row.products.map((x) => x.id)
      this.$refs.productChoose.init(pid)
    },
    onProductChosen(product) {
      this.row.products.push({
        id: product.id,
        status: 5,
        is_rush: 0,
        alias_id: product.alias_id,
        name: product.name,
        delivery_date: null,
        delivery_method: product.delivery_method,
        total_quantity: product.total_quantity,
        quantity: null,
        unit_price: product.unit_price,
        mold_price: null,
        deposit: 0,
        comment: null,
      })
    },
    onProductRemove(index) {
      this.row.products.splice(index, 1)
    },
    onBeforeSubmit() {
      if (this.row.products.length < 1) {
        this.$message.error('請選擇訂單產品')
        return false
      }
    },
  },
}
</script>
