<template>
  <el-dialog :visible.sync="modalShow" :close-on-click-modal="false" :width="modalWidth" top="5px" append-to-body>
    <template #title>
      <h3 class="m-0">報廢處理</h3>
    </template>
    <el-form v-if="modalShow" :ref="formRefs" :model="row" :rules="rules" label-width="120px">
      <div class="card">
        <div class="card-header">
          <h5 class="m-0">
            報廢編號：<span class="text-monospace">{{ row.sno }}</span>
          </h5>
        </div>
        <div class="card-body p-3">
          <div class="form-row">
            <el-form-item class="col-md-6 col-lg-4" label="客戶名稱">
              <h6>{{ row.customer && row.customer.name }}</h6>
            </el-form-item>
            <el-form-item class="col-md-6 col-lg-4" label="訂單編號">
              <h6 class="text-monospace">{{ row.order && row.order.sno }}</h6>
            </el-form-item>
            <el-form-item class="col-md-6 col-lg-4" label="對象編號">
              <h6 class="text-monospace">{{ row.targetable && row.targetable.sno }}</h6>
            </el-form-item>
            <div class="w-100"></div>
            <el-form-item class="col-12" label="產品名稱">
              <h6>{{ row.product && `(${row.product.alias_id}) ${row.product.name}` }}</h6>
            </el-form-item>
            <el-form-item class="col-md-6 col-lg-4" label="報廢數量" prop="quantity">
              <el-input v-model.number="row.quantity" :min="1" type="number" placeholder="報廢數量">
                <template #append> 個 </template>
              </el-input>
            </el-form-item>
            <el-form-item class="col-md-6 col-lg-4" label="報廢日期" prop="date">
              <el-date-picker v-model="row.date" type="date" value-format="yyyy-MM-dd" placeholder="報廢日期" />
            </el-form-item>
            <el-form-item class="col-md-6 col-lg-4" label="報廢倉儲" prop="warehouse_id">
              <el-select v-model.number="row.warehouse_id" placeholder="- 請選擇倉儲 -" filterable>
                <el-option
                  v-for="item in $store.getters['warehouse/scrapped']"
                  :key="item.id"
                  :label="item.name"
                  :value="Number(item.id)"
                />
              </el-select>
            </el-form-item>
            <el-form-item class="col" label="備註" prop="comment">
              <el-input v-model.trim="row.comment" placeholder="備註" />
            </el-form-item>
          </div>
        </div>
      </div>
      <div class="form-row mt-3">
        <div class="col text-right">
          <b-btn variant="secondary" @click.prevent="modalShow = false">
            <fa icon="times-circle" fixed-width />取消
          </b-btn>
          <b-btn type="submit" variant="primary" @click.prevent="onSubmit"> <fa icon="edit" fixed-width />確定 </b-btn>
        </div>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import { modify } from '@/plugins/mixins'
import { colors } from '@/plugins/consts'

export default {
  name: `ScrapSetting`,
  mixins: [modify],
  data() {
    return {
      structure: {
        targetable: {
          sno: null,
        },
        order: {
          sno: null,
        },
        product: {
          alias_id: null,
          name: null,
        },
        customer: {
          name: null,
        },
        warehouse_id: null,
      },
      rules: {
        date: [{ required: true, message: '請輸入報廢日期', trigger: 'change' }],
        quantity: [
          { required: true, message: '請輸入報廢數量', trigger: 'blur' },
          { type: 'number', min: 1, message: '請確認報廢數量', trigger: 'change' },
        ],
        warehouse_id: [{ required: true, message: '請選擇報廢倉儲', trigger: 'change' }],
      },
      colors,
    }
  },
  methods: {
    ...mapActions(`scrap`, ['fetchOne', 'doUpdate']),
  },
}
</script>
