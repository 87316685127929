import { mapGetters } from 'vuex'
import { debounce, omit } from 'lodash-es'

export const fetchData = {
  data() {
    return {
      query: {
        page: Number(this.$route.query.page) || 1,
        search: this.$route.query.search || null,
      },
      pagination: { current_page: 1, last_page: 1, total: 0 },
      rows: [],
      isLoading: false,
      settingRef: null,
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.parserQuery(to.query)
      vm.getAll()
    })
  },
  beforeRouteUpdate(to, from, next) {
    this.parserQuery(to.query)
    this.getAll()
    next()
  },
  methods: {
    parserQuery(query) {
      query.page = Number(query.page) || 1
      query = Object.assign({}, this.query, query)
      Object.keys(query).map((k) => {
        query[k] = query[k] === '0' ? 0 : Array.isArray(query[k]) ? query[k] : Number(query[k]) || query[k]
      })
      this.$set(this.$data, 'query', query)
    },
    routerPush: debounce(function (resetPage = false) {
      if (resetPage) {
        this.query.page = 1
      }
      this.$router.push({ query: this.query }).catch(() => {})
    }, 500),
    fetchAll() {},
    fetchPage() {},
    getAll() {
      this.isLoading = true
      const action = this.pagination === null ? this.fetchAll : this.fetchPage
      action(this.query)
        .then((result) => {
          if (this.pagination) {
            this.$set(this.$data, 'rows', result.data)
            this.$set(this.$data, 'pagination', omit(result, ['data']))
          } else {
            this.$set(this.$data, 'rows', result)
          }
          this.onAfterGetAll(result)
        })
        .catch((err) => console.log(err))
        .finally(() => {
          this.isLoading = false
        })
    },
    onCreate() {
      this.$refs[this.settingRef].init()
    },
    onUpdate(id) {
      this.$refs[this.settingRef].init(id)
    },
    onDelete(id, text = '刪除') {
      this.$confirm(`是否確定${text}？`, '注意', {
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      })
        .then(() => {
          this.doDelete(id).then(() => {
            this.$message.success(`${text}完成`)
            this.onAfterDelete()
          })
        })
        .catch(() => {
          this.$message.info(`${text}已取消`)
        })
    },
    onAfterGetAll(result) {
      return result
    },
    onAfterDelete() {
      this.getAll()
    },
  },
}

export const modalControl = {
  data() {
    return {
      isDirty: false,
      modalShow: false,
      modalMaxWidth: 1200,
      modalWidth: null,
    }
  },
  computed: {
    ...mapGetters({
      width: 'app/windowWidth',
    }),
  },
  watch: {
    width: {
      handler(newVal) {
        this.modalWidth = newVal <= this.modalMaxWidth ? '94%' : `${this.modalMaxWidth}px`
      },
      immediate: true,
    },
    modalShow(newVal, oldVal) {
      if (!newVal && oldVal) {
        this.hideModal()
      }
    },
  },
  methods: {
    onDone() {
      this.isDirty = true
    },
    onEmit() {
      this.$emit('done', this.row)
    },
    hideModal() {
      // 檢查當 modal 關閉時，如果內容有改變 isDirty = true，則通報父系組件進行更新
      if (this.isDirty) {
        this.onEmit()
      }

      this.isDirty = false
    },
  },
}

export const modify = {
  mixins: [modalControl],
  data() {
    return {
      row: {},
      structure: {},
      formAction: 'create',
      formRefs: 'modalForm',
      submitAction: null,
      returnPath: null,
    }
  },
  computed: {
    actionText() {
      return this.formAction === 'create' ? '新增' : '修改'
    },
  },
  created() {
    this.resetRow()
  },
  methods: {
    init(id = null) {
      const action = id !== null ? this.onUpdate : this.onCreate
      action(id)
    },
    resetRow() {
      this.$set(this.$data, 'row', JSON.parse(JSON.stringify(this.structure)))
    },
    // resetForm() {
    //   this.$refs[this.formRefs]?.resetFields()
    // },
    onCreate() {
      this.formAction = 'create'
      this.resetRow()
      this.onAfterCreate()
      this.modalShow = true
    },
    onAfterCreate() {},
    onUpdate(id) {
      this.formAction = 'update'
      this.fetchOne(id).then((result) => {
        this.$set(this.$data, 'row', result)
        this.onAfterUpdate(result)
        // this.resetForm()
        this.modalShow = true
      })
    },
    onAfterUpdate(result) {
      return result
    },
    onBeforeSubmit() {
      return true
    },
    onSubmit: debounce(function () {
      if (this.onBeforeSubmit() === false || this.$refs[this.formRefs] === undefined) {
        return false
      }

      this.$refs[this.formRefs].validate((valid) => {
        if (!valid) {
          this.$message.error('請重新確認輸入欄位')
          return false
        }

        let action =
          typeof this.submitAction === 'function'
            ? this.submitAction
            : this.formAction === 'create'
            ? this.doCreate
            : this.doUpdate

        action(this.row)
          .then((result) => {
            this.$set(this.$data, 'row', result)
            this.$message.success('處理完成')

            if (this.returnPath) {
              this.$router.push({ path: this.returnPath }).catch(() => {})
            } else {
              this.isDirty = true
              this.modalShow = false
              return result
            }
          })
          .catch(() => {
            // this.$message.error(data.message)
          })
      })
    }, 500),
  },
}

export const sort = {
  methods: {
    onSort(val) {
      this.$set(this.query, 'orderBy', (val.order && val.prop) || 'id')
      this.$set(this.query, 'sortedBy', val.order === 'ascending' ? 'asc' : 'desc')
      this.routerPush(true)
    },
  },
}

export const back = {
  methods: {
    onBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/').catch(() => {})
    },
  },
}

export const unique = {
  methods: {
    async doCheckUnique(rule, value, callback) {
      if (this.formAction === 'update') {
        callback()
      } else {
        const result = await this.checkUnique(value)
        if (result) {
          let error = [rule.field + ' already exists']
          callback(error)
        }
        callback()
      }
    },
  },
}

export const greaterThan = {
  methods: {
    async greaterThan(rule, value, callback) {
      let than = Number(rule.than) || 0
      if (+value <= than) {
        callback([rule.field + ' must greater than ' + than])
      }
      callback()
    },
  },
}
