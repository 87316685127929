<template>
  <div>
    <el-dialog :visible.sync="modalShow" title="產品庫存記錄" :width="modalWidth" top="5px" append-to-body>
      <h5 v-show="product" class="d-flex">
        {{ `(${product.alias_id}) ${product.name}` }}
        <div class="ml-auto">
          庫存量：<quantity :quantity="product.quantity" :required="product.required_quantity" class="d-inline" />
        </div>
        <div class="ml-auto col-sm-12 col-md-6 col-lg-3">
          <el-select v-model="query.type" placeholder="- 所有種類 -" clearable size="small" @change="onFilterType()">
            <el-option v-for="(type, key) in colors" :key="key" :label="type.type" :value="key" />
          </el-select>
        </div>
      </h5>
      <el-table v-loading="isLoading" :data="rows" stripe border @sort-change="onSort">
        <el-table-column label="建立時間" prop="created_at" width="210" sortable="custom" class-name="text-monospace" />
        <el-table-column v-slot="{ row }" label="種類" align="center" width="85">
          <span :class="['badge', `badge-${colors[prefix(row.stockable_type)].color}`]">
            {{
              row.stockable_type == 'App\\Models\\Shipment'
                ? shipmentType[row.stockable.type]
                : colors[prefix(row.stockable_type)].type
            }}
          </span>
        </el-table-column>
        <el-table-column prop="stockable.sno" label="編號" width="135" class-name="text-monospace" />
        <el-table-column label="承辦人" prop="user.name" width="75" />
        <el-table-column label="倉儲" prop="warehouse.name" width="90" />
        <el-table-column label="數量" prop="quantity" align="right" width="100" />
        <el-table-column v-slot="{ row }" label="備註" prop="comment">
          <el-popover trigger="click" placement="top" width="240" title="輸入備註">
            <template #default>
              <el-input v-model="row.comment" placeholder="備註" />
              <div class="form-row mt-2">
                <div class="col text-right">
                  <b-btn type="submit" variant="primary" size="sm" @click.prevent="onUpdateComment(row)">更新</b-btn>
                </div>
              </div>
            </template>
            <template #reference>
              <a href="#" @click.prevent>{{ row.comment }} <fa icon="edit" fixed-width /></a>
            </template>
          </el-popover>
        </el-table-column>
      </el-table>
      <pagination-modal :pagination="pagination" @page-change="(val) => (query.page = val)" />
      <div class="mt-3 d-flex">
        <b-btn variant="info" :to="`/print/stock-product?product_id=${query.product_id}`" target="_blank">
          <fa icon="print" fixed-width />列印
        </b-btn>
        <b-btn
          v-if="$store.getters['permission/check']([89]) && isInventory"
          variant="indigo"
          @click.prevent="$refs.productInventoryOne.init(query.product_id)"
        >
          <fa icon="calculator" fixed-width />盤點
        </b-btn>
        <b-btn class="ml-auto" variant="secondary" @click.prevent="modalShow = false">
          <fa icon="times-circle" fixed-width />關閉
        </b-btn>
      </div>
    </el-dialog>
    <product-inventory-one ref="productInventoryOne" @done="onDone" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { fetchData, modalControl, sort } from '@/plugins/mixins'
import { shipmentType } from '@/plugins/consts'

export default {
  name: 'ProductStockHistory',
  mixins: [fetchData, modalControl, sort],
  props: {
    isInventory: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      query: {
        product_id: null,
        orderBy: 'id',
        sortedBy: 'desc',
        type: '',
      },
      product: {},
      colors: {
        PR: { color: 'primary', type: '入庫' },
        SL: { color: 'orange', type: '出貨' },
        NG: { color: 'danger', type: '退貨' },
        RW: { color: 'warning', type: '入庫重工' },
        RI: { color: 'info', type: '重工' },
        SC: { color: 'dark', type: '報廢' },
        XX: { color: 'success', type: '盤點' },
      },
      modalMaxWidth: 900,
      shipmentType,
    }
  },
  watch: {
    'query.page'() {
      this.getAll()
    },
  },
  methods: {
    ...mapActions('stockProduct', ['fetchPage', 'doUpdate']),
    ...mapActions('product', ['fetchOne']),
    init(productId) {
      if (!productId) {
        return false
      }

      this.query.page = 1
      this.query.product_id = productId
      this.getProduct()
      this.getAll()
      this.modalShow = true
    },
    getProduct() {
      this.fetchOne(this.query.product_id).then((product) => {
        this.$set(this.$data, 'product', product)
      })
    },
    onSort(val) {
      this.$set(this.query, 'orderBy', (val.order && val.prop) || 'id')
      this.$set(this.query, 'sortedBy', val.order === 'ascending' ? 'asc' : 'desc')
      this.getAll()
    },
    onFilterType() {
      this.query.page = 1
      this.getAll()
    },
    // TODO: check this
    prefix(type) {
      switch (type) {
        case 'App\\Models\\Prepare':
          return 'PR'
        case 'App\\Models\\Shipment':
          return 'SL'
        case 'App\\Models\\ShipmentReturn':
          return 'NG'
        case 'App\\Models\\Rework':
          return 'RW'
        case 'App\\Models\\ReworkItem':
          return 'RI'
        case 'App\\Models\\Scrap':
          return 'SC'
        default:
          return 'XX'
      }
    },
    async onUpdateComment(row) {
      await this.doUpdate(row)
      this.getAll()
    },
    onDone() {
      this.isDirty = true
      this.getProduct()
      if (this.query.page !== 1) {
        this.query.page = 1
      } else {
        this.getAll()
      }
    },
  },
}
</script>
