<template>
  <el-dialog :visible.sync="modalShow" :close-on-click-modal="false" :width="modalWidth" top="5px" append-to-body>
    <template #title>
      <h3 class="m-0">
        {{ `${actionText}材料` }}
      </h3>
    </template>
    <el-form
      v-if="modalShow"
      :ref="formRefs"
      :model="row"
      :rules="rules"
      :disabled="!$store.getters['permission/check']([83])"
      label-width="100px"
    >
      <div class="card">
        <div class="card-body p-3">
          <div class="form-row">
            <el-form-item class="col-md-6" label="材料種類" prop="material_type_id">
              <el-select v-model.number="row.material_type_id" placeholder="- 請選擇 -" @change="onChangeType">
                <el-option
                  v-for="item in $store.getters['materialType/all']"
                  :key="item.id"
                  :label="item.name"
                  :value="Number(item.id)"
                />
              </el-select>
            </el-form-item>
            <el-form-item class="col-md-6" label="材料編號" prop="alias_id">
              <el-input v-model.trim="row.alias_id" placeholder="材料編號" />
            </el-form-item>
            <div class="w-100"></div>
            <el-tooltip placement="top" content="例：3.5*20mm, 請勿使用空格">
              <el-form-item class="col-12" label="材料名稱" prop="name">
                <el-input v-model.trim="row.name" placeholder="材料名稱" />
              </el-form-item>
            </el-tooltip>
            <div class="w-100"></div>
            <el-form-item class="col-md-6" label="單位重量" prop="unit_weight">
              <el-input v-model.number="row.unit_weight" type="number" min="0" placeholder="單位重量">
                <template #append>
                  <el-select v-model.number="row.measure">
                    <el-option
                      v-for="(item, index) in measureTypes"
                      :key="index"
                      :label="item"
                      :value="Number(index)"
                    />
                  </el-select>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item class="col-md-6" label="預設倉儲" prop="warehouse_id">
              <el-select v-model.number="row.warehouse_id" placeholder="- 請選擇預設倉儲 -" filterable>
                <el-option
                  v-for="item in $store.getters['warehouse/material']"
                  :key="item.id"
                  :label="item.name"
                  :value="Number(item.id)"
                />
              </el-select>
            </el-form-item>
            <el-form-item class="col-md-6" label="安全庫存" prop="safety_stock">
              <el-input v-model.number="row.safety_stock" type="number" :min="0" placeholder="安全庫存">
                <template #append> 個 </template>
              </el-input>
            </el-form-item>
            <el-form-item class="col-md-6" label="預設供料商" prop="vendor_id">
              <el-select v-model.number="row.vendor_id" clearable placeholder="- 請選擇 -">
                <el-option
                  v-for="item in $store.getters['customer/vendors']"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
            <el-form-item class="col" label="備註" prop="comment">
              <el-input v-model.trim="row.comment" placeholder="備註" />
            </el-form-item>
          </div>
        </div>
      </div>
      <div class="form-row mt-3">
        <div class="col text-right">
          <b-btn variant="secondary" @click.prevent="modalShow = false">
            <fa icon="times-circle" fixed-width />取消
          </b-btn>
          <b-btn
            v-if="$store.getters['permission/check']([83])"
            type="submit"
            variant="primary"
            @click.prevent="onSubmit"
          >
            <fa icon="edit" fixed-width />{{ actionText }}
          </b-btn>
        </div>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import { modify } from '@/plugins/mixins'
import { measureTypes } from '@/plugins/consts'

export default {
  name: 'MaterialSetting',
  mixins: [modify],
  data() {
    return {
      structure: {
        id: null,
        material_type_id: null,
        name: null,
        alias_id: null,
        measure: 1,
        vendor_id: null,
        unit_weight: null,
        warehouse_id: null,
        safety_stock: null,
        comment: null,
      },
      rules: {
        material_type_id: [{ required: true, message: '請選擇材料種類', trigger: 'change' }],
        name: [{ required: true, message: '請選擇材料名稱', trigger: 'blur' }],
        measure: [{ required: true, message: '請選擇度量單位', trigger: 'change' }],
        unit_weight: [
          { required: true, message: '請輸入單位重量', trigger: 'blur' },
          { type: 'number', min: 0, message: '單位重量最小爲 0', trigger: 'change' },
        ],
        warehouse_id: [{ required: true, message: '請選擇預設倉儲', trigger: 'change' }],
        safety_stock: [{ type: 'number', min: 0, message: '安全庫存量最小爲 0', trigger: 'change' }],
      },
      measureTypes,
      modalMaxWidth: 800,
    }
  },
  methods: {
    ...mapActions('material', ['fetchOne', 'doCreate', 'doUpdate']),
    onChangeType(val) {
      this.row.measure = this.$store.getters['materialType/all'].find((x) => x.id == +val)?.is_plate ? 0 : 1
    },
  },
}
</script>
