<template>
  <div class="d-flex flex-row flex-wrap align-items-center">
    <b-pagination
      :value="pagination.current_page"
      :total-rows="pagination.total"
      :limit="$store.state.app.device === 'mobile' ? 5 : 10"
      :per-page="pagination.per_page"
      size="md"
      class="mb-3 mr-3"
      @change="setPage"
    />
    <nav class="mb-3">
      <ul class="pagination pagination-md mb-0">
        <li class="page-item disabled">
          <span class="page-link text-nowrap">共 {{ pagination.last_page }} 頁 ( {{ pagination.total }} 項 )</span>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'PaginationModal',
  props: {
    pagination: {
      type: Object,
      default() {
        return {
          current_page: 1,
          per_page: 15,
          last_page: 1,
          total: 0,
        }
      },
    },
  },
  methods: {
    setPage(val) {
      this.$emit('page-change', val)
    },
  },
}
</script>
