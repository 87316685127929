<template>
  <div>
    <el-dialog :visible.sync="modalShow" :close-on-click-modal="false" :width="modalWidth" top="5px" append-to-body>
      <template #title>
        <h3 class="m-0">模具配件設定</h3>
      </template>
      <el-form v-if="modalShow" :ref="formRefs" :model="row" :rules="rules" label-width="100px">
        <div class="card">
          <div class="card-body p-3">
            <div class="form-row">
              <el-form-item class="col-md-6" label="配件名稱" prop="name">
                <el-input v-model.trim="row.name" placeholder="配件名稱" />
              </el-form-item>
              <div class="w-100"></div>
              <el-form-item class="col-md-6" label="狀態" prop="status">
                <el-select v-model.number="row.status" placeholder="- 請選擇 -">
                  <el-option
                    v-for="(status, index) in moldUnitStatus"
                    :key="index"
                    :label="status"
                    :value="Number(index)"
                  />
                </el-select>
              </el-form-item>
              <el-form-item class="col-md-6" label="成本" prop="cost">
                <el-input v-model.number="row.cost" type="number" :min="0" placeholder="成本">
                  <template #prepend> NT$ </template>
                </el-input>
              </el-form-item>
              <div class="w-100"></div>
              <el-form-item class="col" label="備註" prop="comment">
                <el-input v-model.trim="row.comment" placeholder="備註" />
              </el-form-item>
            </div>
          </div>
        </div>
        <div class="form-row mt-3">
          <div class="col text-right">
            <b-btn variant="secondary" @click.prevent="modalShow = false">
              <fa icon="times-circle" fixed-width />取消
            </b-btn>
            <b-btn type="submit" variant="primary" @click.prevent="onSubmit">
              <fa icon="edit" fixed-width />{{ actionText }}
            </b-btn>
          </div>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { moldUnitStatus } from '@/plugins/consts'
import { modify } from '@/plugins/mixins'
import { mapActions } from 'vuex'

export default {
  name: 'MoldUnitSetting',
  mixins: [modify],
  props: {
    moldId: {
      type: Number,
      default: null,
      required: true,
    },
  },
  data() {
    return {
      structure: {
        mold_id: this.moldId,
        name: null,
        status: null,
        cost: null,
        comment: null,
      },
      rules: {
        name: [{ required: true, message: '請輸入配件名稱', trigger: 'blur' }],
        status: [{ required: true, message: '請選擇狀態', trigger: 'change' }],
        cost: [{ required: true, type: 'number', min: 0, message: '請輸入成本', trigger: 'change' }],
      },
      modalMaxWidth: 800,
      moldUnitStatus,
    }
  },
  methods: {
    ...mapActions('moldUnit', ['fetchOne', 'doCreate', 'doUpdate', 'doDelete']),
  },
}
</script>
