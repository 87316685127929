<template>
  <div>
    <table
      v-for="mold in molds"
      :key="mold.id"
      :class="small ? 'table-sm' : ''"
      class="table table-bordered table-striped display"
    >
      <tbody>
        <tr class="vm">
          <td style="width: 13%">模具名稱</td>
          <td style="width: 20%">{{ mold.name }}</td>
          <td style="width: 13%">存放位置</td>
          <td style="width: 20%">{{ mold.warehouse && mold.warehouse.name }}</td>
          <td style="width: 13%">狀態</td>
          <td class="p-1">
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <b-badge :variant="colors[mold.status]">{{ moldStatus[mold.status] }}</b-badge>
              </div>
              <div class="ml-auto">
                <b-btn-group>
                  <b-btn
                    v-if="$store.getters['permission/check']([21])"
                    variant="primary"
                    size="sm"
                    @click.prevent="$emit('mold-edit', mold.id)"
                  >
                    <fa icon="cog" fixed-width />
                  </b-btn>
                  <b-btn
                    v-if="$store.getters['permission/check']([22])"
                    variant="danger"
                    size="sm"
                    @click.prevent="$emit('mold-delete', mold.id)"
                  >
                    <fa icon="trash-alt" fixed-width />
                  </b-btn>
                </b-btn-group>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td>模具生產商</td>
          <td>{{ mold.vendor && mold.vendor.name }}</td>
          <td>模具廠商編號</td>
          <td>{{ mold.vendor_alias_id }}</td>
          <td>所有權人</td>
          <td>{{ ownerShip[mold.owner_id] }}</td>
        </tr>
        <tr>
          <td>模具圖面</td>
          <td>
            <div v-if="mold.drawings.length > 0" class="float-left">
              <el-tooltip placement="top" content="點擊查看圖面">
                <a href="#" @click.prevent="$emit('mold-drawing', mold.drawings)"><fa icon="file-image" /></a>
              </el-tooltip>
            </div>
          </td>
          <td>建立時間</td>
          <td>{{ mold.created_at }}</td>
          <td>最後更新</td>
          <td>{{ mold.updated_at }}</td>
        </tr>
        <tr>
          <td>模具配件</td>
          <td colspan="5" class="p-0">
            <mold-unit :units="mold.units" :mold-id="mold.id" small @done="$emit('done')" />
          </td>
        </tr>
        <tr>
          <td>備註</td>
          <td colspan="5">{{ mold.comment }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { colors, moldStatus, ownerShip } from '@/plugins/consts'

export default {
  name: 'MoldInfo',
  props: {
    molds: {
      type: Array,
      default() {
        return [
          {
            id: null,
            status: 2,
            part_id: null,
            owner_id: 0,
            vendor_id: null,
            warehouse_id: null,
            vendor_alias_id: '',
            name: '',
            drawings: [],
            comment: '',
            vendor: { name: '' },
            warehouse: { name: '' },
          },
        ]
      },
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      colors,
      moldStatus,
      ownerShip,
    }
  },
}
</script>
