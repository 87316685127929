<template>
  <div class="table-responsive">
    <table
      v-for="part in parts"
      :key="part.id"
      :class="small ? 'table-sm' : ''"
      class="table table-bordered table-striped display"
    >
      <tbody>
        <tr class="vm">
          <td style="width: 13%">配件編號</td>
          <td style="width: 20%">{{ part.alias_id }}</td>
          <td style="width: 13%">配件名稱</td>
          <td style="width: 20%">{{ part.name }}</td>
          <td style="width: 13%">圖面版本</td>
          <td>
            <div class="d-flex justify-content-between align-items-center">
              <div v-if="part.drawings && part.drawings.length > 0" class="flex-fill">
                <span class="badge badge-warning">{{ part.drawings[0].revision }}</span>
                <el-tooltip placement="top" content="點擊查看圖面">
                  <a href="#" @click.prevent="$emit('show-drawings', part.drawings)"> <fa icon="file-image" /> </a>
                </el-tooltip>
              </div>
              <div v-if="full" class="flex-fill text-right">
                <b-btn-group>
                  <b-btn
                    v-if="$store.getters['permission/check']([17])"
                    variant="primary"
                    size="sm"
                    @click.prevent="$emit('part-edit', part.id)"
                  >
                    <fa icon="cog" fixed-width />
                  </b-btn>
                  <b-btn
                    v-if="part.is_mold > 0 && $store.getters['permission/check']([19])"
                    :to="`/product/${part.product_id}/part/${part.id}/mold`"
                    variant="dark"
                    size="sm"
                  >
                    <fa icon="inbox" fixed-width />
                  </b-btn>
                  <b-btn
                    v-if="$store.getters['permission/check']([18])"
                    variant="danger"
                    size="sm"
                    @click.prevent="$emit('part-delete', part.id)"
                  >
                    <fa icon="trash-alt" fixed-width />
                  </b-btn>
                </b-btn-group>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td>材料</td>
          <td colspan="3">{{ part.material | materialName }}</td>
          <td>材料單數</td>
          <td>
            <span class="badge badge-info">{{ part.material_to_quantity }}</span>
          </td>
        </tr>
        <tr>
          <td>是否開模</td>
          <td><yes-no :status="Boolean(part.is_mold)" is-text /></td>
          <td>預設倉儲</td>
          <td>{{ part.warehouse && part.warehouse.name }}</td>
          <td>組合用量</td>
          <td>
            <span class="badge badge-primary">{{ part.usage }}</span>
          </td>
        </tr>
        <tr>
          <td>配件單位重量</td>
          <td>{{ part.unit_weight | measure(part.measure) }}</td>
          <td>現有庫存數量</td>
          <td>
            <a href="#" @click.prevent="$refs.partStockHistory.init(part.id)">
              <quantity :quantity="part.quantity" />
            </a>
          </td>
          <td>安全庫存量</td>
          <td>{{ part.safety_stock }}</td>
        </tr>
        <tr v-if="full">
          <td>模具數量</td>
          <td>{{ part.molds_count }}</td>
          <td>建立時間</td>
          <td>{{ part.created_at }}</td>
          <td>最後更新</td>
          <td>{{ part.updated_at }}</td>
        </tr>
        <tr v-if="full">
          <td>生產製程</td>
          <td class="p-0" colspan="5">
            <table class="table table-bordered table-sm m-0">
              <thead>
                <tr>
                  <th style="width: 50px">順序</th>
                  <th style="width: 30%">製程名稱</th>
                  <th style="width: 30%">預設加工廠商</th>
                  <th>備註</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(proc, index2) in part.processes" :key="index2" class="bg-white">
                  <td class="text-center">{{ index2 + 1 }}</td>
                  <td>{{ proc.default_process && proc.default_process.name }}</td>
                  <td>{{ proc.vendor && proc.vendor.name }}</td>
                  <td>{{ proc.comment }}</td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr>
          <td>備註</td>
          <td colspan="5">{{ part.comment }}</td>
        </tr>
      </tbody>
    </table>
    <part-stock-history ref="partStockHistory" />
  </div>
</template>

<script>
export default {
  name: 'PartBasic',
  props: {
    parts: {
      type: Array,
      default() {
        return []
      },
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
    full: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
