<template>
  <el-dialog :visible.sync="modalShow" :close-on-click-modal="false" :width="modalWidth" top="55px" append-to-body>
    <template #title>
      <h3 class="m-0">
        {{ actionText + '出貨' }}
      </h3>
    </template>
    <el-form
      v-if="modalShow"
      :ref="formRefs"
      :model="row"
      :rules="rules"
      :disabled="!$store.getters['permission/check']([47])"
      label-width="120px"
    >
      <div class="card">
        <div v-if="row.sno" class="card-header px-2">
          <h5 class="m-0">
            進貨編號：<span class="text-monospace">{{ row.sno }}</span>
          </h5>
        </div>
        <div class="card-body p-3">
          <div class="form-row">
            <el-form-item class="col-md-6 col-lg-4" label="客戶名稱">
              <h6>{{ row.customer && row.customer.name }}</h6>
            </el-form-item>
            <el-form-item v-if="row.sno" class="col-md-6 col-lg-4" label="訂單編號">
              <h6 class="text-monospace">{{ row.order && row.order.sno }}</h6>
            </el-form-item>
            <el-form-item class="col-md-6 col-lg-4" label="訂貨數量">
              <h6>{{ row.order.quantity }} 個</h6>
            </el-form-item>
            <div class="w-100"></div>
            <el-form-item class="col" label="產品名稱">
              <h6>{{ row.product && `(${row.product.alias_id}) ${row.product.name}` }}</h6>
            </el-form-item>
            <div class="w-100"></div>
            <el-form-item class="col-md-6 col-lg-4" label="種類" prop="type">
              <el-radio-group v-model="row.type">
                <el-radio v-for="(label, index) in shipmentType" :key="index" :label="+index">{{ label }}</el-radio>
              </el-radio-group>
            </el-form-item>
            <div class="w-100"></div>
            <el-form-item class="col-md-6 col-lg-4" label="出貨日期" prop="date">
              <el-date-picker v-model="row.date" type="date" value-format="yyyy-MM-dd" placeholder="出貨日期" />
            </el-form-item>
            <el-form-item class="col-md-6 col-lg-4" label="出貨數量" prop="quantity">
              <el-input v-model.number="row.quantity" :min="1" type="number" placeholder="出貨數量">
                <template #append> 個 </template>
              </el-input>
            </el-form-item>
            <el-form-item class="col-md-6 col-lg-4" label="樣品數量" prop="sample_quantity">
              <el-input v-model.number="row.sample_quantity" :min="0" type="number" placeholder="樣品數量">
                <template #append> 個 </template>
              </el-input>
            </el-form-item>
            <el-form-item class="col-md-6 col-lg-4" label="產品單價" prop="unit_price">
              <el-input v-model.number="row.unit_price" type="number" :min="0" placeholder="單價">
                <template #prepend>
                  {{ row.customer && row.customer.currency | currencySymbol }}
                </template>
              </el-input>
            </el-form-item>
            <el-form-item class="col-md-6 col-lg-4" label="出貨倉儲" prop="warehouse_id">
              <el-select v-model.number="row.warehouse_id" placeholder="出貨倉儲" filterable>
                <el-option
                  v-for="item in $store.getters['warehouse/product']"
                  :key="item.id"
                  :label="item.name"
                  :value="Number(item.id)"
                />
              </el-select>
            </el-form-item>
            <el-form-item class="col-md-6 col-lg-4" label="送貨方式" prop="delivery_method">
              <el-select v-model.number="row.delivery_method" placeholder="送貨方式">
                <el-option v-for="(item, index) in deliveryMethods" :key="index" :label="item" :value="Number(index)" />
              </el-select>
            </el-form-item>
            <el-form-item class="col" label="備註">
              <el-input v-model="row.comment" placeholder="備註" />
            </el-form-item>
          </div>
        </div>
      </div>
      <div class="form-row mt-3">
        <div class="col text-right">
          <b-btn variant="secondary" @click.prevent="modalShow = false">
            <fa icon="times-circle" fixed-width />取消
          </b-btn>
          <b-btn
            v-if="$store.getters['permission/check']([47])"
            type="submit"
            variant="primary"
            @click.prevent="onSubmit"
          >
            <fa icon="edit" fixed-width />{{ actionText }}
          </b-btn>
        </div>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import { modify } from '@/plugins/mixins'
import { deliveryMethods, shipmentType } from '@/plugins/consts'

export default {
  name: 'ShipmentSetting',
  mixins: [modify],
  data() {
    return {
      structure: {
        order: {
          sno: null,
        },
        customer: {
          name: null,
        },
        product: {
          alias_id: null,
          name: null,
        },
        type: 1,
        quantity: null,
        sample_quantity: null,
        unit_price: null,
      },
      rules: {
        type: [{ required: true, message: '請選擇送貨種類', trigger: 'change' }],
        delivery_method: [{ required: true, message: '請選擇送貨方式', trigger: 'change' }],
        date: [{ required: true, message: '請選擇送貨日期', trigger: 'blur' }],
        quantity: [{ required: true, type: 'integer', min: 1, message: '請輸入出貨數量', trigger: 'change' }],
        sample_quantity: [{ required: true, type: 'integer', min: 0, message: '請輸入樣品數量', trigger: 'change' }],
        unit_price: [
          { required: true, message: '請輸入單價', trigger: 'blur' },
          { type: 'number', min: 0, message: '單價最小為 0', trigger: 'blur' },
        ],
        warehouse_id: [{ required: true, message: '請選擇出貨倉儲', trigger: 'change' }],
      },
      deliveryMethods,
      shipmentType,
    }
  },
  methods: {
    ...mapActions('shipment', ['fetchOne', 'doCreate', 'doUpdate']),
  },
}
</script>
