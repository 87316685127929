<template>
  <div>
    <el-dialog :visible.sync="modalShow" :close-on-click-modal="false" :width="modalWidth" top="5px">
      <template #title>
        <h3 class="m-0">修改製造訂單</h3>
      </template>
      <el-form v-if="modalShow" :ref="formRefs" :model="row" :rules="rules" label-width="100px">
        <div class="card">
          <div v-if="row.sno" class="card-header px-2">
            <h5 class="m-0">
              製造訂單編號：<span class="text-monospace">{{ row.sno }}</span>
            </h5>
          </div>
          <div class="card-body p-3">
            <div class="form-row">
              <el-form-item class="col-md-6 col-lg-4" label="客戶" prop="customer_id">
                <h6>{{ row.customer && row.customer.name }}</h6>
              </el-form-item>
              <el-form-item class="col-md-6 col-lg-4" label="客戶訂單號" prop="customer_order_id">
                <el-input v-model.trim="row.customer_order_id" placeholder="客戶訂單編號" />
              </el-form-item>
              <el-form-item class="col-md-6 col-lg-4" label="急件" prop="is_rush">
                <el-switch
                  v-model.number="row.is_rush"
                  :active-value="1"
                  :inactive-value="0"
                  active-text="是"
                  inactive-text="否"
                />
              </el-form-item>
              <div class="w-100"></div>
              <el-form-item class="col" label="產品名稱">
                <h6>{{ '(' + row.product.alias_id + ') ' + row.product.name }}</h6>
              </el-form-item>
              <div class="w-100"></div>
              <el-form-item class="col-md-6 col-lg-4" label="單價" prop="unit_price">
                <el-input v-model.number="row.unit_price" type="number" :min="0" placeholder="單價">
                  <template #prepend>
                    {{ row.currency | currencySymbol }}
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item class="col-md-6 col-lg-4" label="訂貨數量" prop="quantity">
                <el-input
                  v-model.number="row.quantity"
                  :disabled="row.purchase_items && row.purchase_items.length > 0"
                  type="number"
                  :min="1"
                  placeholder="訂貨數量"
                >
                  <template #append> 個 </template>
                </el-input>
              </el-form-item>
              <el-form-item class="col-md-6 col-lg-4" label="模具費用" prop="mold_price">
                <el-input v-model.number="row.mold_price" type="number" :min="0" placeholder="模具費用">
                  <template #prepend>
                    {{ row.currency | currencySymbol }}
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item class="col-md-6 col-lg-4" label="交貨日期" prop="delivery_date">
                <el-date-picker
                  v-model="row.delivery_date"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="交貨日期"
                />
              </el-form-item>
              <el-form-item class="col-md-6 col-lg-4" label="交貨方式" prop="delivery_method">
                <el-select v-model.number="row.delivery_method" placeholder="- 請選擇 -" filterable>
                  <el-option
                    v-for="(item, index) in deliveryMethods"
                    :key="index"
                    :label="item"
                    :value="Number(index)"
                  />
                </el-select>
              </el-form-item>
              <el-form-item class="col-md-6 col-lg-4" label="訂金" prop="deposit">
                <el-input v-model.number="row.deposit" type="number" :min="0" placeholder="訂金">
                  <template #prepend>
                    {{ row.currency | currencySymbol }}
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item class="col-md-6 col-lg-4" label="貨幣" prop="currency">
                <el-radio-group v-model="row.currency">
                  <el-radio :label="0">臺幣</el-radio>
                  <el-radio :label="1">美金</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item class="col-md-6 col-lg-4" label="應稅" prop="is_invoice">
                <el-switch
                  v-model.number="row.is_invoice"
                  :active-value="1"
                  :inactive-value="0"
                  active-text="是"
                  inactive-text="否"
                />
              </el-form-item>
              <div class="w-100"></div>
              <el-form-item class="col" label="備註" prop="comment">
                <el-input v-model="row.comment" placeholder="備註" />
              </el-form-item>
            </div>
          </div>
        </div>

        <div v-if="row.status < 6">
          <h4 class="mt-3">配件設定</h4>
          <table
            v-for="(part, index) in row.parts"
            :key="index"
            class="table table-bordered table-striped table-sm display"
          >
            <tbody>
              <template v-if="!part.is_combined">
                <tr class="vm">
                  <td style="width: 13%">配件編號</td>
                  <td style="width: 20%">{{ part.alias_id }}</td>
                  <td style="width: 13%">配件名稱</td>
                  <td style="width: 20%">{{ part.name }}</td>
                  <td style="width: 13%"><label class="m-0 required">材料單數</label></td>
                  <td>
                    <el-form-item
                      :prop="'parts[' + index + '].material_to_quantity'"
                      :rules="{ required: true, type: 'number', min: 1, message: ' ', trigger: 'blur' }"
                      class="m-0 p-0"
                      label-width="0"
                    >
                      <el-input
                        v-model.number="part.material_to_quantity"
                        :disabled="!part.is_mold"
                        type="number"
                        :min="1"
                        placeholder="材料單數"
                      >
                        <template #append> 個 </template>
                      </el-input>
                    </el-form-item>
                  </td>
                </tr>
                <tr class="vm">
                  <td><label class="m-0 required">材料</label></td>
                  <td colspan="3">
                    <el-form-item
                      :prop="'parts[' + index + '].material_id'"
                      :rules="{ required: true, message: ' ', trigger: 'blur' }"
                      class="m-0 p-0"
                      label-width="0"
                    >
                      <material-select v-model="part.material_id" />
                    </el-form-item>
                  </td>
                  <td><label class="m-0 required">組合用量</label></td>
                  <td>
                    <el-form-item
                      :prop="'parts[' + index + '].usage'"
                      :rules="{ required: true, type: 'number', min: 1, message: ' ', trigger: 'blur' }"
                      class="m-0 p-0"
                      label-width="0"
                    >
                      <el-input v-model.number="part.usage" type="number" :min="1" placeholder="組合用量">
                        <template #append> 個 </template>
                      </el-input>
                    </el-form-item>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td style="width: 13%">配件編號</td>
                  <td colspan="5">{{ part.alias_id }} <span class="badge badge-pink">組合配件</span></td>
                </tr>
                <tr>
                  <td>配件名稱</td>
                  <td colspan="5">{{ part.name }}</td>
                </tr>
                <tr class="vm">
                  <td><label class="m-0 required">組合用量</label></td>
                  <td style="width: 20%">
                    <el-form-item
                      :prop="'parts[' + index + '].usage'"
                      :rules="{ required: true, type: 'number', min: 1, message: ' ', trigger: 'blur' }"
                      class="m-0 p-0"
                      label-width="0"
                    >
                      <el-input v-model.number="part.usage" type="number" :min="1" placeholder="組合用量">
                        <template #append> 個 </template>
                      </el-input>
                    </el-form-item>
                  </td>
                  <td colspan="4">&nbsp;</td>
                </tr>
              </template>
              <tr>
                <td>生產製程</td>
                <td class="p-0" colspan="5">
                  <table class="table table-striped table-sm m-0">
                    <thead>
                      <tr>
                        <th class="text-center" style="width: 80px">拖曳排序</th>
                        <th style="width: 250px">製程名稱</th>
                        <th>備註</th>
                        <th style="width: 60px">
                          <b-btn class="float-right" size="sm" variant="success" @click.prevent="onAddProcess(part)">
                            <fa icon="plus-circle" fixed-width />
                          </b-btn>
                        </th>
                      </tr>
                    </thead>
                    <draggable v-model="part.process" ghost-class="ghost" :move="onDragProcess" tag="tbody">
                      <tr v-for="(proc, index2) in part.process" :key="index2" class="vm">
                        <td class="text-center table-light cursor"><fa icon="arrows-alt" fixed-width /></td>
                        <td class="table-light">
                          <el-form-item
                            :prop="'parts[' + index + '].process[' + index2 + '].id'"
                            :rules="{ required: true, message: ' ', trigger: 'blur' }"
                            class="m-0 p-0"
                            label-width="0"
                          >
                            <el-select v-model.number="proc.id" placeholder="- 請選擇製程 -" filterable>
                              <el-option-group
                                v-for="(group, index3) in $store.getters['defaultProcess/group']"
                                :key="index3"
                                :label="defaultProcessTypes[index3]"
                              >
                                <el-option
                                  v-for="item2 in group"
                                  :key="item2.id"
                                  :label="item2.name"
                                  :value="Number(item2.id)"
                                />
                              </el-option-group>
                            </el-select>
                          </el-form-item>
                        </td>
                        <td class="table-light">
                          <el-form-item
                            :prop="'parts[' + index + '].process[' + index2 + '].comment'"
                            class="m-0 p-0"
                            label-width="0"
                          >
                            <el-input v-model="proc.comment" placeholder="備註" />
                          </el-form-item>
                        </td>
                        <td class="table-light">
                          <b-btn
                            class="float-right"
                            size="sm"
                            variant="danger"
                            @click.prevent="onRemoveProcess(part, index2)"
                          >
                            <fa icon="trash-alt" fixed-width />
                          </b-btn>
                        </td>
                      </tr>
                    </draggable>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="form-row mt-3">
          <div class="col text-right">
            <b-btn variant="secondary" @click.prevent="modalShow = false">
              <fa icon="times-circle" fixed-width />取消
            </b-btn>
            <b-btn type="submit" variant="primary" @click.prevent="onSubmit">
              <fa icon="edit" fixed-width />修改
            </b-btn>
          </div>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { modify, greaterThan } from '@/plugins/mixins'
import { deliveryMethods, defaultProcessTypes } from '@/plugins/consts'
import Draggable from 'vuedraggable'

export default {
  name: 'OrderUpdate',
  components: { Draggable },
  mixins: [modify, greaterThan],
  data() {
    return {
      structure: {
        status: 0,
        product: {
          id: null,
          alias_id: null,
          name: null,
        },
        parts: [],
        customer: {
          name: null,
        },
      },
      rules: {
        customer_id: [{ required: true, message: '請選擇客戶', trigger: 'blur' }],
        delivery_date: [{ required: true, message: '請選擇交貨日期', trigger: 'blur' }],
        delivery_method: [{ required: true, message: '請選擇交貨方式', trigger: 'blur' }],
        is_rush: [{ required: true, message: '請選擇是否急件', trigger: 'blur' }],
        currency: [{ required: true, message: '請選擇訂單貨幣', trigger: 'blur' }],
        is_invoice: [{ required: true, message: '請選擇是否應稅', trigger: 'blur' }],
        unit_price: [
          { required: true, type: 'number', min: 0, message: '請確認產品單價', trigger: 'blur' },
          { type: 'number', validator: this.greaterThan, message: '單價必須大於 0', trigger: 'change' },
        ],
        mold_price: [{ required: false, type: 'number', min: 0, message: '請確認模具費用', trigger: 'blur' }],
        quantity: [
          { required: true, message: '請輸入訂貨數量', trigger: 'blur' },
          { type: 'number', min: 1, message: '訂貨數量最少爲 1', trigger: 'change' },
        ],
        deposit: [{ required: true, type: 'number', min: 0, message: '請確認訂金', trigger: 'blur' }],
      },
      deliveryMethods,
      defaultProcessTypes,
    }
  },
  methods: {
    ...mapActions('order', ['fetchOne', 'doUpdate']),
    onDragProcess({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element
      const draggedElement = draggedContext.element
      return (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
    },
    onAddProcess(part) {
      part.process.push({
        id: null,
        vendor_id: null,
        comment: null,
      })
    },
    onRemoveProcess(part, index) {
      part.process.splice(index, 1)
      if (part.process.length === 0) {
        part.process.push({
          id: 16,
          vendor_id: null,
          comment: null,
        })
      }
    },
  },
}
</script>
