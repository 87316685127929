<template>
  <div v-if="!item.meta.hidden">
    <template v-if="hasOneChild(item.children)">
      <router-link :key="menuItem.path" :to="{ path: menuItem.path }">
        <el-menu-item :index="menuItem.name" :class="{ 'submenu-title-noDropdown': !isNest }">
          <fa v-if="menuItem.meta && menuItem.meta.icon" :icon="menuItem.meta.icon" fixed-width />
          <template #title>
            <span v-if="menuItem.meta && menuItem.meta.title">
              {{ menuItem.meta.title }}
            </span>
          </template>
        </el-menu-item>
      </router-link>
    </template>

    <el-submenu v-else ref="subMenu" :index="item.name" popper-append-to-body>
      <template #title>
        <fa v-if="item.meta && item.meta.icon" :icon="item.meta.icon" fixed-width />
        <span v-if="item.meta && item.meta.title">
          {{ item.meta.title }}
        </span>
      </template>

      <sidebar-item v-for="child in item.children" :key="child.name" :item="child" :is-next="true" class="nest-menu" />
    </el-submenu>
  </div>
</template>

<script>
export default {
  name: 'SidebarItem',
  props: {
    item: {
      type: Object,
      default() {
        return {}
      },
      required: true,
    },
    isNest: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    menuItem() {
      let children = this.item.children
      return (children && children[0]) || this.item
    },
    device() {
      return this.$store.state.app.device
    },
  },
  methods: {
    hasOneChild(children = []) {
      let count = children.filter((child) => {
        return child.meta && child.meta.hidden === undefined
      })
      return count.length <= 1
    },
    fixBugIniOS() {
      const $subMenu = this.$refs.subMenu
      if ($subMenu) {
        const handleMouseleave = $subMenu.handleMouseleave
        $subMenu.handleMouseleave = (e) => {
          if (this.device === 'mobile') {
            return
          }
          handleMouseleave(e)
        }
      }
    },
  },
}
</script>
