<template>
  <div class="w-100">
    <table class="table table-bordered table-striped table-sm display">
      <tbody>
        <tr class="vm">
          <td style="width: 13%">退貨編號</td>
          <td style="width: 20%" class="text-monospace">
            <a href="#" @click.prevent="onReturn(target.id)">{{ target.sno }} <fa icon="edit" /> </a>
          </td>
          <td style="width: 13%">訂單編號</td>
          <td style="width: 20%" class="text-monospace">{{ target.order && target.order.sno }}</td>
          <td style="width: 13%">退貨日期</td>
          <td style="width: 20%">{{ target.date }}</td>
        </tr>
        <tr>
          <td>客戶訂單編號</td>
          <td>{{ target.order && target.order.customer_order_id }}</td>
          <td>產品名稱</td>
          <td colspan="3">{{ target.product && `(${target.product.alias_id}) ${target.product.name}` }}</td>
        </tr>
        <tr>
          <td>退貨數量</td>
          <td>{{ target.quantity }}</td>
          <td>產品單價</td>
          <td>{{ target.unit_price | currency(target.order.currency) }}</td>
          <td>承辦人</td>
          <td>{{ target.user && target.user.name }}</td>
        </tr>
        <tr>
          <td>後續處理</td>
          <td>
            <b-badge :variant="colors[target.subsequent]">{{ returnSubsequent[target.subsequent] }}</b-badge>
          </td>
          <td>處置方式</td>
          <td>
            <b-badge :variant="colors[target.disposal + 2]">{{ returnDisposal[target.disposal] }}</b-badge>
          </td>
          <td>入庫倉儲</td>
          <td>{{ target.warehouse && target.warehouse.name }}</td>
        </tr>
        <tr>
          <td>建立時間</td>
          <td>{{ target.created_at }}</td>
          <td>退貨原因</td>
          <td colspan="3">{{ target.reason }}</td>
        </tr>
      </tbody>
    </table>
    <shipment-return-setting ref="shipmentReturnSetting" @done="$emit('done')" />
  </div>
</template>

<script>
import { colors, returnSubsequent, returnDisposal } from '@/plugins/consts'

export default {
  name: 'AccountsReceivableShipmentReturn',
  props: {
    target: {
      type: Object,
      default() {
        return {
          sno: null,
          order: {},
          product: {},
          user: {},
          warehouse: {},
        }
      },
    },
  },
  data() {
    return {
      colors,
      returnSubsequent,
      returnDisposal,
    }
  },
  methods: {
    onReturn(id) {
      this.$refs.shipmentReturnSetting.init(id)
    },
  },
}
</script>
