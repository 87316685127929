<template>
  <div class="d-flex flex-row flex-wrap align-items-center">
    <b-pagination-nav
      :value="pagination.current_page"
      :number-of-pages="pagination.last_page"
      :link-gen="linkGen"
      :limit="$store.state.app.device === 'mobile' ? 5 : 10"
      use-router
      no-page-detect
      size="md"
      class="mr-3 mb-3"
    />
    <nav class="mb-3">
      <ul class="pagination pagination-md mb-0">
        <li class="page-item disabled">
          <span class="page-link text-nowrap">共 {{ pagination.last_page }} 頁 ( {{ pagination.total }} 項 )</span>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'PaginationNav',
  props: {
    query: {
      type: Object,
      default() {
        return {
          page: 1,
        }
      },
    },
    pagination: {
      type: Object,
      default() {
        return {
          current_page: 1,
          last_page: 1,
          total: 0,
        }
      },
      required: true,
    },
  },
  methods: {
    linkGen(page) {
      let query = Object.assign({}, this.query)
      query.page = page
      return { query }
    },
  },
}
</script>
