<template>
  <div>
    <el-dialog :visible.sync="modalShow" :close-on-click-modal="false" :width="modalWidth" top="5px" append-to-body>
      <template #title>
        <h3 class="m-0">應收賬款銷帳</h3>
      </template>
      <div class="row">
        <div class="col-md-2 col-lg-3 mb-3">
          <el-select
            v-model.number="query.customer_id"
            placeholder="- 所有廠商 -"
            filterable
            clearable
            @change="getAll(true)"
          >
            <el-option
              v-for="item in $store.getters['customer/consumers']"
              :key="item.id"
              :label="item.name"
              :value="Number(item.id)"
            />
          </el-select>
        </div>
        <div class="col-md-2 col-lg-3 mb-3">
          <el-input
            v-model.trim="query.search"
            placeholder="搜尋應收帳款編號、產品編號或名稱"
            clearable
            @change="getAll(true)"
            @keyup.enter="getAll(true)"
          >
            <template #append>
              <el-button @click.prevent="getAll(true)"><fa icon="search" /></el-button>
            </template>
          </el-input>
        </div>
        <div class="col-md-6 col-lg-4 mb-3">
          <el-date-picker
            v-model="query.estimate_receive_date"
            type="daterange"
            range-separator="至"
            start-placeholder="開始日期"
            end-placeholder="結束日期"
            value-format="yyyy-MM-dd"
            @change="getAll(true)"
          />
        </div>
        <div class="col-md-2 col-lg-2 mb-3">
          <el-select
            v-model.number="query.status"
            placeholder="- 全部狀態 -"
            filterable
            clearable
            @change="getAll(true)"
          >
            <el-option
              v-for="(item, index) in accountsReceivableStatus"
              :key="index"
              :label="item"
              :value="Number(index)"
            />
          </el-select>
        </div>
      </div>

      <div v-show="query.customer_id" class="row mb-3">
        <div class="col-6">
          <b-btn :disabled="selected.length == 0" variant="primary" size="sm" @click.prevent="onCheckout">
            <fa icon="file-invoice-dollar" fixed-width />銷帳
          </b-btn>
        </div>
        <div class="col-6 text-right">
          <strong>小計金額: {{ subTotal | currency(customer.currency, 3) }}</strong>
        </div>
      </div>

      <div v-show="query.customer_id">
        <el-table
          ref="checkoutTable"
          v-loading="isLoading"
          :data="rows"
          size="small"
          stripe
          border
          @selection-change="onSelected"
        >
          <el-table-column type="selection" width="40" :selectable="(row) => row.status == 2" align="center" />
          <el-table-column prop="sno" label="應收帳款編號" width="110" class-name="text-monospace" />
          <el-table-column prop="targetable.sno" label="對象編號" width="110" class-name="text-monospace" />
          <el-table-column v-slot="{ row }" label="種類" width="60" align="center">
            <b-badge :variant="colors[row.type + 1]">{{ accountsTypes[row.type] }}</b-badge>
          </el-table-column>
          <el-table-column v-slot="{ row }" label="狀態" width="85" align="center">
            <b-badge :variant="colors[row.status]">{{ accountsReceivableStatus[row.status] }}</b-badge>
          </el-table-column>
          <el-table-column v-slot="{ row }" label="對象名稱" show-overflow-tooltip>
            {{
              row.targetable && row.targetable.product
                ? `(${row.targetable.product.alias_id}) ${row.targetable.product.name}`
                : ''
            }}
          </el-table-column>
          <el-table-column v-slot="{ row }" label="日期" width="100" class-name="text-monospace">
            {{ row.targetable && row.targetable.date }}
          </el-table-column>
          <el-table-column v-slot="{ row }" label="數量" width="80" align="right">
            {{
              ['App\\Models\\Shipment', 'App\\Models\\ShipmentReturn'].includes(row.targetable_type) &&
              row.targetable &&
              row.targetable.quantity
                ? (row.receivable > 0 ? '' : '-') + row.targetable.quantity
                : 1
            }}
          </el-table-column>
          <el-table-column label="結算日期" prop="estimate_receive_date" width="100" class-name="text-monospace" />
          <el-table-column v-slot="{ row }" label="應收金額" width="140" align="right" class-name="text-monospace">
            {{ row.receivable | currency(customer.currency) }}
          </el-table-column>
          <el-table-column v-slot="{ row }" label="操作" width="55">
            <b-btn size="sm" variant="outline-dark" @click.prevent="onUpdate(row)">
              <fa icon="cog" fixed-width />
            </b-btn>
          </el-table-column>
        </el-table>
        <div v-show="query.customer_id" class="row mb-3">
          <div class="col-6">
            <b-btn :disabled="selected.length == 0" variant="primary" size="sm" @click.prevent="onCheckout">
              <fa icon="file-invoice-dollar" fixed-width />銷帳
            </b-btn>
          </div>
          <div class="col-6 text-right">
            <strong>小計金額: {{ subTotal | currency(customer.currency) }}</strong>
          </div>
        </div>
        <pagination-modal :pagination="pagination" @page-change="(val) => (query.page = val)" />
      </div>
      <div class="mt-3 d-flex">
        <b-btn class="ml-auto" variant="secondary" @click.prevent="modalShow = false">
          <fa icon="times-circle" fixed-width />關閉
        </b-btn>
      </div>
    </el-dialog>
    <accounts-receivable-setting ref="receivableSetting" @done="onDone" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { colors, accountsReceivableStatus, accountsTypes } from '@/plugins/consts'
import { modalControl } from '@/plugins/mixins'
import { differenceBy, intersectionBy, uniqBy } from 'lodash-es'

export default {
  name: 'AccountsReceivableCheckout',
  mixins: [modalControl],
  props: {
    customerId: {
      type: Number,
      default: null,
    },
    dateRange: {
      type: Array,
      default() {
        return []
      },
    },
    status: {
      type: Number,
      default: 2,
    },
  },
  data() {
    return {
      query: {
        customer_id: null,
        page: 1,
        status: 2,
        estimate_receive_date: [],
      },
      pagination: { current_page: 1, last_page: 1, total: 0 },
      rows: [],
      isLoading: false,
      selected: [],
      colors,
      accountsReceivableStatus,
      accountsTypes,
    }
  },
  computed: {
    customer() {
      return this.$store.state.customer.data.find((x) => x.id == this.query.customer_id) || {}
    },
    subTotal() {
      return this.selected.length > 0 ? this.selected.map((s) => s.receivable).reduce((a, b) => a + b, 0) : 0
    },
  },
  watch: {
    'query.page'(val) {
      if (val) {
        this.getAll()
      }
    },
    'query.customer_id'() {
      this.selected = []
    },
    customerId: {
      handler(val) {
        this.query.customer_id = val
      },
      immediate: true,
    },
    status: {
      handler(val) {
        this.query.status = val
      },
      immediate: true,
    },
    dateRange: {
      handler(val) {
        this.query.estimate_receive_date = val
      },
      immediate: true,
    },
    rows: {
      handler(val) {
        let intersection = intersectionBy(this.selected, val, 'id') || []
        this.$nextTick(() => {
          intersection.forEach((row) => {
            let index = this.rows.findIndex((r) => r.id === row.id)
            this.$refs.checkoutTable.toggleRowSelection(this.rows[index], true)
          })
        })
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    ...mapActions('accountsReceivable', ['fetchAll', 'doCheckout']),
    init() {
      this.modalShow = true
      if (this.customerId) {
        this.getAll()
      }
    },
    getAll(reset = false) {
      if (!this.query.customer_id) {
        this.$message.error('請先選擇客戶')
        return false
      }

      if (reset) {
        this.query.page = 1
      }

      this.isLoading = true
      this.fetchAll(this.query).then((result) => {
        this.$set(this.$data, 'rows', result.data)
        delete result['data']
        this.$set(this.$data, 'pagination', result)
        this.isLoading = false
      })
    },
    onSelected(checked) {
      this.selected = differenceBy(
        uniqBy([].concat(this.selected).concat(intersectionBy(this.rows, checked, 'id')), 'id'),
        differenceBy(this.rows, checked, 'id'),
        'id'
      )
    },
    onUpdate(row) {
      this.$refs.receivableSetting.init(row.id)
    },
    onDone() {
      this.isDirty = true
      this.getAll()
    },
    onCheckout() {
      if (this.selected.length < 1) {
        this.$message.error('請選擇銷帳項目')
        return false
      }

      this.$confirm(`是否確定銷帳？`, '注意', {
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      })
        .then(() => {
          let selected = this.selected.map((s) => s.id)
          this.doCheckout({ ids: selected }).then(() => {
            this.$message.success(`銷帳完成`)
            this.isDirty = true
            this.selected = []
            this.getAll(true)
          })
        })
        .catch(() => {
          this.$message.info(`已取消`)
        })
    },
    hideModal() {
      this.$set(this.$data, 'query', {
        customer_id: this.customerId || null,
        page: 1,
        status: this.status || null,
        estimate_receive_date: this.dateRange.length > 0 ? this.dateRange : [],
      })

      this.$set(this.$data, 'pagination', { current_page: 1, last_page: 1, total: 0 })
      this.rows = []
      this.selected = []

      if (this.isDirty) {
        this.onEmit()
      }

      this.isDirty = false
    },
  },
}
</script>
