<template>
  <el-dialog :visible.sync="modalShow" :close-on-click-modal="false" :width="modalWidth" top="5px" append-to-body>
    <template #title>
      <h3 class="m-0">產品盤點</h3>
    </template>
    <div class="alert alert-warning" role="alert">
      <strong>注意</strong>：如果庫存數量正確，請「保留空白」，有輸入「校正庫存」的欄位才會做「庫存校正」
    </div>
    <el-form v-if="modalShow" :ref="formRefs" :model="row" label-position="top" size="mini">
      <table class="table table-sm m-0">
        <tbody>
          <tr>
            <td>產品</td>
            <td style="width: 140px">倉儲位置</td>
            <td style="width: 40px"></td>
            <td class="text-right" style="width: 100px">系統庫存</td>
            <td class="text-right" style="width: 100px">校正庫存</td>
            <td style="width: 44px" />
          </tr>
        </tbody>
      </table>
      <table
        v-for="(product, index) in row.products"
        :key="product.id"
        class="table table-border table-hover table-sm m-0"
      >
        <tbody>
          <tr v-for="(quantity, index2) in product.quantity" :key="index2" class="vm">
            <template v-if="index2 === 0">
              <td>{{ `(${product.alias_id}) ${product.name}` }}</td>
            </template>
            <template v-else>
              <td />
            </template>
            <td style="width: 140px">
              <el-form-item
                :rules="{ required: true, message: ' ', trigger: 'change' }"
                :prop="`products[${index}].quantity[${index2}].id`"
                class="m-0 p-0"
              >
                <el-select v-model.number="quantity.id" placeholder="倉儲位置" filterable>
                  <el-option
                    v-for="warehouse in $store.getters['warehouse/inventory']"
                    :key="warehouse.id"
                    :label="warehouse.name"
                    :value="warehouse.id"
                  />
                </el-select>
              </el-form-item>
            </td>
            <td style="width: 40px">
              <b-badge v-if="quantity.is_default" variant="teal">預設</b-badge>
            </td>
            <td class="text-right" style="width: 100px">{{ quantity.qty }}</td>
            <td style="width: 100px">
              <el-form-item
                :rules="{
                  type: 'integer',
                  min: 0,
                  message: ' ',
                  trigger: 'change',
                  transform: (val) => Number(val),
                }"
                :prop="`products[${index}].quantity[${index2}].new_qty`"
                class="m-0 p-0"
              >
                <el-input v-model.number="quantity.new_qty" :min="0" type="number" />
              </el-form-item>
            </td>
            <td style="width: 44px">
              <span v-if="quantity.is_default" class="text-success" @click.prevent="onAddWarehouse(product.quantity)">
                <fa icon="plus-circle" class="fa-lg" fixed-width />
              </span>
              <span v-else class="text-danger" @click.prevent="onRemoveWarehouse(product.quantity, index2)">
                <fa icon="times-circle" class="fa-lg" fixed-width />
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="form-row mt-3">
        <div class="col text-right">
          <b-btn variant="secondary" @click.prevent="modalShow = false">
            <fa icon="times-circle" fixed-width />取消
          </b-btn>
          <b-btn type="submit" variant="primary" @click.prevent="onSubmit"> <fa icon="edit" fixed-width />修改 </b-btn>
        </div>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import { modify } from '@/plugins/mixins'
import { orderBy } from 'lodash-es'

export default {
  name: 'ProductInventory',
  mixins: [modify],
  data() {
    return {
      structure: {
        products: [],
      },
    }
  },
  created() {
    this.submitAction = this.doProductsInventory
  },
  methods: {
    ...mapActions('product', ['fetchAll', 'doProductsInventory']),
    onUpdate(id) {
      this.formAction = 'update'
      this.fetchAll({ customer_id: id }).then((results) => {
        let products = results.map((pdt) => {
          // 如果沒有預設倉儲，補 0
          if (pdt.quantity.find((x) => x.id == +pdt.warehouse_id) === undefined) {
            pdt.quantity.push({ id: +pdt.warehouse_id, qty: 0 })
          }

          pdt.quantity = orderBy(
            pdt.quantity.map((q) => {
              q.new_qty = null
              q.is_default = q.id === +pdt.warehouse_id
              return q
            }),
            ['is_default', 'id'],
            ['desc', 'asc']
          )
          return pdt
        })
        this.$set(this.$data, 'row', { products })
        this.modalShow = true
      })
    },
    onAddWarehouse(quantity) {
      quantity.push({
        id: null,
        qty: null,
        new_qty: null,
        is_default: false,
      })
    },
    onRemoveWarehouse(quantity, index) {
      quantity.splice(index, 1)
    },
  },
}
</script>
