<template>
  <table class="table table-bordered table-striped table-sm">
    <thead>
      <tr>
        <th style="width: 170px">重工編號</th>
        <th style="width: 170px">對象編號</th>
        <th>重工入庫日期</th>
        <th style="width: 80px" class="text-center">狀態</th>
        <th class="text-right">重工入庫數量</th>
        <th class="text-right">重工完成數量</th>
        <th class="text-right">報廢數量</th>
        <th>備註</th>
        <th style="width: 50px">操作</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="rework in items" :key="rework.id" class="vm">
        <td class="text-monospace">
          <a href="#" @click.prevent="$emit('rework-update', rework.id)">{{ rework.sno }} <fa icon="edit" /></a>
        </td>
        <td class="text-monospace">
          {{ rework.targetable && rework.targetable.sno }}
        </td>
        <td>{{ rework.date }}</td>
        <td class="text-center">
          <b-badge :variant="colors[rework.status]">{{ reworkStatus[rework.status] }}</b-badge>
        </td>
        <td class="text-right">{{ rework.quantity }}</td>
        <td class="text-right">{{ rework.finish_quantity }}</td>
        <td class="text-right">{{ rework.scrap_quantity }}</td>
        <td>{{ rework.comment }}</td>
        <td>
          <b-btn
            v-if="rework.targetable_type == 'App\\Models\\Prepare'"
            variant="danger"
            size="sm"
            @click.prevent="$emit('rework-delete', rework.id)"
          >
            <fa icon="trash" fixed-width />
          </b-btn>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { reworkStatus, colors } from '@/plugins/consts'

export default {
  name: 'ReworkList',
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      reworkStatus,
      colors,
    }
  },
}
</script>
