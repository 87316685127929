<template>
  <el-dialog :visible.sync="modalShow" :close-on-click-modal="false" :width="modalWidth" top="5px" append-to-body>
    <template #title>
      <h3 class="m-0">{{ actionText }}包裝入庫</h3>
    </template>
    <el-form
      v-if="modalShow"
      :ref="formRefs"
      :model="row"
      :rules="rules"
      label-width="120px"
      :disabled="!$store.getters['permission/check']([35])"
    >
      <div class="card">
        <div v-if="row.sno" class="card-header px-2">
          <h5 class="m-0">
            包裝入庫編號：<span class="text-monospace">{{ row.sno }}</span>
          </h5>
        </div>
        <div class="card-body p-3">
          <div class="form-row">
            <el-form-item class="col-md-6 col-lg-4" label="客戶名稱">
              <h6>{{ row.customer && row.customer.name }}</h6>
            </el-form-item>
            <el-form-item class="col-md-6 col-lg-4" label="製造訂單號">
              <h6 class="text-monospace">{{ row.order && row.order.sno }}</h6>
            </el-form-item>
            <div class="w-100"></div>
            <el-form-item class="col" label="產品名稱">
              <h6>{{ row.product && `(${row.product.alias_id}) ${row.product.name}` }}</h6>
            </el-form-item>
            <div class="w-100"></div>
            <el-form-item class="col-md-6 col-lg-4" label="訂貨數量">
              <h6>{{ row.order && row.order.quantity }} 個</h6>
            </el-form-item>
            <el-form-item class="col-md-6 col-lg-4" label="已入庫數量">
              <h6>{{ row.order && row.order.production_quantity }} 個</h6>
            </el-form-item>
            <div class="w-100"></div>
            <el-form-item class="col-md-6 col-lg-4" label="入庫數量" prop="quantity">
              <el-input v-model.number="row.quantity" type="number" placeholder="入庫數量" autofocus>
                <template #append> 個 </template>
              </el-input>
            </el-form-item>
            <el-form-item class="col-md-6 col-lg-4" label="入庫日期" prop="date">
              <el-date-picker v-model="row.date" type="date" value-format="yyyy-MM-dd" placeholder="入庫日期" />
            </el-form-item>
            <el-form-item class="col-md-6 col-lg-4" label="入庫倉儲" prop="warehouse_id">
              <el-select v-model.number="row.warehouse_id" placeholder="- 請選擇倉儲 -" filterable>
                <el-option
                  v-for="item in $store.getters['warehouse/product']"
                  :key="item.id"
                  :label="item.name"
                  :value="Number(item.id)"
                />
              </el-select>
            </el-form-item>
            <el-form-item class="col" label="備註" prop="comment">
              <el-input v-model.trim="row.comment" placeholder="備註" />
            </el-form-item>
          </div>
        </div>
      </div>
      <div class="form-row mt-3">
        <div class="col text-right">
          <b-btn variant="secondary" @click.prevent="modalShow = false">
            <fa icon="times-circle" fixed-width />取消
          </b-btn>
          <b-btn
            v-if="$store.getters['permission/check']([35])"
            type="submit"
            variant="primary"
            @click.prevent="onSubmit"
          >
            <fa icon="edit" fixed-width />修改
          </b-btn>
        </div>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import { modify } from '@/plugins/mixins'
export default {
  name: 'PrepareSetting',
  mixins: [modify],
  data() {
    return {
      structure: {
        order: {
          sno: null,
          quantity: null,
          production_quantity: null,
        },
        customer: { name: null },
        product: { id: null, alias_id: null, name: null },
      },
      rules: {
        quantity: [{ required: true, message: '請輸入入庫數量', type: 'number', min: 1, trigger: 'change' }],
        date: [{ required: true, message: '請選擇入庫日期', trigger: 'change' }],
        warehouse_id: [{ required: true, message: '請輸入入庫數量', trigger: 'change' }],
      },
    }
  },
  methods: {
    ...mapActions('prepare', ['fetchOne', 'doUpdate']),
  },
}
</script>
