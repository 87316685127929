<template>
  <div class="table-responsive">
    <table :class="small ? 'table-sm' : ''" class="table table-bordered table-striped display">
      <tbody>
        <tr>
          <td style="width: 13%">製造訂單編號</td>
          <td class="text-monospace" style="width: 20%">{{ order.sno }}</td>
          <td style="width: 13%">客戶名稱</td>
          <td style="width: 20%">{{ customer.name }}</td>
          <td style="width: 13%">客戶訂單編號</td>
          <td>{{ order.customer_order_id }}</td>
        </tr>
        <tr>
          <td>訂單狀態</td>
          <td>
            <b-badge :variant="colors[order.status]">{{ orderStatus[order.status] }}</b-badge>
          </td>
          <td>交貨日期</td>
          <td>
            <order-delivery-date
              :history="order.delivery_date_history"
              :delivery-date="order.delivery_date"
              :is-rush="order.is_rush"
            />
          </td>
          <td>送貨方式</td>
          <td>
            <b-badge :variant="colors.slice(1)[order.delivery_method]">
              {{ deliveryMethods[order.delivery_method] }}
            </b-badge>
          </td>
        </tr>
        <tr v-if="full">
          <td>應稅</td>
          <td><yes-no :status="Boolean(order.is_invoice)" is-text /></td>
          <td>建立時間</td>
          <td>{{ order.created_at }}</td>
          <td>最後更新</td>
          <td>{{ order.updated_at }}</td>
        </tr>
        <tr v-if="$store.getters['permission/check']([1])">
          <td>產品單價</td>
          <td>{{ order.unit_price | currency(order.currency) }}</td>
          <td>模具費用</td>
          <td>{{ order.mold_price | currency(order.currency) }}</td>
          <td>訂金</td>
          <td>{{ order.deposit | currency(order.currency) }}</td>
        </tr>
        <tr>
          <td>訂貨數量</td>
          <td>{{ order.quantity }}</td>
          <td>已入庫數量</td>
          <td>{{ order.production_quantity }}</td>
          <td>已出貨數量</td>
          <td>{{ order.shipment_quantity }}</td>
        </tr>
        <tr>
          <td>未出貨數量</td>
          <td>{{ order.quantity - order.shipment_quantity + order.replenish_quantity }}</td>
          <td>退貨數量</td>
          <td>{{ order.return_quantity }}</td>
          <td>補貨數量</td>
          <td>{{ order.replenish_quantity }}</td>
        </tr>
        <!-- <tr v-if="full && $store.getters['permission/check']([1])">
          <td>小計金額</td>
          <td>{{ order.subtotal | currency(order.currency) }}</td>
          <td>發票稅金</td>
          <td>{{ ((order.subtotal * (customer.tax || 5)) / 100) | currency(order.currency) }}</td>
          <td>總計金額</td>
          <td>
            {{ ((order.subtotal * (customer.tax || 5)) / 100 + order.subtotal) | currency(order.currency) }}
          </td>
        </tr> -->
        <tr>
          <td>備註</td>
          <td colspan="5">{{ order.comment }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { colors, orderStatus, deliveryMethods } from '@/plugins/consts'

export default {
  name: 'OrderInfo',
  props: {
    order: {
      type: Object,
      default() {
        return {
          id: null,
          sno: null,
          status: 2,
          customer_id: null,
          product_id: null,
          currency: 0,
          is_invoice: 1,
          customer_order_id: null,
          delivery_date: null,
          delivery_method: 0,
          quantity: 0,
          production_quantity: 0,
          unit_price: 0,
          mold_price: 0,
          subtotal: 0,
          deposit: 0,
          comment: null,
          created_at: null,
          updated_at: null,
        }
      },
    },
    small: {
      type: Boolean,
      default: false,
    },
    full: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      orderStatus,
      colors,
      deliveryMethods,
    }
  },
  computed: {
    customer() {
      return this.$store.getters['customer/consumers'].find((x) => x.id == this.order.customer_id) || {}
    },
  },
}
</script>
