<template>
  <el-dialog :visible.sync="modalShow" :close-on-click-modal="false" :width="modalWidth" top="5px" append-to-body>
    <template #title>
      <h3 class="m-0">
        {{ actionText + '模具訂單' }}
      </h3>
    </template>
    <el-form v-if="modalShow" :ref="formRefs" :model="row" label-width="120px">
      <div class="card">
        <div class="card-body p-3">
          <div class="form-row">
            <el-form-item class="col-md-6" label="模具訂單編號">
              <h6 class="text-monospace">{{ row.sno }}</h6>
            </el-form-item>
            <el-form-item
              :rules="{ required: true, message: '請選擇模具生產商', trigger: 'change' }"
              class="col-md-6"
              label="模具生產商"
              prop="vendor_id"
            >
              <el-select v-model.number="row.vendor_id" :disabled="row.status != 2" placeholder="- 請選擇 -">
                <el-option
                  v-for="vendor in $store.getters['customer/molds']"
                  :key="vendor.id"
                  :label="vendor.name"
                  :value="Number(vendor.id)"
                />
              </el-select>
            </el-form-item>
          </div>
          <div class="form-row">
            <el-form-item
              :rules="{ required: row.status != 2, message: '請選輸入完成日期', trigger: 'change' }"
              class="col-sm-6"
              label="完成日期"
              prop="date"
            >
              <el-date-picker v-model="row.date" type="date" value-format="yyyy-MM-dd" placeholder="完成日期" />
            </el-form-item>
            <el-form-item class="col-sm-6" label="備註" prop="comment">
              <el-input v-model.trim="row.comment" placeholder="備註" />
            </el-form-item>
          </div>
        </div>
      </div>
      <table class="table table-bordered table-sm display mt-3">
        <tbody>
          <tr>
            <td class="bg-light" style="width: 13%">產品編號</td>
            <td style="width: 20%">{{ row.product && row.product.alias_id }}</td>
            <td class="bg-light" style="width: 13%">產品名稱</td>
            <td style="width: 20%">{{ row.product && row.product.name }}</td>
            <td class="bg-light" style="width: 13%">訂單狀態</td>
            <td>
              <b-badge :variant="colors[row.status]">{{ moldOrderStatus[row.status] }}</b-badge>
            </td>
          </tr>
          <tr>
            <td class="bg-light">配件編號</td>
            <td>{{ row.part && row.part.alias_id }}</td>
            <td class="bg-light">配件名稱</td>
            <td>{{ row.part && row.part.name }}</td>
            <td class="bg-light">模具訂單費用</td>
            <td>
              <span v-if="$store.getters['permission/check']([1])">{{ row.cost | currency }}</span>
            </td>
          </tr>
        </tbody>
      </table>
      <table class="table table-bordered table-sm mt-3">
        <thead class="thead-light">
          <tr>
            <th>模具名稱</th>
            <th class="text-center" style="width: 100px">狀態</th>
            <th>模具廠商編號</th>
            <th>所有權人</th>
            <th>存放位置</th>
            <th>備註</th>
            <th v-if="row.status === 2 && $store.getters['permission/check']([25])" style="width: 48px">
              <b-btn variant="success" size="sm" @click.prevent="onAddMold(row.part_id)">
                <fa icon="plus-circle" fixed-width />
              </b-btn>
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(mold, index) in row.molds">
            <tr :key="mold.id" class="table-warning">
              <td>
                <el-form-item
                  :prop="`molds[${index}].name`"
                  :rules="{ required: true, message: ' ', trigger: 'blur' }"
                  class="p-0 m-0"
                  label-width="0"
                >
                  <el-input
                    v-model="mold.name"
                    :disabled="row.status < 2 || mold.status < 2"
                    placeholder="模具名稱"
                    size="small"
                  />
                </el-form-item>
              </td>
              <td class="text-center pt-2">
                <b-badge :variant="colors[mold.status]">{{ moldStatus[mold.status] }}</b-badge>
              </td>
              <td>
                <el-form-item class="p-0 m-0" label-width="0">
                  <el-input
                    v-model="mold.vendor_alias_id"
                    :disabled="[0, 2].includes(mold.status)"
                    placeholder="模具廠商編號"
                    size="small"
                  />
                </el-form-item>
              </td>
              <td>
                <el-form-item
                  :prop="`molds[${index}].owner_id`"
                  :rules="{ required: true, message: ' ', trigger: 'change' }"
                  class="p-0 m-0"
                  label-width="0"
                >
                  <el-select v-model="mold.owner_id" :disabled="row.status < 2" placeholder="- 請選擇 -" size="small">
                    <el-option
                      v-for="(owner, index2) in ownerShip"
                      :key="index2"
                      :label="owner"
                      :value="Number(index2)"
                    />
                  </el-select>
                </el-form-item>
              </td>
              <td>
                <el-form-item
                  :prop="`molds[${index}].warehouse_id`"
                  :rules="{ required: ![0, 1, 2].includes(mold.status), message: ' ', trigger: 'change' }"
                  class="p-0 m-0"
                  label-width="0"
                >
                  <el-select
                    v-model.number="mold.warehouse_id"
                    :disabled="[0, 1, 2].includes(mold.status)"
                    placeholder="- 請選擇 -"
                    size="small"
                  >
                    <el-option
                      v-for="warehouse in $store.getters['warehouse/mold']"
                      :key="warehouse.id"
                      :label="warehouse.name"
                      :value="Number(warehouse.id)"
                    />
                  </el-select>
                </el-form-item>
              </td>
              <td>
                <el-form-item class="p-0 m-0" label-width="0">
                  <el-input v-model="mold.comment" placeholder="備註" size="small" />
                </el-form-item>
              </td>
              <td v-if="row.status === 2 && $store.getters['permission/check']([25])">
                <b-btn variant="danger" size="sm" @click.prevent="onRemoveMold(mold.id)">
                  <fa icon="trash-alt" fixed-width />
                </b-btn>
              </td>
            </tr>
            <tr v-if="mold.status !== 2" :key="'unit-' + index">
              <td class="text-right"><strong>模具配件</strong></td>
              <td colspan="6" class="m-0 p-0">
                <table class="table table-bordered table-sm m-0">
                  <thead class="thead-light">
                    <tr>
                      <th>模具配件名稱</th>
                      <th class="text-center" style="width: 90px">狀態</th>
                      <th v-if="$store.getters['permission/check']([1, 2])">成本</th>
                      <th>備註</th>
                      <th v-if="row.status > 1 && $store.getters['permission/check']([25])" style="width: 48px">
                        <b-btn class="float-right" variant="success" size="sm" @click.prevent="onAddUnit(mold.id)">
                          <fa icon="plus-circle" fixed-width />
                        </b-btn>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(unit, index2) in mold.order_units" :key="index2">
                      <td>
                        <el-form-item
                          :prop="`molds[${index}].order_units[${index2}].name`"
                          :rules="{ required: true, message: ' ', trigger: 'blur' }"
                          class="p-0 m-0"
                          label-width="0"
                        >
                          <el-input
                            v-model="unit.name"
                            :disabled="row.status < 1"
                            placeholder="模具配件名稱"
                            size="small"
                          />
                        </el-form-item>
                      </td>
                      <td class="text-center pt-2">
                        <b-badge :variant="colors[unit.status]">{{ moldUnitStatus[unit.status] }}</b-badge>
                      </td>
                      <td v-if="$store.getters['permission/check']([1, 2])">
                        <el-form-item
                          :prop="`molds[${index}].order_units[${index2}].cost`"
                          :rules="{ required: true, type: 'number', min: 0, message: ' ', trigger: 'blur' }"
                          class="p-0 m-0"
                          label-width="0"
                        >
                          <el-input
                            v-model.number="unit.cost"
                            type="number"
                            :min="0"
                            :disabled="row.status < 1"
                            placeholder="模具配件成本"
                            size="small"
                          >
                            <template #prepend> NT$ </template>
                          </el-input>
                        </el-form-item>
                      </td>
                      <td>
                        <el-form-item class="p-0 m-0" label-width="0">
                          <el-input v-model="unit.comment" placeholder="備註" size="small" />
                        </el-form-item>
                      </td>
                      <td v-if="row.status > 1 && $store.getters['permission/check']([25])">
                        <b-btn
                          class="float-right"
                          variant="danger"
                          size="sm"
                          @click.prevent="onRemoveUnit(mold.id, unit.id)"
                        >
                          <fa icon="trash-alt" fixed-width />
                        </b-btn>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
      <div class="form-row mt-3">
        <div class="col text-right">
          <b-btn variant="secondary" @click.prevent="modalShow = false">
            <fa icon="times-circle" fixed-width />取消
          </b-btn>
          <b-btn
            v-if="row.status !== 0 && $store.getters['permission/check']([25])"
            type="submit"
            variant="primary"
            @click.prevent="onSubmit"
          >
            <fa icon="edit" fixed-width />{{ row.status === 1 ? '更新' : actionText }}
          </b-btn>
          <b-btn
            v-if="row.status === 2 && $store.getters['permission/check']([25])"
            variant="indigo"
            @click.prevent="doUpdateMold"
          >
            <fa icon="inbox" fixed-width />開模
          </b-btn>
          <b-btn
            v-if="row.status === 3 && $store.getters['permission/check']([25])"
            variant="success"
            @click.prevent="doSuccess"
          >
            <fa icon="check-circle" fixed-width />開模完成
          </b-btn>
        </div>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
import { cloneDeep, reject, debounce } from 'lodash-es'
import { mapActions } from 'vuex'
import { colors, moldStatus, moldUnitStatus, moldOrderStatus, ownerShip } from '@/plugins/consts'
import { modify } from '@/plugins/mixins'

export default {
  name: 'MoldOrderUpdate',
  mixins: [modify],
  data() {
    return {
      colors,
      moldStatus,
      moldUnitStatus,
      moldOrderStatus,
      ownerShip,
    }
  },
  methods: {
    ...mapActions('moldOrder', ['fetchOne', 'doUpdate']),
    onAfterUpdate() {
      this.$set(this.row, 'newMolds', [])
      this.$set(this.row, 'deleteMolds', [])
      this.$set(this.row, 'newUnits', [])
      this.$set(this.row, 'deleteUnits', [])
    },
    onAddMold(partId) {
      let mold = {
        id: Date.now(),
        comment: null,
        drawings: [],
        name: null,
        order_units: [],
        owner_id: 0,
        part_id: partId,
        status: 2,
        vendor_alias_id: null,
        vendor_id: this.row.vendor_id,
        warehouse_id: null,
      }
      this.row.molds.push(mold)
      this.row.newMolds.push(mold)
    },
    onRemoveMold(moldId) {
      this.$confirm('是否要移除該模具？', '確認', {
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          let mold = cloneDeep(this.row.molds.find((x) => x.id == +moldId))
          let isNew = this.row.newMolds.find((x) => x.id == +moldId) === undefined
          this.row.newMolds = reject(this.row.newMolds, { id: +moldId })
          this.row.deleteMolds = reject(this.row.deleteMolds, { id: +moldId })
          this.row.molds = reject(this.row.molds, { id: +moldId })
          if (mold.status === 2 && isNew) {
            this.row.deleteMolds.push(mold)
          } else {
            mold.order_units.forEach((v) => {
              this.row.deleteUnits.push(v)
            })
          }
        })
        .catch(() => {
          return false
        })
    },
    onAddUnit(moldId) {
      let mold = this.row.molds.find((x) => x.id == +moldId)
      let unit = {
        comment: null,
        cost: null,
        id: Date.now(),
        mold_id: moldId,
        name: null,
        status: this.row.status === 2 ? 2 : 3,
      }
      mold.order_units.push(unit)
      this.row.newUnits.push(unit)
    },
    onRemoveUnit(moldId, unitId) {
      this.$confirm('是否要移除該模具配件？', '確認', {
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          let mold = this.row.molds.find((x) => x.id === +moldId)
          let unit = cloneDeep(mold.order_units.find((x) => x.id === +unitId))
          let isNew = this.row.newUnits.find((x) => x.id === +unitId) === undefined
          mold.order_units = reject(mold.order_units, { id: +unitId })
          this.row.newUnits = reject(this.row.newUnits, { id: +unitId })
          if (unit.status === 2 && isNew) {
            this.row.deleteUnits.push(unit)
          }

          if (mold.order_units.length < 1) {
            this.row.molds = reject(this.row.molds, { id: +moldId })
          }
        })
        .catch(() => {
          return false
        })
    },
    doUpdateMold: debounce(function () {
      this.$refs[this.formRefs].validate((valid) => {
        if (!valid) {
          this.$message.error('請重新確認輸入欄位')
          return false
        }

        // 更新狀態爲開模中
        this.row.status = 3
        this.row.molds.forEach((v) => {
          if (v.status !== 1) {
            v.status = 3
          }
          v.order_units.forEach((u) => {
            if (u.status !== 1) {
              u.status = 3
            }
          })
        })

        this.doUpdate(this.row)
          .then(() => {
            this.$message.success('處理完成')
            this.isDirty = true
            // this.modalShow = false
          })
          .catch((err) => {
            console.log(err)
          })
      })
    }, 500),
    doSuccess: debounce(function () {
      let check = 0
      this.row.molds.forEach((v) => {
        if (v.order_units.length < 1) {
          this.onAddUnit(v.id)
          check++
        }
      })

      if (check > 0) {
        this.$message.error('請新增模具配件')
        return false
      }

      this.$refs[this.formRefs].validate((valid) => {
        if (!valid) {
          this.$message.error('請重新確認輸入欄位')
          return false
        }

        this.row.status = 1
        this.row.molds.forEach((v) => {
          if (v.status !== 1) {
            v.status = 1
          }
          v.order_units.forEach((u) => {
            if (u.status !== 1) {
              u.status = 1
            }
          })
        })
        this.doUpdate(this.row)
          .then(() => {
            this.$message.success('處理完成')
            this.isDirty = true
            this.modalShow = false
          })
          .catch((err) => {
            console.log(err)
          })
      })
    }, 500),
  },
}
</script>
