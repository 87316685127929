<template>
  <div v-if="!isText" :class="status ? 'text-success' : 'text-danger'">
    <fa :icon="status ? ['fas', 'check-circle'] : ['fas', 'times-circle']" fixed-width />
  </div>
  <span v-else class="badge" :class="status ? 'badge-success' : 'badge-danger'">{{ status ? '是' : '否' }}</span>
</template>

<script>
export default {
  name: 'YesNo',
  props: {
    status: {
      type: Boolean,
      default: true,
    },
    isText: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
