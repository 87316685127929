<template>
  <el-dialog :visible.sync="modalShow" :close-on-click-modal="false" :width="modalWidth" top="5px" append-to-body>
    <template #title>
      <h3 class="m-0">
        {{ actionText + '權限角色' }}
      </h3>
    </template>
    <el-form
      v-if="modalShow"
      :ref="formRefs"
      :model="row"
      :rules="rules"
      :disabled="!$store.getters['permission/check']([71])"
      label-width="100px"
    >
      <div class="card">
        <div class="card-body p-3">
          <div class="form-row">
            <el-form-item class="col-sm-12" prop="name" label="角色名稱">
              <el-input v-model="row.name" placeholder="角色名稱" />
            </el-form-item>
          </div>
        </div>
      </div>
      <div class="form-row mt-3">
        <div class="col text-right">
          <b-btn variant="secondary" @click.prevent="modalShow = false">
            <fa icon="times-circle" fixed-width />取消
          </b-btn>
          <b-btn
            v-if="$store.getters['permission/check']([71])"
            type="submit"
            variant="primary"
            @click.prevent="onSubmit"
          >
            <fa icon="edit" fixed-width />{{ actionText }}
          </b-btn>
        </div>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import { modify, unique } from '@/plugins/mixins'

export default {
  name: `RoleSetting`,
  mixins: [modify, unique],
  data() {
    return {
      structure: {
        name: null,
        gurad_name: null,
      },
      rules: {
        name: [
          { required: true, message: '請輸入角色名稱', trigger: 'change' },
          { validator: this.doCheckUnique, message: '角色名稱重複，請重新確認', trigger: 'blur' },
        ],
      },
      modalMaxWidth: 500,
    }
  },
  methods: {
    ...mapActions(`role`, ['fetchOne', 'doCreate', 'doUpdate', 'checkUnique']),
  },
}
</script>
