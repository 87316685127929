<template>
  <table class="table table-bordered table-striped table-sm display">
    <tbody>
      <tr class="vm">
        <td style="width: 13%">批次</td>
        <td style="width: 20%">
          <div class="d-flex justify-content-between">
            <div class="flex-fill align-self-center">
              <b-badge variant="pink">{{ `${proc.serial}-${proc.batch}` }}</b-badge>
            </div>
            <div v-if="[1, 3, 4].includes(proc.status)" class="flex-fill text-right">
              <b-btn
                style="padding: 0.15rem"
                variant="primary"
                size="sm"
                @click.prevent="$emit('process-manage', proc.id)"
              >
                <fa icon="tasks" fixed-width />管理
              </b-btn>
            </div>
          </div>
        </td>
        <td style="width: 13%">製程名稱</td>
        <td style="width: 20%">{{ proc.default_process && proc.default_process.name }}</td>
        <td style="width: 13%">加工對象</td>
        <td style="width: 20%">{{ proc.customer && proc.customer.name }}</td>
      </tr>
      <tr class="vm">
        <td>狀態</td>
        <td>
          <b-badge :variant="colors[proc.status]">{{ processStatus[proc.status] }}</b-badge>
        </td>
        <td>加工數量</td>
        <td>{{ proc.arrange_quantity }}</td>
        <td>交付日期</td>
        <td>{{ proc.delivery_date }}</td>
      </tr>
      <tr>
        <td>{{ proc.default_process_id === 1 ? '領料' : '點收' }}(進)</td>
        <td colspan="5" class="p-0">
          <table v-if="proc.incomings && proc.incomings.length > 0" class="table table-bordered table-sm m-0">
            <thead>
              <tr>
                <th style="width: 150px">編號</th>
                <th class="text-center" style="width: 80px">種類</th>
                <th style="width: 180px">點收時間</th>
                <th>承辦人</th>
                <th>倉儲</th>
                <th class="text-right">數量</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="incoming in proc.incomings" :key="incoming.id" class="bg-white">
                <td class="text-monospace">{{ incoming.sno }}</td>
                <td class="text-center">
                  <b-badge :variant="colors[incoming.type + 1]">{{ processIncomingType[incoming.type] }}</b-badge>
                </td>
                <td>{{ incoming.created_at }}</td>
                <td>{{ incoming.user && incoming.user.name }}</td>
                <td>{{ $store.getters['warehouse/name'](incoming.warehouse_id) }}</td>
                <td class="text-right">{{ incoming.quantity }}</td>
              </tr>
              <tr class="bg-white text-right">
                <td colspan="5"><strong class="text-orange">總計數量</strong></td>
                <td>
                  <strong class="text-orange">{{ proc.incomings | sumBy('quantity') }}</strong>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr>
        <td>交接(出)</td>
        <td colspan="5" class="p-0">
          <table v-if="outgoings.length > 0" class="table table-bordered table-sm m-0">
            <thead>
              <tr>
                <th style="width: 150px">編號</th>
                <th class="text-center" style="width: 80px">種類</th>
                <th style="width: 180px">交接時間</th>
                <th>承辦人</th>
                <th>倉儲</th>
                <th class="text-right">數量</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="outgoing in outgoings" :key="outgoing.id" class="bg-white">
                <td class="text-monospace">{{ outgoing.sno }}</td>
                <td class="text-center">
                  <b-badge :variant="colors[outgoing.type + 1]">{{ processOutgoingType[outgoing.type] }}</b-badge>
                </td>
                <td>{{ outgoing.created_at }}</td>
                <td>{{ outgoing.user && outgoing.user.name }}</td>
                <td>{{ $store.getters['warehouse/name'](outgoing.warehouse_id) }}</td>
                <td class="text-right">{{ outgoing.quantity }}</td>
              </tr>
              <tr class="bg-white text-right">
                <td colspan="5"><strong class="text-success">總計數量</strong></td>
                <td>
                  <strong class="text-success">{{ outgoings | sumBy('quantity') }}</strong>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr v-if="scrapped.length > 0">
        <td>報廢與重工</td>
        <td colspan="5" class="p-0">
          <table class="table table-bordered table-sm m-0">
            <thead>
              <tr>
                <th style="width: 150px">編號</th>
                <th class="text-center" style="width: 80px">種類</th>
                <th style="width: 180px">交接時間</th>
                <th>承辦人</th>
                <th>倉儲</th>
                <th class="text-right">數量</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="scrap in scrapped" :key="scrap.id" class="bg-white">
                <td class="text-monospace">{{ scrap.sno }}</td>
                <td class="text-center">
                  <b-badge :variant="colors[scrap.type + 1]">{{ processOutgoingType[scrap.type] }}</b-badge>
                </td>
                <td>{{ scrap.created_at }}</td>
                <td>{{ scrap.user && scrap.user.name }}</td>
                <td>{{ $store.getters['warehouse/name'](scrap.warehouse_id) }}</td>
                <td class="text-right">{{ scrap.quantity }}</td>
              </tr>
              <tr class="bg-white text-right">
                <td colspan="5"><strong class="text-danger">總計數量</strong></td>
                <td>
                  <strong class="text-danger">{{ scrapped | sumBy('quantity') }}</strong>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr>
        <td>備註</td>
        <td colspan="5">{{ proc.comment }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { colors, processStatus, processIncomingType, processOutgoingType } from '@/plugins/consts'

export default {
  name: 'ProcessDetail',
  props: {
    proc: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      colors,
      processStatus,
      processIncomingType,
      processOutgoingType,
    }
  },
  computed: {
    outgoings() {
      return this.proc.outgoings.filter((item) => item.type == 0) || []
    },
    scrapped() {
      return this.proc.outgoings.filter((item) => item.type > 0) || []
    },
  },
}
</script>
