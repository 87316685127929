<template>
  <table class="table table-bordered table-striped table-sm">
    <thead>
      <tr>
        <th style="width: 170px">退貨編號</th>
        <th style="width: 170px">出貨編號</th>
        <th>退貨日期</th>
        <th>承辦人</th>
        <th>入庫倉儲</th>
        <th class="text-center">後續處理</th>
        <th class="text-center">處置方式</th>
        <th class="text-right">退貨數量</th>
        <th>退貨原因</th>
        <th style="width: 50px">操作</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="shipment_return in items" :key="shipment_return.id">
        <td class="text-monospace">
          <a href="#" @click.prevent="$emit('shipment-return-update', shipment_return.id)">
            {{ shipment_return.sno }} <fa icon="edit" />
          </a>
        </td>
        <td class="text-monospace">{{ shipment_return.shipment.sno }}</td>
        <td>{{ shipment_return.date }}</td>
        <td>{{ shipment_return.user && shipment_return.user.name }}</td>
        <td>{{ shipment_return.warehouse && shipment_return.warehouse.name }}</td>
        <td class="text-center">
          <b-badge :variant="colors[shipment_return.subsequent]">
            {{ returnSubsequent[shipment_return.subsequent] }}
          </b-badge>
        </td>
        <td class="text-center">
          <b-badge :variant="colors[shipment_return.disposal + 2]">
            {{ returnDisposal[shipment_return.disposal] }}
          </b-badge>
        </td>
        <td class="text-right">{{ shipment_return.quantity }}</td>
        <td>{{ shipment_return.reason }}</td>
        <td>
          <b-btn variant="danger" size="sm" @click.prevent="$emit('shipment-return-delete', shipment_return.id)">
            <fa icon="trash" fixed-width />
          </b-btn>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { colors, returnSubsequent, returnDisposal } from '@/plugins/consts'
export default {
  name: 'ShipmentReturnList',
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      colors,
      returnSubsequent,
      returnDisposal,
    }
  },
}
</script>
