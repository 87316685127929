import Vue from 'vue'
import App from '@/App.vue'
import { router, store } from '@/plugins/router'
import VueImg from 'v-img'

import '@/registerServiceWorker'
import '@/plugins/bootstrap-vue'
import '@/plugins/element-ui'
import '@/plugins/fontawesome'
import '@/plugins/filters'
import '@/plugins/sentry'
// import ErrorPlugin from '@/plugins/error'
import '@/components'
import '@/scss/app.scss'

Vue.config.productionTip = false

if (process.env.NODE_ENV !== 'production') {
  Vue.config.errorHandler = (error, vm, info) => {
    console.error(error, info, vm)
  }

  Vue.config.warnHandler = (error, vm, info) => {
    console.error(error, info, vm)
  }
}
Vue.use(VueImg)

new Vue({
  router,
  store,
  // i18n,
  render: (h) => h(App),
}).$mount('#app')
