<template>
  <table class="table table-bordered table-striped table-sm">
    <thead>
      <tr>
        <th style="width: 170px">報廢編號</th>
        <th style="width: 150px">對象編號</th>
        <th class="text-center" style="width: 80px">狀態</th>
        <th>報廢日期</th>
        <th>報廢數量</th>
        <th>承辦人</th>
        <th>入庫倉儲</th>
        <th>備註</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="scrap in items" :key="scrap.id">
        <td class="text-monospace">
          <a href="#" @click.prevent="$emit('scrap-update', scrap.id)">{{ scrap.sno }} <fa icon="edit" /></a>
        </td>
        <td class="text-monospace">{{ scrap.targetable && scrap.targetable.sno }}</td>
        <td class="text-center">
          <b-badge :variant="colors[scrap.status]">{{ scrapStatus[scrap.status] }}</b-badge>
        </td>
        <td>{{ scrap.date }}</td>
        <td>{{ scrap.quantity }}</td>
        <td>{{ scrap.user && scrap.user.name }}</td>
        <td>{{ scrap.warehouse && scrap.warehouse.name }}</td>
        <td>{{ scrap.comment }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { colors, scrapStatus } from '@/plugins/consts'
export default {
  name: 'ScrapList',
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      colors,
      scrapStatus,
    }
  },
}
</script>
