<template>
  <el-dialog :visible.sync="modalShow" :close-on-click-modal="false" :width="modalWidth" top="5px" append-to-body>
    <template #title>
      <h3 class="m-0">採購申請</h3>
    </template>
    <el-form
      v-if="modalShow"
      :ref="formRefs"
      :model="row"
      :rules="rules"
      :disabled="[0, 1].includes(row.status)"
      label-width="120px"
      size="small"
    >
      <table
        v-for="(item, index) in row.items"
        :key="index"
        class="table table-bordered table-striped table-sm display"
      >
        <tbody>
          <tr class="vm">
            <td style="width: 13%">種類</td>
            <td style="width: 20%">
              <b-badge :variant="colors[item.type]">{{ purchaseItemTypes[item.type] }}</b-badge>
            </td>
            <td style="width: 13%">材料名稱</td>
            <td colspan="3">
              <div class="d-flex align-items-center">
                <span>{{ item.material | materialName }}</span>
                <b-btn class="ml-auto" variant="pink" size="sm" @click.prevent="onRemoveItem(index)">
                  <fa icon="trash-alt" fixed-width />略過
                </b-btn>
              </div>
            </td>
          </tr>
          <tr class="vm">
            <td>供料廠商</td>
            <td>
              <el-form-item
                :prop="`items.${index}.customer_id`"
                :rules="{ required: true, message: ' ', trigger: 'change' }"
                class="m-0 p-0"
                label-width="0"
              >
                <el-select v-model.number="item.customer_id" placeholder="- 請選擇供料廠商 -">
                  <el-option
                    v-for="vendor in $store.getters['customer/vendors']"
                    :key="vendor.id"
                    :label="vendor.name"
                    :value="Number(vendor.id)"
                  />
                </el-select>
              </el-form-item>
            </td>
            <td style="width: 13%">採購數量</td>
            <td style="width: 20%">
              <el-form-item
                :prop="`items.${index}.quantity`"
                :rules="{ required: true, type: 'integer', min: 1, message: ' ', trigger: 'change' }"
                class="m-0 p-0"
                label-width="0"
              >
                <el-input v-model.number="item.quantity" type="number" :min="1" placeholder="採購數量">
                  <template #append> 個 </template>
                </el-input>
              </el-form-item>
            </td>
            <td style="width: 13%">交貨日期</td>
            <td>
              <el-form-item
                :prop="`items.${index}.delivery_date`"
                :rules="{ required: true, message: ' ', trigger: 'change' }"
                class="m-0 p-0"
                label-width="0"
              >
                <el-date-picker
                  v-model="item.delivery_date"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="交貨日期"
                />
              </el-form-item>
            </td>
          </tr>
          <tr class="vm">
            <td>送貨地點</td>
            <td>
              <el-form-item
                :prop="`items.${index}.target_id`"
                :rules="{ required: true, message: ' ', trigger: 'change' }"
                class="m-0 p-0"
                label-width="0"
              >
                <el-select
                  v-model.number="item.target_id"
                  placeholder="- 請選擇送貨地點 -"
                  filterable
                  @change="targetChange(item)"
                >
                  <el-option
                    v-for="outsource in $store.getters['customer/outsources']"
                    :key="outsource.id"
                    :label="outsource.name"
                    :value="Number(outsource.id)"
                  />
                </el-select>
              </el-form-item>
            </td>
            <td>入庫到</td>
            <td>
              <el-form-item
                :prop="`items.${index}.warehouse_id`"
                :rules="{ required: item.target_id === 0, message: ' ', trigger: 'change' }"
                class="m-0 p-0"
                label-width="0"
              >
                <el-select
                  v-model.number="item.warehouse_id"
                  :disabled="item.target_id !== 0"
                  placeholder="- 入庫到 -"
                  filterable
                >
                  <el-option
                    v-for="warehouse in $store.getters['warehouse/material']"
                    :key="warehouse.id"
                    :label="warehouse.name"
                    :value="Number(warehouse.id)"
                  />
                </el-select>
              </el-form-item>
            </td>
            <td>備註</td>
            <td>
              <el-form-item class="m-0 p-0" label-width="0">
                <el-input v-model.trim="item.comment" placeholder="備註" />
              </el-form-item>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="form-row mt-3">
        <div class="col text-right">
          <b-btn variant="secondary" @click.prevent="modalShow = false">
            <fa icon="times-circle" fixed-width />取消
          </b-btn>
          <b-btn type="submit" variant="primary" @click.prevent="onSubmit">
            <fa icon="edit" fixed-width />{{ actionText }}
          </b-btn>
        </div>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import { purchaseStatus, purchaseItemTypes, purchaseItemStatus, colors } from '@/plugins/consts'
import { modify } from '@/plugins/mixins'

export default {
  name: 'PurchaseApply',
  mixins: [modify],
  data() {
    return {
      rules: {},
      purchaseStatus,
      purchaseItemTypes,
      purchaseItemStatus,
      colors,
    }
  },
  methods: {
    ...mapActions('purchase', { fetchAll: 'fetchAll', doCreate: 'doApply' }),
    onCreate() {
      this.formAction = 'create'
      this.resetRow()
      this.fetchAll().then((result) => {
        result.map((r) => {
          r.customer_id = r.material.vendor_id
        })
        this.$set(this.row, 'items', result)
        this.modalShow = true
      })
    },
    targetChange(item) {
      if (item.target_id !== 0) {
        item.warehouse_id = null
      }
    },
    onRemoveItem(index) {
      this.row.items.splice(index, 1)
    },
  },
}
</script>
