<template>
  <div>
    <el-dialog :visible.sync="modalShow" :close-on-click-modal="false" :width="modalWidth" top="5px" append-to-body>
      <template #title>
        <h3 class="m-0">新增大量退貨</h3>
      </template>
      <el-form
        v-if="modalShow"
        :ref="formRefs"
        :model="row"
        :disabled="!$store.getters['permission/check']([51])"
        label-width="100px"
      >
        <div class="card">
          <div class="card-body p-3">
            <div class="form-row">
              <el-form-item class="col-md-6 col-lg-4" label="客戶名稱" prop="customer_id">
                <el-select
                  v-model.number="row.customer_id"
                  placeholder="請先選擇客戶"
                  filterable
                  @change="onCustomerChange"
                >
                  <el-option
                    v-for="item in $store.getters['customer/consumers']"
                    :key="item.id"
                    :label="item.name"
                    :value="Number(item.id)"
                  />
                </el-select>
              </el-form-item>
            </div>
            <div v-show="row.customer_id" class="form-row table-responsive">
              <table class="table table-bordered table-striped table-sm">
                <thead>
                  <tr class="text-center">
                    <th style="max-width: 160px">產品名稱</th>
                    <th style="width: 130px">出貨編號</th>
                    <th style="width: 120px">出貨日期</th>
                    <th style="width: 80px">出貨數量</th>
                    <th style="width: 95px">處置方式</th>
                    <th style="width: 95px">後續處理</th>
                    <th style="width: 150px">退貨日期</th>
                    <th style="width: 100px">退貨數量</th>
                    <th style="width: 100px">入庫倉儲</th>
                    <th>退貨原因</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(ship, index1) in row.returns" :key="index1" class="vm">
                    <td class="text-monospace text-truncate" style="max-width: 150px">
                      {{ ship.product && `(${ship.product.alias_id})${ship.product.name}` }}
                    </td>
                    <td class="text-monospace">{{ ship.sno }}</td>
                    <td class="text-monospace">{{ ship.delivery_date }}</td>
                    <td class="text-monospace text-right">{{ ship.delivery_quantity }}</td>
                    <td>
                      <el-form-item
                        :prop="'returns[' + index1 + '].disposal'"
                        :rules="{ required: true, message: ' ', trigger: 'change' }"
                        label-width="0px"
                        class="m-0 p-0"
                      >
                        <el-select v-model="ship.disposal" size="small" placeholder="- 請選擇 -">
                          <el-option
                            v-for="(disposal, d) in returnDisposal"
                            :key="d"
                            :label="disposal"
                            :value="Number(d)"
                          />
                        </el-select>
                      </el-form-item>
                    </td>
                    <td>
                      <el-form-item
                        :prop="'returns[' + index1 + '].subsequent'"
                        :rules="{ required: true, message: ' ', trigger: 'change' }"
                        label-width="0px"
                        class="m-0 p-0"
                      >
                        <el-select v-model="ship.subsequent" size="small" @change="ship.warehouse_id = null">
                          <el-option
                            v-for="(subsequent, index3) in returnSubsequent"
                            :key="index3"
                            :label="subsequent"
                            :value="Number(index3)"
                          />
                        </el-select>
                      </el-form-item>
                    </td>
                    <td>
                      <el-form-item
                        :prop="'returns[' + index1 + '].date'"
                        :rules="{ required: true, message: ' ', trigger: 'change' }"
                        label-width="0px"
                        class="m-0 p-0"
                      >
                        <el-date-picker
                          v-model="ship.date"
                          value-format="yyyy-MM-dd"
                          type="date"
                          placeholder="退貨日期"
                          size="small"
                        />
                      </el-form-item>
                    </td>
                    <td>
                      <el-form-item
                        :prop="'returns[' + index1 + '].quantity'"
                        :rules="{ required: true, type: 'number', min: 1, message: ' ', trigger: 'change' }"
                        label-width="0px"
                        class="m-0 p-0"
                      >
                        <el-input
                          v-model.number="ship.quantity"
                          :min="1"
                          type="number"
                          placeholder="退貨數量"
                          size="small"
                        />
                      </el-form-item>
                    </td>
                    <td>
                      <el-form-item
                        :prop="'returns[' + index1 + '].warehouse_id'"
                        :rules="{ required: true, message: ' ', trigger: 'change' }"
                        label-width="0px"
                        class="m-0 p-0"
                      >
                        <el-select v-model.number="ship.warehouse_id" placeholder="入庫倉儲" size="small">
                          <el-option
                            v-for="warehouse in returnWarehouses(ship.subsequent)"
                            :key="warehouse.id"
                            :label="warehouse.name"
                            :value="+warehouse.id"
                          />
                        </el-select>
                      </el-form-item>
                    </td>
                    <td>
                      <el-form-item
                        :prop="'returns[' + index1 + '].reason'"
                        :rules="{ required: true, message: ' ', trigger: 'change' }"
                        label-width="0px"
                        class="m-0 p-0"
                      >
                        <el-input v-model="ship.reason" placeholder="退貨原因" size="small" />
                      </el-form-item>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="form-row mt-3">
          <div class="col text-right">
            <b-btn variant="secondary" @click.prevent="modalShow = false">
              <fa icon="times-circle" fixed-width />取消
            </b-btn>
            <b-btn v-show="row.customer_id" type="submit" variant="primary" @click.prevent="onSubmit">
              <fa icon="edit" fixed-width />{{ actionText }}
            </b-btn>
          </div>
        </div>
      </el-form>
    </el-dialog>
    <shipment-choose ref="shipmentChoose" multi @shipment-multi-choose="onChoose" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { modify } from '@/plugins/mixins'
import { returnSubsequent, returnDisposal } from '@/plugins/consts'

export default {
  name: 'ShipmentReturnBatch',
  mixins: [modify],
  data() {
    return {
      structure: {
        customer_id: null,
        returns: [
          {
            disposal: 2,
            subsequent: 1,
            date: new Date(),
            warehouse_id: null,
            quantity: null,
            reason: 'NG',
          },
        ],
      },
      returnSubsequent,
      returnDisposal,
    }
  },
  methods: {
    ...mapActions('shipmentReturn', { doCreate: 'doBatch' }),
    returnWarehouses(subsequent) {
      return subsequent == 1 ? this.$store.getters['warehouse/rework'] : this.$store.getters['warehouse/scrapped']
    },
    onCustomerChange(val) {
      if (val === '') {
        this.$message.error('請先選擇客戶')
        return false
      }

      this.$refs.shipmentChoose.init(val)
    },
    onChoose(choosen) {
      let returns = []
      choosen.forEach((s) => {
        returns.push({
          order_id: s.order_id,
          shipment_id: s.id,
          product: {
            alias_id: s.product.alias_id,
            name: s.product.name,
          },
          sno: s.sno,
          delivery_date: s.date,
          delivery_quantity: s.quantity,
          disposal: 2,
          subsequent: 1,
          date: new Date(),
          warehouse_id: null,
          quantity: null,
          unit_price: s.unit_price,
          reason: 'NG',
        })
      })

      this.$set(this.row, 'returns', returns)
    },
  },
}
</script>
