<template>
  <div class="w-100">
    <table class="table table-bordered table-striped table-sm display">
      <tbody>
        <tr class="vm">
          <td style="width: 13%">模具訂單編號</td>
          <td style="width: 20%">
            <!-- <a href="#" @click.prevent="onReceive(target.id)">{{ target.sno }} <fa icon="edit" /></a> -->
          </td>
          <td style="width: 13%">進貨日期</td>
          <td style="width: 20%">{{ target.date }}</td>
          <td style="width: 13%">進貨地點</td>
          <td>{{ target.target && target.target.name }}</td>
        </tr>
        <tr>
          <td>備註</td>
          <td colspan="5">{{ target.comment }}</td>
        </tr>
      </tbody>
    </table>
    <!-- <receive-setting ref="receiveSetting" @done="$emit('done')" /> -->
  </div>
</template>

<script>
export default {
  name: 'AccountsPayableMoldOrder',
  props: {
    target: {
      type: Object,
      default() {
        return {
          sno: null,
        }
      },
    },
  },
  computed: {
    customer() {
      return this.$store.state.customer.data.find((x) => x.id == this.target.customer_id)?.currency || {}
    },
  },
  methods: {
    // onReceive(id) {
    //   this.$refs.receiveSetting.init(id)
    // },
  },
}
</script>
