<template>
  <el-dialog :visible.sync="modalShow" :close-on-click-modal="false" :width="modalWidth" top="5px" append-to-body>
    <template #title>
      <h3 class="m-0">
        {{ actionText + '重工批次' }}
      </h3>
    </template>
    <el-form v-if="modalShow" :ref="formRefs" :model="row" :rules="rules" label-width="120px">
      <div class="card">
        <div v-if="row.sno" class="card-header">
          <h5 class="m-0">
            重工批次編號：<span class="text-monospace">{{ row.sno }}</span>
          </h5>
        </div>
        <div class="card-body p-3">
          <div class="form-row">
            <el-form-item class="col-12" label="產品名稱">
              <h6>{{ product && `(${product.alias_id}) ${product.name}` }}</h6>
            </el-form-item>
            <el-form-item class="col-md-6" label="重工完成日期" prop="date">
              <el-date-picker v-model="row.date" type="date" value-format="yyyy-MM-dd" placeholder="重工完成日期" />
            </el-form-item>
            <div class="w-100"></div>
            <el-form-item class="col-md-6" label="重工完成數量" prop="finish_quantity">
              <el-input v-model.number="row.finish_quantity" :min="0" type="number" placeholder="重工完成數量">
                <template #append> 個 </template>
              </el-input>
            </el-form-item>
            <el-form-item class="col-md-6" label="入庫倉儲" prop="finish_warehouse_id">
              <el-select v-model.number="row.finish_warehouse_id" placeholder="- 請選擇倉儲 -" filterable>
                <el-option
                  v-for="item in $store.getters['warehouse/product']"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
            <el-form-item class="col-md-6" label="報廢數量" prop="scrap_quantity">
              <el-input v-model.number="row.scrap_quantity" :min="0" type="number" placeholder="報廢數量">
                <template #append> 個 </template>
              </el-input>
            </el-form-item>
            <el-form-item
              v-if="row.scrap_quantity > 0"
              class="col-md-6"
              label="報廢倉儲"
              prop="scrap_warehouse_id"
              :rules="[{ required: row.scrap_quantity > 0, message: '請選擇報廢倉儲', trigger: 'change' }]"
            >
              <el-select v-model.number="row.scrap_warehouse_id" placeholder="- 請選擇倉儲 -" filterable>
                <el-option
                  v-for="item in $store.getters['warehouse/scrapped']"
                  :key="item.id"
                  :label="item.name"
                  :value="Number(item.id)"
                />
              </el-select>
            </el-form-item>
            <div class="w-100"></div>
            <el-form-item class="col" label="備註" prop="comment">
              <el-input v-model.trim="row.comment" placeholder="備註" />
            </el-form-item>
          </div>
        </div>
      </div>
      <div class="form-row mt-3">
        <div class="col text-right">
          <b-btn variant="secondary" @click.prevent="modalShow = false">
            <fa icon="times-circle" fixed-width />取消
          </b-btn>
          <b-btn type="submit" variant="primary" @click.prevent="onSubmit">
            <fa icon="edit" fixed-width />{{ actionText }}
          </b-btn>
        </div>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import { modify } from '@/plugins/mixins'

export default {
  name: `ReworkItemSetting`,
  mixins: [modify],
  props: {
    reworkId: {
      type: [Number, String],
      default: null,
    },
    product: {
      type: Object,
      default() {
        return {
          alias_id: null,
          name: null,
          warehouse_id: null,
        }
      },
    },
  },
  data() {
    return {
      structure: {
        id: null,
        sno: null,
        rework_id: this.reworkId,
        date: null,
        finish_warehouse_id: this.product.warehouse_id,
        finish_quantity: null,
        scrap_warehouse_id: null,
        scrap_quantity: null,
        comment: null,
      },
      rules: {
        date: [{ required: true, message: '請輸入重工完成日期', trigger: 'change' }],
        finish_quantity: [
          { required: true, message: '請輸入重工完成數量', trigger: 'blur' },
          { type: 'number', min: 0, message: '請確認重工完成數量', trigger: 'change' },
        ],
        scrap_quantity: [
          { required: true, message: '請輸入報廢數量', trigger: 'blur' },
          { type: 'number', min: 0, message: '請確認報廢數量', trigger: 'change' },
        ],
        finish_warehouse_id: [{ required: true, message: '請選擇入庫倉儲', trigger: 'change' }],
      },
      modalMaxWidth: 800,
    }
  },
  watch: {
    reworkId: {
      handler(n) {
        this.$set(this.structure, 'rework_id', n)
      },
      immediate: true,
    },
    'product.warehouse_id': {
      handler(n) {
        this.$set(this.structure, 'finish_warehouse_id', n)
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions(`reworkItem`, ['fetchOne', 'doCreate', 'doUpdate']),
  },
}
</script>
