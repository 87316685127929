<template>
  <div>
    <el-dialog :visible.sync="modalShow" :close-on-click-modal="false" :width="modalWidth" top="5px" append-to-body>
      <template #title>
        <h3 class="m-0">生產製程詳細</h3>
      </template>
      <table class="table table-bordered table-striped table-sm display">
        <tbody>
          <tr>
            <td style="width: 13%">工單編號</td>
            <td style="width: 20%" class="text-monospace">{{ row.ticket && row.ticket.sno }}</td>
            <td style="width: 13%">材料單數</td>
            <td style="width: 20%">{{ row.ticket && row.ticket.material_to_quantity }}</td>
            <td style="width: 13%">組合用量</td>
            <td style="width: 20%">{{ row.ticket && row.ticket.usage }}</td>
          </tr>
          <tr class="vm">
            <td>批次</td>
            <td>
              <b-badge variant="pink">{{ `${row.serial}-${row.batch}` }}</b-badge>
            </td>
            <td>製程名稱</td>
            <td>{{ row.default_process && row.default_process.name }}</td>
            <td>加工對象</td>
            <td>{{ row.customer && row.customer.name }}</td>
          </tr>
          <tr>
            <td>狀態</td>
            <td>
              <b-badge :variant="colors[row.status]">{{ processStatus[row.status] }}</b-badge>
            </td>
            <td>加工數量</td>
            <td>{{ row.arrange_quantity }}</td>
            <td>交付日期</td>
            <td>{{ row.delivery_date }}</td>
          </tr>
          <tr v-if="row.ticket && row.ticket.material">
            <td>材料名稱</td>
            <td colspan="3">{{ row.ticket.material | materialName }}</td>
            <td>材料庫存</td>
            <td>
              <a href="#" @click.prevent="$emit('material-history', row.ticket.material_id)">
                <quantity :quantity="row.ticket.material.quantity" />
              </a>
            </td>
          </tr>
          <tr>
            <td>備註</td>
            <td colspan="5">{{ row.comment }}</td>
          </tr>
        </tbody>
      </table>
      <h4>
        <span>{{ processIncomingText }}</span>
        <b-btn class="float-right" variant="success" size="sm" @click.prevent="$refs.processIncomingSetting.init()">
          <fa icon="plus-circle" fixed-width />新增
        </b-btn>
      </h4>
      <table v-if="row.incomings && row.incomings.length > 0" class="table table-bordered table-striped table-sm">
        <thead>
          <tr>
            <th style="width: 150px">編號</th>
            <th class="text-center" style="width: 80px">種類</th>
            <th style="width: 180px">{{ processIncomingText }}時間</th>
            <th>承辦人</th>
            <th>倉儲</th>
            <th class="text-right">數量</th>
            <th width="125px">操作</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="incoming in row.incomings" :key="incoming.id">
            <td class="text-monospace">{{ incoming.sno }}</td>
            <td class="text-center">
              <b-badge :variant="colors[incoming.type + 1]">{{ processIncomingType[incoming.type] }}</b-badge>
            </td>
            <td>{{ incoming.created_at }}</td>
            <td>{{ incoming.user && incoming.user.name }}</td>
            <td>{{ incoming.warehouse && incoming.warehouse.name }}</td>
            <td class="text-right">{{ incoming.quantity }}</td>
            <td class="text-right">
              <b-btn
                style="padding: 0.15rem"
                variant="primary"
                size="sm"
                @click.prevent="$refs.processIncomingSetting.init(incoming.id)"
              >
                <fa icon="cog" fixed-width />設定
              </b-btn>
              <b-btn
                style="padding: 0.15rem"
                variant="danger"
                size="sm"
                @click.prevent="onProcessInDelete(incoming.id)"
              >
                <fa icon="trash" fixed-width />刪除
              </b-btn>
            </td>
          </tr>
          <tr class="text-right">
            <td colspan="5"><strong class="text-orange">總計數量</strong></td>
            <td>
              <strong class="text-orange">{{ row.incomings | sumBy('quantity') }}</strong>
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <template v-if="row.incomings && row.incomings.length > 0">
        <h4>
          <span>交接</span>
          <b-btn class="float-right" variant="success" size="sm" @click.prevent="$refs.processOutgoingSetting.init()">
            <fa icon="plus-circle" fixed-width />新增
          </b-btn>
        </h4>
        <table v-if="row.outgoings && row.outgoings.length > 0" class="table table-bordered table-striped table-sm">
          <thead>
            <tr>
              <th style="width: 150px">編號</th>
              <th class="text-center" style="width: 80px">種類</th>
              <th style="width: 180px">交接時間</th>
              <th>承辦人</th>
              <th>倉儲</th>
              <th class="text-right">數量</th>
              <th width="125px">操作</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="outgoing in outgoings" :key="outgoing.id">
              <td class="text-monospace">{{ outgoing.sno }}</td>
              <td class="text-center">
                <b-badge :variant="colors[outgoing.type + 1]">{{ processOutgoingType[outgoing.type] }}</b-badge>
              </td>
              <td>{{ outgoing.created_at }}</td>
              <td>{{ outgoing.user && outgoing.user.name }}</td>
              <td>{{ outgoing.warehouse && outgoing.warehouse.name }}</td>
              <td class="text-right">{{ outgoing.quantity }}</td>
              <td class="text-right">
                <b-btn
                  style="padding: 0.15rem"
                  variant="primary"
                  size="sm"
                  @click.prevent="$refs.processOutgoingSetting.init(outgoing.id)"
                >
                  <fa icon="cog" fixed-width />設定
                </b-btn>
                <b-btn
                  style="padding: 0.15rem"
                  variant="danger"
                  size="sm"
                  @click.prevent="onProcessOutDelete(outgoing.id)"
                >
                  <fa icon="trash" fixed-width />刪除
                </b-btn>
              </td>
            </tr>
            <tr class="text-right">
              <td colspan="5"><strong class="text-success">總計數量</strong></td>
              <td>
                <strong class="text-success">
                  {{ outgoings | sumBy('quantity') }}
                </strong>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
        <template v-if="scrapped.length > 0">
          <h4>
            <span>重工與報廢</span>
          </h4>
          <table class="table table-bordered table-striped table-sm">
            <thead>
              <tr>
                <th style="width: 150px">編號</th>
                <th class="text-center" style="width: 80px">種類</th>
                <th style="width: 180px">交接時間</th>
                <th>承辦人</th>
                <th>倉儲</th>
                <th class="text-right">數量</th>
                <th width="125px">操作</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="scrap in scrapped" :key="scrap.id">
                <td class="text-monospace">{{ scrap.sno }}</td>
                <td class="text-center">
                  <b-badge :variant="colors[scrap.type + 2]">{{ processOutgoingType[scrap.type] }}</b-badge>
                </td>
                <td>{{ scrap.created_at }}</td>
                <td>{{ scrap.user && scrap.user.name }}</td>
                <td>{{ scrap.warehouse && scrap.warehouse.name }}</td>
                <td class="text-right">{{ scrap.quantity }}</td>
                <td class="text-right">
                  <b-btn
                    style="padding: 0.15rem"
                    variant="primary"
                    size="sm"
                    @click.prevent="$refs.processOutgoingSetting.init(scrap.id)"
                  >
                    <fa icon="cog" fixed-width />設定
                  </b-btn>
                  <b-btn
                    style="padding: 0.15rem"
                    variant="danger"
                    size="sm"
                    @click.prevent="onProcessOutDelete(scrap.id)"
                  >
                    <fa icon="trash" fixed-width />刪除
                  </b-btn>
                </td>
              </tr>
              <tr class="text-right">
                <td colspan="5"><strong class="text-danger">總計數量</strong></td>
                <td>
                  <strong class="text-danger">
                    {{ scrapped | sumBy('quantity') }}
                  </strong>
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </template>
      </template>
      <hr />
      <div class="text-right">
        <b-btn variant="secondary" @click.prevent="modalShow = false"><fa icon="times-circle" fixed-width />取消</b-btn>
      </div>
    </el-dialog>
    <process-incoming-setting ref="processIncomingSetting" :proc="row" @done="onDone" />
    <process-outgoing-setting ref="processOutgoingSetting" :proc="row" @done="onDone" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { modalControl } from '@/plugins/mixins'
import { colors, processStatus, processIncomingType, processOutgoingType } from '@/plugins/consts'

export default {
  name: 'ProcessSetting',
  mixins: [modalControl],
  data() {
    return {
      processId: null,
      row: {},
      colors,
      processStatus,
      processIncomingType,
      processOutgoingType,
    }
  },
  computed: {
    processIncomingText() {
      return this.row.default_process_id == 1 ? '領料' : '點收'
    },
    outgoings() {
      return this.row.outgoings.filter((v) => v.type == 0) || []
    },
    scrapped() {
      return this.row.outgoings.filter((v) => v.type > 0) || []
    },
  },
  methods: {
    ...mapActions('process', ['fetchOne']),
    ...mapActions('processIncoming', { doProcessInDelete: 'doDelete' }),
    ...mapActions('processOutgoing', { doProcessOutDelete: 'doDelete' }),
    init(id) {
      if (!id) {
        this.$message.error('請提供生產製程編號')
        return false
      }

      this.processId = +id

      this.fetchOne(this.processId).then((result) => {
        this.$set(this.$data, 'row', result)
        this.modalShow = true
      })
    },
    onProcessInDelete(id, text = '刪除') {
      this.$confirm(`是否確定${text}？`, '注意', {
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      })
        .then(() => {
          this.doProcessInDelete(id).then(() => {
            this.$message.success(`${text}完成`)
            this.onDone()
          })
        })
        .catch(() => {
          this.$message.info(`已取消`)
        })
    },
    onProcessOutDelete(id, text = '刪除') {
      this.$confirm(`是否確定${text}？`, '注意', {
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      })
        .then(() => {
          this.doProcessOutDelete(id).then(() => {
            this.$message.success(`${text}完成`)
            this.onDone()
          })
        })
        .catch(() => {
          this.$message.info(`已取消`)
        })
    },
    onDone() {
      this.isDirty = true
      this.init(this.processId)
    },
  },
}
</script>
