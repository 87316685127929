import Vue from 'vue'

import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://efc350d3d084464faf51845e74059dbe@sentry.io/1808495',
    ignoreErrors: ['ResizeObserver'],
    integrations: [new Integrations.Vue({ Vue, attachProps: true })],
  })
}
