<template>
  <el-dialog :visible.sync="isVisible" :width="modalWidth" top="5px" append-to-body>
    <template #title>
      <h3 class="m-0">圖面版本</h3>
    </template>
    <div v-for="(item, index) in drawings" :key="index" class="mb-3">
      <h5 class="d-flex">
        <div class="pr-3">
          版本: <span class="text-muted">{{ item.revision }}</span>
        </div>
        <div class="pr-3">
          變更時間: <span class="text-muted">{{ item.created_at }}</span>
        </div>
        <div class="flex-grow-1">
          變更原因: <span class="text-muted">{{ item.reason }}</span>
        </div>
      </h5>
      <div class="row">
        <div v-for="(image, index2) in item.images" :key="index2" class="col-md-6">
          <img v-img:drawings :src="image" class="img-thumbnail" />
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { modalControl } from '@/plugins/mixins'
export default {
  name: 'PartDrawings',
  mixins: [modalControl],
  props: {
    drawings: {
      type: Array,
      default() {
        return []
      },
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isVisible: this.visible,
      modalMaxWidth: 800,
    }
  },
  watch: {
    visible() {
      this.isVisible = this.visible
    },
    isVisible(val) {
      this.$emit('update:visible', val)
    },
  },
}
</script>
