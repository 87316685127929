<template>
  <el-dialog :visible.sync="modalShow" :close-on-click-modal="false" :width="modalWidth" top="5px" append-to-body>
    <template #title>
      <h3 class="m-0">
        {{ actionText + '登入帳號' }}
      </h3>
    </template>
    <el-form
      v-if="modalShow"
      :ref="formRefs"
      :model="row"
      :rules="rules"
      :disabled="!$store.getters['permission/check']([67])"
      label-width="120px"
    >
      <div class="card">
        <div class="card-body p-3">
          <div class="form-row">
            <el-form-item class="col-md-6" label="權限等級" prop="role_id">
              <el-select v-model.number="row.role_id" placeholder="- 請選擇 -">
                <el-option
                  v-for="item in $store.getters['role/all']"
                  :key="item.id"
                  :label="item.name"
                  :value="Number(item.id)"
                />
              </el-select>
            </el-form-item>
            <div class="w-100"></div>
            <el-form-item class="col-md-6" label="中文姓名" prop="name.zh_TW">
              <el-input v-model.trim="row.name.zh_TW" placeholder="中文姓名" />
            </el-form-item>
            <el-form-item class="col-md-6" label="英文姓名" prop="name.en">
              <el-input v-model.trim="row.name.en" placeholder="英文姓名" />
            </el-form-item>
            <el-form-item class="col-md-6" label="登入帳號" prop="account">
              <el-input v-model.trim="row.account" :disabled="formAction !== 'create'" placeholder="登入帳號" />
            </el-form-item>
            <el-form-item
              :rules="[
                { required: formAction === 'create', message: '請輸入登入密碼', trigger: 'blur' },
                { type: 'string', min: 6, message: '密碼長度最少為 6 個字元', trigger: 'change' },
              ]"
              class="col-md-6"
              label="登入密碼"
              prop="password"
            >
              <el-input
                v-model.trim="row.password"
                :placeholder="formAction !== 'create' ? '如不更改密碼，請保留空白' : '登入密碼'"
                type="password"
                autocomplete="new-password"
              />
            </el-form-item>
            <el-form-item class="col-md-6" label="Email" prop="email">
              <el-input v-model.trim="row.email" type="email" placeholder="Email" />
            </el-form-item>
            <el-form-item class="col-md-6" label="遠端登入碼" prop="remote_code">
              <el-input v-model.trim="row.remote_code" placeholder="遠端登入碼" />
            </el-form-item>
          </div>
        </div>
      </div>
      <div class="form-row mt-3">
        <div class="col text-right">
          <b-btn variant="secondary" @click.prevent="modalShow = false">
            <fa icon="times-circle" fixed-width />取消
          </b-btn>
          <b-btn
            v-if="$store.getters['permission/check']([67])"
            type="submit"
            variant="primary"
            @click.prevent="onSubmit"
          >
            <fa icon="edit" fixed-width />{{ actionText }}
          </b-btn>
        </div>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import { userTypes } from '@/plugins/consts'
import { modify, unique } from '@/plugins/mixins'

export default {
  name: 'UserSetting',
  mixins: [modify, unique],
  data() {
    return {
      structure: {
        id: null,
        role_id: null,
        name: { en: null, zh_TW: null },
        account: null,
        password: null,
        email: null,
        remote_code: null,
      },
      rules: {
        role_id: [{ required: true, message: '請選擇權限等級', trigger: 'change' }],
        'name.zh_TW': [{ required: true, message: '請輸入中文姓名', trigger: 'blur' }],
        email: [{ type: 'email', message: '請確認Email', trigger: 'change' }],
        account: [
          { required: true, message: '請輸入登入帳號', trigger: 'blur' },
          { validator: this.doCheckUnique, message: '帳號重複，請重新確認', trigger: 'blur' },
        ],
        remote_code: [{ type: 'string', min: 6, message: '遠端登入碼最少為 6 個字元', trigger: 'change' }],
      },
      userTypes,
      modalMaxWidth: 800,
    }
  },
  methods: {
    ...mapActions('user', ['fetchOne', 'doCreate', 'doUpdate', 'checkUnique']),
  },
}
</script>
