<template>
  <div>
    <el-popover ref="pop" placement="top-start" trigger="hover">
      <template #default>
        <table class="table table-striped table-sm m-0" style="font-size: 0.875rem">
          <tbody>
            <tr>
              <td>訂單數量</td>
              <td class="text-right">{{ order.quantity }}</td>
            </tr>
            <tr>
              <td>入庫數量</td>
              <td class="text-right">{{ order.production_quantity }}</td>
            </tr>
            <tr>
              <td>出貨數量</td>
              <td class="text-right">{{ order.shipment_quantity }}</td>
            </tr>
            <tr>
              <td>退貨數量</td>
              <td class="text-right">{{ order.return_quantity }}</td>
            </tr>
            <tr>
              <td>補貨數量</td>
              <td class="text-right">{{ order.replenish_quantity }}</td>
            </tr>
          </tbody>
        </table>
      </template>
    </el-popover>
    <span v-popover:pop style="cursor: 'help'; text-decoration-line: underline; text-decoration-style: dotted">
      {{ order.shipment_quantity }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'OrderQuantity',
  props: {
    order: {
      type: Object,
      default() {
        return {
          quantity: null,
          production_quantity: null,
          shipment_quantity: null,
          return_quantity: null,
          replenish_quantity: null,
        }
      },
    },
  },
}
</script>
