<template>
  <el-select
    v-model.number="materialId"
    placeholder="- 請搜尋材料 -"
    filterable
    clearable
    remote
    :remote-method="remoteMethod"
    @clear="materialId = null"
  >
    <el-option v-for="item in filteredItems" :key="item.value" :label="item.label" :value="Number(item.value)" />
  </el-select>
</template>

<script>
export default {
  name: 'MaterialSelect',
  props: {
    value: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      filteredItems: [],
    }
  },
  computed: {
    materialId: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    allItems() {
      return this.$store.getters['material/all'].map((item) => {
        return { value: +item.id, label: this.$options.filters.materialName(item) }
      })
    },
  },
  watch: {
    materialId: {
      handler(newVal) {
        this.filter(newVal)
      },
      immediate: true,
    },
  },
  mounted() {
    this.filter(this.value)
  },
  methods: {
    filter(value) {
      this.filteredItems = this.allItems.filter((item) => item.value == value) || []
    },
    remoteMethod(query) {
      if (query !== '') {
        this.filteredItems = this.allItems.filter((item) => {
          return item.label.toLowerCase().includes(query.toLowerCase())
        })
      } else {
        this.filteredItems = []
      }
    },
  },
}
</script>
