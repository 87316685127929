<template>
  <div>
    <el-dialog :visible.sync="modalShow" :close-on-click-modal="false" :width="modalWidth" top="5px" append-to-body>
      <template #title>
        <h3 class="m-0">製令工單詳細</h3>
      </template>
      <table class="table table-bordered table-striped table-sm display">
        <tbody>
          <tr>
            <td style="width: 13%">生產製程編號</td>
            <td style="width: 20%" class="text-monospace">{{ row.sno }}</td>
            <td style="width: 13%">製造訂單編號</td>
            <td style="width: 20%" class="text-monospace">{{ row.order && row.order.sno }}</td>
            <td style="width: 13%">客戶名稱</td>
            <td>{{ row.order && row.order.customer.name }}</td>
          </tr>
          <tr>
            <td>產品名稱</td>
            <td colspan="3">
              {{ row.order && `(${row.order.product.alias_id}) ${row.order.product.name}` }}
            </td>
            <td>工單狀態</td>
            <td>
              <b-badge :variant="colors[row.status]">{{ ticketStatus[row.status] }}</b-badge>
            </td>
          </tr>
          <tr>
            <td>配件名稱</td>
            <td colspan="3">{{ row.part && `${row.part.alias_id} - ${row.part.name}` }}</td>
            <td>配件庫存</td>
            <td>
              <a v-if="row.part" href="#" @click.prevent="$refs.partStockHistory.init(row.part.id)">
                <quantity :quantity="row.part.quantity" />
              </a>
            </td>
          </tr>
          <tr v-if="row.material">
            <td>材料名稱</td>
            <td colspan="3">{{ row.material && row.material | materialName }}</td>
            <td>材料庫存</td>
            <td>
              <a href="#" @click.prevent="onMaterialHistory(row.material_id)">
                <quantity :quantity="row.material.quantity" />
              </a>
            </td>
          </tr>
          <tr>
            <td>需求數量</td>
            <td>{{ row.require_quantity }}</td>
            <td>安排生產數量</td>
            <td>{{ row.arrange_quantity }}</td>
            <td>已生產數量</td>
            <td>{{ Number(row.production_quantity) }}</td>
          </tr>
          <tr>
            <td>交付日期</td>
            <td>{{ row.delivery_date }}</td>
            <td>開始時間</td>
            <td>{{ row.begin_at }}</td>
            <td>結束時間</td>
            <td>{{ row.finish_at }}</td>
          </tr>
        </tbody>
      </table>
      <template v-if="row.parents && row.parents.length > 0">
        <h4>父系配件</h4>
        <table class="table table-bordered table-striped table-sm">
          <thead>
            <tr>
              <th style="width: 150px">生產製程編號</th>
              <th>配件名稱</th>
              <th class="text-center" style="width: 110px">狀態</th>
              <th class="text-center" style="width: 110px">組合用量</th>
              <th class="text-center" style="width: 110px">庫存量</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="parent in row.parents" :key="parent.id">
              <td class="text-monospace">{{ parent.sno }}</td>
              <td>{{ `${parent.part.name}` }}</td>
              <td class="text-center">
                <b-badge :variant="colors[parent.status]">{{ ticketStatus[parent.status] }}</b-badge>
              </td>
              <td class="text-center">
                <b-badge variant="primary">{{ parent.usage }}</b-badge>
              </td>
              <td class="text-center">
                <a href="#" @click.prevent="$refs.partStockHistory.init(parent.part.id)">
                  <quantity :quantity="parent.part.quantity" />
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </template>

      <h4>生產加工程序</h4>
      <process-detail v-for="item in row.processes" :key="item.id" :proc="item" @process-manage="onProcessManage" />
      <div class="text-right">
        <b-btn variant="secondary" @click.prevent="modalShow = false"><fa icon="times-circle" fixed-width />取消</b-btn>
      </div>
    </el-dialog>
    <material-stock-history ref="materialStockHistory" @done="onDone" />
    <part-stock-history ref="partStockHistory" @done="onDone" />
    <process-setting ref="processSetting" @material-history="onMaterialHistory" @done="onDone" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { modalControl } from '@/plugins/mixins'
import { colors, ticketStatus } from '@/plugins/consts'

export default {
  name: 'TicketDetail',
  mixins: [modalControl],
  data() {
    return {
      ticketId: null,
      row: {},
      colors,
      ticketStatus,
    }
  },
  methods: {
    ...mapActions('ticket', ['fetchOne']),
    init(id) {
      if (!id) {
        this.$message.error('請提供製令工單編號')
        return false
      }

      this.ticketId = +id
      this.fetchOne(id).then((result) => {
        this.$set(this.$data, 'row', result)
        this.modalShow = true
      })
    },
    onMaterialHistory(id) {
      this.$refs.materialStockHistory.init(id)
    },
    onProcessManage(id) {
      this.$refs.processSetting.init(id)
    },
    onDone() {
      this.isDirty = true
      this.init(this.ticketId)
    },
  },
}
</script>
