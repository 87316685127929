<template>
  <div class="w-100">
    <table class="table table-bordered table-striped table-sm display">
      <tbody>
        <tr class="vm">
          <td style="width: 13%">退貨編號</td>
          <td style="width: 20%" class="text-monospace">
            <a href="#" @click.prevent="onReturn(target.id)">{{ target.sno }} <fa icon="edit" /></a>
          </td>
          <td style="width: 13%">進貨編號</td>
          <td style="width: 20%" class="text-monospace">
            <a href="#" @click.prevent="onReceive(target.receive.id)">
              {{ target.receive && target.receive.sno }} <fa icon="edit" />
            </a>
          </td>
          <td style="width: 13%">退貨日期</td>
          <td>{{ target.date }}</td>
        </tr>
        <tr>
          <td>廠商進貨單號</td>
          <td>{{ target.receive.customer_order_no }}</td>
          <td>材料名稱</td>
          <td colspan="3">{{ target.material | materialName }}</td>
        </tr>
        <tr class="vm">
          <td>退貨數量</td>
          <td>{{ target.quantity }}</td>
          <td>進貨數量</td>
          <td>{{ target.receive.quantity }}</td>
          <td>進貨地點</td>
          <td>{{ target.target && target.target.name }}</td>
        </tr>
        <tr>
          <td>承辦人</td>
          <td>{{ target.user && target.user.name }}</td>
          <td>倉儲名稱</td>
          <td>{{ target.warehouse && target.warehouse.name }}</td>
          <td>建立時間</td>
          <td>{{ target.created_at }}</td>
        </tr>
        <tr>
          <td>退貨原因</td>
          <td colspan="5">{{ target.reason }}</td>
        </tr>
      </tbody>
    </table>
    <receive-setting ref="receiveSetting" @done="$emit('done')" />
    <receive-return-setting ref="receiveReturnSetting" hide @done="$emit('done')" />
  </div>
</template>

<script>
export default {
  name: 'AccountsPayableReceiveReturn',
  props: {
    target: {
      type: Object,
      default() {
        return {
          sno: null,
        }
      },
    },
  },
  methods: {
    onReturn(id) {
      this.$refs.receiveReturnSetting.init(id)
    },
    onReceive(id) {
      this.$refs.receiveSetting.init(id)
    },
  },
}
</script>
