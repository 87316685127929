<template>
  <div class="table-responsive">
    <table :class="{ 'table-sm': small }" class="table table-bordered table-striped display">
      <tbody>
        <tr>
          <td style="width: 13%">客戶名稱</td>
          <td style="width: 20%">{{ customer.name }}</td>
          <td style="width: 13%">產品編號</td>
          <td style="width: 20%">{{ product.alias_id }}</td>
          <td style="width: 13%">產品名稱</td>
          <td>{{ product.name }}</td>
        </tr>
        <tr v-if="$store.getters['permission/check']([1])">
          <td>產品單價</td>
          <td>
            <product-price
              :prices="product.prices"
              :currency-value="customer.currency || 0"
              :unit-price="product.unit_price"
              placement="right-start"
            />
          </td>
          <td>產品成本</td>
          <td>{{ product.cost | currency }}</td>
          <td>模具成本</td>
          <td>{{ product.mold_cost | currency }}</td>
        </tr>
        <tr>
          <td>產品單位重量</td>
          <td>{{ product.unit_weight | measure(product.measure) }}</td>
          <td>現有庫存量</td>
          <td>
            <a href="#" @click.prevent="$refs.productStockHistory.init(product.id)">
              <quantity :quantity="product.quantity" :required="product.required_quantity" />
            </a>
          </td>
          <td>安全庫存量</td>
          <td>{{ product.safety_stock }}</td>
        </tr>
        <tr v-if="full">
          <td>預設倉儲</td>
          <td>{{ product.warehouse && product.warehouse.name }}</td>
          <td>建立時間</td>
          <td>{{ product.created_at }}</td>
          <td>最後更新</td>
          <td>{{ product.updated_at }}</td>
        </tr>
        <tr v-if="full">
          <td>備註</td>
          <td colspan="5">{{ product.comment }}</td>
        </tr>
      </tbody>
    </table>
    <product-stock-history ref="productStockHistory" @done="$emit('done')" />
  </div>
</template>

<script>
export default {
  name: 'ProductDetail',
  props: {
    product: {
      type: Object,
      default() {
        return {
          id: null,
          customer_id: null,
          alias_id: null,
          name: null,
          cost: 0,
          mold_cost: 0,
          unit_price: 0,
          unit_weight: 0,
          measure: 1,
          quantity: [],
          safety_stock: 0,
          comment: '',
          created_at: null,
          updated_at: null,
          warehouse: {
            name: null,
          },
        }
      },
    },
    small: {
      type: Boolean,
      default: false,
    },
    full: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    customer() {
      return this.$store.state.customer.data.find((x) => x.id == this.product.customer_id) || {}
    },
  },
}
</script>
