<template>
  <div>
    <el-dialog :visible.sync="modalShow" :close-on-click-modal="false" :width="modalWidth" top="5px" append-to-body>
      <template #title>
        <h3 class="m-0">列印出貨標籤</h3>
      </template>
      <el-form v-if="modalShow" :ref="formRefs" :model="row" :rules="rules" label-width="100px">
        <div class="card">
          <div class="card-body p-3">
            <div class="form-row">
              <el-form-item class="col-md-6" label="客戶名稱" prop="customer_id">
                <el-select
                  v-model.number="row.customer_id"
                  :disabled="row.sno !== null"
                  placeholder="請先選擇客戶"
                  @change="onCustomerChange"
                >
                  <el-option
                    v-for="item in $store.getters['customer/consumers']"
                    :key="item.id"
                    :label="item.name"
                    :value="Number(item.id)"
                  />
                </el-select>
              </el-form-item>
            </div>
            <div v-show="row.customer_id">
              <div class="form-row">
                <el-form-item class="col-12" label="產品名稱">
                  <h6>{{ row.name && `(${row.alias_id}) ${row.name}` }}</h6>
                </el-form-item>
                <el-form-item class="col-md-6" label="語言" prop="locale">
                  <el-radio-group v-model="row.locale">
                    <el-radio label="zh_TW">中文</el-radio>
                    <el-radio label="en">英文</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item class="col-md-6" label="標籤尺寸" prop="size">
                  <el-radio-group v-model="row.size">
                    <el-radio :label="1">7 x 4</el-radio>
                    <el-radio :label="2">10 x 10</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item class="col-md-6" label="標籤抬頭" prop="company_id">
                  <el-select v-model.number="row.company_id" placeholder="請選擇公司">
                    <el-option
                      v-for="item in $store.getters['company/all']"
                      :key="item.id"
                      :label="item.name"
                      :value="Number(item.id)"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item
                  :rules="[{ required: row.size === 2, message: '請輸入出貨編號', trigger: 'blur' }]"
                  class="col-md-6"
                  label="出貨編號"
                  prop="sno"
                >
                  <el-input v-model.trim="row.sno" placeholder="出貨編號" />
                </el-form-item>
                <el-form-item class="col-md-6" label="出貨日期" prop="date">
                  <el-date-picker v-model="row.date" type="date" value-format="yyyy-MM-dd" placeholder="出貨日期" />
                </el-form-item>
                <el-form-item class="col-md-6" label="出貨數量" prop="quantity">
                  <el-input v-model.number="row.quantity" :min="1" type="number" placeholder="出貨數量">
                    <template #append> 個 </template>
                  </el-input>
                </el-form-item>

                <el-form-item class="col-md-6" label="出貨批次" prop="batch">
                  <el-input v-model.trim="row.batch" placeholder="出貨批次" />
                </el-form-item>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row mt-3">
          <div class="col text-right">
            <b-btn variant="secondary" @click.prevent="modalShow = false">
              <fa icon="times-circle" fixed-width />取消
            </b-btn>
            <b-btn v-show="row.customer_id" type="submit" variant="primary" @click.prevent="onSubmit">
              <fa icon="print" fixed-width />列印
            </b-btn>
          </div>
        </div>
      </el-form>
    </el-dialog>
    <product-choose ref="productChoose" :customer-id="row.customer_id" @done="onProductChoose" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { modify } from '@/plugins/mixins'
import { debounce } from 'lodash-es'

export default {
  name: 'ShipmentBarcode',
  mixins: [modify],
  data() {
    return {
      structure: {
        customer_id: null,
        company_id: 1,
        locale: 'zh_TW',
        size: 1,
        alias_id: null,
        name: null,
        sno: null,
        batch: null,
        date: null,
        quantity: null,
      },
      rules: {
        customer_id: [{ required: true, message: '請選擇客戶', trigger: 'change' }],
        company_id: [{ required: true, message: '請選擇公司', trigger: 'change' }],
        locale: [{ required: true, message: '請選擇語言', trigger: 'change' }],
        size: [{ required: true, message: '請選擇尺寸', trigger: 'change' }],
        date: [{ required: true, message: '請選擇出貨日期', trigger: 'blur' }],
        quantity: [{ required: true, type: 'number', min: 1, message: '請輸入出貨數量', trigger: 'blur' }],
      },
      modalMaxWidth: 800,
    }
  },
  methods: {
    ...mapActions('shipment', ['fetchOne']),
    onUpdate(id) {
      this.formAction = 'update'
      this.fetchOne(id).then((result) => {
        this.$set(this.$data, 'row', {
          customer_id: result.customer_id,
          company_id: 1,
          locale: 'zh_TW',
          size: 1,
          alias_id: result.product.alias_id,
          name: result.product.name,
          sno: result.sno,
          batch: null,
          date: result.date,
          quantity: result.quantity,
        })

        this.modalShow = true
      })
    },
    onCustomerChange(val) {
      if (val === '') {
        this.$message.error('請先選擇客戶')
        return false
      }

      this.row.customer_id = val
      this.$nextTick(() => {
        this.$refs.productChoose.init([])
      })
    },
    onProductChoose(product) {
      this.row.alias_id = product.alias_id
      this.row.name = product.name
    },
    onSubmit: debounce(function () {
      this.$refs[this.formRefs].validate((valid) => {
        if (!valid) {
          this.$message.error('請重新確認輸入欄位')
          return false
        }

        this.$emit('done', Object.assign({}, this.row))
        this.modalShow = false
        this.$forceUpdate()
      })
    }, 500),
  },
}
</script>
