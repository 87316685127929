<template>
  <el-dialog :visible.sync="modalShow" :close-on-click-modal="false" :width="modalWidth" top="5px" append-to-body>
    <template #title>
      <h3 class="m-0">
        {{ actionText + '折讓單' }}
      </h3>
    </template>
    <el-form v-if="modalShow" :ref="formRefs" :model="row" :rules="rules" label-width="120px">
      <div class="card">
        <div class="card-header">
          <h5 class="m-0">
            折讓單編號：<span class="text-monospace">{{ row.sno }}</span>
          </h5>
        </div>
        <div class="card-body p-3">
          <div v-if="row.sno" class="form-row">
            <el-form-item class="col-md-6 col-lg-4" label="廠商名稱">
              <h6>{{ row.customer && row.customer.name }}</h6>
            </el-form-item>
            <el-form-item class="col-md-6 col-lg-4" label="折讓金額" prop="credit">
              <el-input v-model.number="row.credit" type="number" placeholder="折讓金額">
                <template #prepend>
                  {{ row.currency | currencySymbol }}
                </template>
              </el-input>
            </el-form-item>
            <el-form-item class="col-md-6 col-lg-4" label="應稅" prop="is_invoice">
              <el-switch
                v-model.number="row.is_invoice"
                :active-value="1"
                :inactive-value="0"
                active-text="是"
                inactive-text="否"
              />
            </el-form-item>
            <el-form-item class="col-md-6 col-lg-4" label="小計金額">
              <h6>{{ subTotal | currency(row.currency) }}</h6>
            </el-form-item>
            <el-form-item class="col-md-6 col-lg-4" label="稅金">
              <h6>{{ row.tax | currency(row.currency) }}</h6>
            </el-form-item>
          </div>
        </div>
      </div>
      <div v-if="row.targetable" class="mt-3">
        <h4>折讓原因</h4>
        <component :is="targetComponent" :target="row.targetable" :customer="row.customer" @done="init(row.id)" />
      </div>
      <div class="form-row mt-3">
        <div class="col text-right">
          <b-btn variant="secondary" @click.prevent="modalShow = false">
            <fa icon="times-circle" fixed-width />取消
          </b-btn>
          <b-btn type="submit" variant="primary" @click.prevent="onSubmit">
            <fa icon="edit" fixed-width />{{ actionText }}
          </b-btn>
        </div>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import { modify } from '@/plugins/mixins'
import { colors } from '@/plugins/consts'

export default {
  name: `AllowanceSetting`,
  mixins: [modify],
  data() {
    return {
      rules: {
        credit: [
          { required: true, message: '請輸入折讓金額', trigger: 'blur' },
          { type: 'number', min: 0, message: '請確認折讓金額', trigger: 'change' },
        ],
        is_invoice: [{ required: true, message: '請選擇是否應稅', trigger: 'change' }],
      },
      colors,
    }
  },
  computed: {
    targetComponent() {
      switch (this.row.targetable_type) {
        case 'App\\Models\\Shipment':
          return 'AccountsReceivableShipment'
        default:
          return 'AccountsReceivableShipmentReturn'
      }
    },
    subTotal() {
      return this.row.credit + this.row.tax
    },
  },
  watch: {
    'row.is_invoice': {
      handler(val) {
        this.row.tax = val ? (this.row.credit * this.row.customer.tax) / 100 : 0
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions(`allowance`, ['fetchOne', 'doCreate', 'doUpdate']),
  },
}
</script>
