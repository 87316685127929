<template>
  <div class="table-responsive">
    <table v-for="(item, index) in items" :key="index" class="table table-bordered table-striped table-sm display">
      <tbody>
        <tr class="vm">
          <td style="width: 13%">種類</td>
          <td style="width: 20%">
            <b-badge :variant="colors[item.type]">{{ purchaseItemTypes[item.type] }}</b-badge>
          </td>
          <td style="width: 13%">材料名稱</td>
          <td colspan="3">
            <el-form-item
              :prop="`items.${index}.material_id`"
              :rules="{ required: true, message: ' ', trigger: 'change' }"
              :style="{ width: formAction === 'create' ? `calc(100% - 45px)` : `100%` }"
              class="m-0 p-0 float-left"
              label-width="0"
              size="small"
            >
              <material-select v-model="item.material_id" />
            </el-form-item>
            <b-btn
              v-if="formAction === 'create'"
              class="float-right"
              variant="danger"
              size="sm"
              @click.prevent="$emit('remove-item', index)"
            >
              <fa icon="trash-alt" fixed-width />
            </b-btn>
          </td>
        </tr>
        <tr class="vm">
          <td>狀態</td>
          <td>
            <el-form-item
              :prop="`items.${index}.status`"
              :rules="{ required: true, message: ' ', trigger: 'change' }"
              class="m-0 p-0"
              label-width="0"
              size="small"
            >
              <el-select v-model.number="item.status" placeholder="- 請選擇狀態 -">
                <el-option
                  v-for="(status, index2) in purchaseItemStatus"
                  :key="index2"
                  :label="status"
                  :value="Number(index2)"
                />
              </el-select>
            </el-form-item>
          </td>
          <td style="width: 13%">採購數量</td>
          <td style="width: 20%">
            <el-form-item
              :prop="`items.${index}.quantity`"
              :rules="{ required: true, type: 'integer', min: 1, message: ' ', trigger: 'change' }"
              class="m-0 p-0"
              label-width="0"
              size="small"
            >
              <el-input v-model.number="item.quantity" type="number" :min="1" placeholder="採購數量">
                <template #append> 個 </template>
              </el-input>
            </el-form-item>
          </td>
          <td style="width: 13%">交貨日期</td>
          <td>
            <el-form-item
              :prop="`items.${index}.delivery_date`"
              :rules="{ required: true, message: ' ', trigger: 'change' }"
              class="m-0 p-0"
              label-width="0"
              size="small"
            >
              <el-date-picker
                v-model="item.delivery_date"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="交貨日期"
              />
            </el-form-item>
          </td>
        </tr>
        <tr class="vm">
          <td>送貨地點</td>
          <td>
            <el-form-item
              :prop="`items.${index}.target_id`"
              :rules="{ required: true, message: ' ', trigger: 'change' }"
              class="m-0 p-0"
              label-width="0"
              size="small"
            >
              <el-select
                v-model.number="item.target_id"
                placeholder="- 請選擇送貨地點 -"
                filterable
                @change="targetChange(item)"
              >
                <el-option
                  v-for="outsource in $store.getters['customer/outsources']"
                  :key="outsource.id"
                  :label="outsource.name"
                  :value="Number(outsource.id)"
                />
              </el-select>
            </el-form-item>
          </td>
          <td>入庫到</td>
          <td>
            <el-form-item
              :prop="`items.${index}.warehouse_id`"
              :rules="{ required: item.target_id === 0, message: ' ', trigger: 'change' }"
              class="m-0 p-0"
              label-width="0"
              size="small"
            >
              <el-select
                v-model.number="item.warehouse_id"
                :disabled="item.target_id !== 0"
                placeholder="- 入庫到 -"
                filterable
              >
                <el-option
                  v-for="warehouse in $store.getters['warehouse/material']"
                  :key="warehouse.id"
                  :label="warehouse.name"
                  :value="Number(warehouse.id)"
                />
              </el-select>
            </el-form-item>
          </td>
          <td>備註</td>
          <td>
            <el-form-item class="m-0 p-0" label-width="0" size="small">
              <el-input v-model.trim="item.comment" placeholder="備註" />
            </el-form-item>
          </td>
        </tr>
        <tr v-if="item.receives.length">
          <td>進貨記錄</td>
          <td class="p-0" colspan="5">
            <table class="table table-striped table-bordered table-sm m-0">
              <thead>
                <tr>
                  <th style="width: 150px">進貨編號</th>
                  <th>廠商訂單編號</th>
                  <th style="width: 110px">到貨日期</th>
                  <th class="text-right" style="width: 110px">到貨數量</th>
                  <th class="text-right" style="width: 110px">每單位重量</th>
                  <th class="text-right" style="width: 110px">單價</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="receive in item.receives" :key="receive.id" class="bg-white">
                  <td class="text-monospace">{{ receive.sno }}</td>
                  <td>{{ receive.customer_order_no }}</td>
                  <td>{{ receive.date }}</td>
                  <td class="text-right">{{ receive.quantity }}</td>
                  <td class="text-right">{{ receive.unit_weight | measure(receive.measure) }}</td>
                  <td class="text-right">{{ receive.unit_price | currency(currencyVal) }}</td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { purchaseStatus, purchaseItemTypes, purchaseItemStatus, colors } from '@/plugins/consts'

export default {
  name: 'PurchaseSettingItem',
  props: {
    items: {
      type: Array,
      default: () => [{}],
    },
    currencyVal: {
      type: Number,
      default: 0,
    },
    formAction: {
      type: String,
      default: 'create',
    },
  },
  data() {
    return {
      purchaseStatus,
      purchaseItemTypes,
      purchaseItemStatus,
      colors,
    }
  },
  methods: {
    targetChange(item) {
      if (item.target_id !== 0) {
        item.warehouse_id = null
      }
    },
  },
}
</script>
