<template>
  <el-dialog :visible.sync="modalShow" title="材料庫存記錄" :width="modalWidth" top="5px" append-to-body>
    <el-table v-loading="isLoading" :data="rows" stripe border>
      <el-table-column label="建立時間" prop="created_at" width="190" />
      <el-table-column v-slot="{ row }" label="種類" align="center" width="80">
        <span :class="['badge', `badge-${colors[prefix(row.stockable_type)].color}`]">
          {{ colors[prefix(row.stockable_type)].type }}
        </span>
      </el-table-column>
      <el-table-column prop="stockable.sno" label="編號" width="150" class-name="text-monospace" />
      <el-table-column prop="user.name" label="承辦人" width="100" />
      <el-table-column prop="warehouse.name" label="倉儲" width="120" />
      <el-table-column label="數量" prop="quantity" align="right" />
    </el-table>
    <pagination-modal :pagination="pagination" @page-change="(val) => (query.page = val)" />

    <div class="mt-3 text-right">
      <b-btn variant="secondary" @click.prevent="modalShow = false"><fa icon="times-circle" fixed-width />關閉</b-btn>
    </div>
  </el-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import { fetchData, modalControl } from '@/plugins/mixins'

export default {
  name: 'MaterialStockHistory',
  mixins: [fetchData, modalControl],
  data() {
    return {
      query: { material_id: null },
      colors: {
        PL: { color: 'primary', type: '進貨' },
        RR: { color: 'danger', type: '退貨' },
        IP: { color: 'orange', type: '領料' },
        XX: { color: 'success', type: '盤點' },
      },
      modalMaxWidth: 800,
    }
  },
  watch: {
    'query.page'() {
      this.getAll()
    },
  },
  methods: {
    ...mapActions('stockMaterial', ['fetchPage']),
    init(id) {
      if (!id) {
        return false
      }

      this.query.page = 1
      this.query.material_id = id
      this.getAll()
      this.modalShow = true
    },

    // TODO: check this
    prefix(type) {
      switch (type) {
        case 'App\\Models\\Receive':
          return 'PL'
        case 'App\\Models\\ReceiveReturn':
          return 'RR'
        case 'App\\Models\\ProcessIn':
          return 'IP'
        default:
          return 'XX'
      }
    },
  },
}
</script>
