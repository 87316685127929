import { render, staticRenderFns } from "./AccountsPayableReceive.vue?vue&type=template&id=226e6284&"
import script from "./AccountsPayableReceive.vue?vue&type=script&lang=js&"
export * from "./AccountsPayableReceive.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports