<template>
  <el-dialog :visible.sync="modalShow" :close-on-click-modal="false" :width="modalWidth" top="5px" append-to-body>
    <template #title>
      <h3 class="m-0">應收賬款月結帳拆分</h3>
    </template>
    <div v-if="children.length > 0" class="w-50 mb-3">
      <el-select
        ref="target"
        v-model.number="target_id"
        placeholder="- 請選擇現有月結帳編號，如不選擇則為新增月結帳 -"
        clearable
      >
        <el-option v-for="(item, index) in children" :key="index" :label="item.sno" :value="Number(item.id)">
          <div class="mb-1">編號：{{ item.sno }}，現有應收賬款數量：{{ item.children_count }} 筆</div>
        </el-option>
      </el-select>
    </div>
    <el-table ref="splitTable" :data="rows" size="small" stripe border @selection-change="onSelected">
      <el-table-column type="selection" width="40" />
      <el-table-column prop="sno" label="應收帳款編號" width="110" class-name="text-monospace" />
      <el-table-column prop="targetable.sno" label="對象編號" width="110" class-name="text-monospace" />
      <el-table-column v-slot="{ row }" label="種類" width="60" align="center">
        <b-badge :variant="colors[row.type + 1]">{{ accountsTypes[row.type] }}</b-badge>
      </el-table-column>
      <el-table-column v-slot="{ row }" label="狀態" width="85" align="center">
        <b-badge :variant="colors[row.status]">{{ accountsReceivableStatus[row.status] }}</b-badge>
      </el-table-column>
      <el-table-column v-slot="{ row }" label="對象名稱" show-overflow-tooltip>
        {{
          row.targetable && row.targetable.product
            ? `(${row.targetable.product.alias_id}) ${row.targetable.product.name}`
            : ''
        }}
      </el-table-column>
      <el-table-column v-slot="{ row }" label="日期" width="100" class-name="text-monospace">
        {{ row.targetable && row.targetable.date }}
      </el-table-column>
      <el-table-column v-slot="{ row }" label="數量" width="80" align="right">
        {{ row.targetable && row.targetable.quantity }}
      </el-table-column>
      <el-table-column label="結算日期" prop="estimate_receive_date" width="100" class-name="text-monospace" />
      <el-table-column v-slot="{ row }" label="應收金額" width="140" align="right" class-name="text-monospace">
        {{ row.receivable | currency(currency) }}
      </el-table-column>
    </el-table>
    <div class="mt-3">
      <h6 class="m-0">
        已選取項目：{{ selected.length }} 個，小計金額：{{ subTotal | currency(currency) }} ，將{{
          target_id ? '拆分到：' + target : '新增一份月結帳'
        }}
      </h6>
    </div>
    <div class="mt-3 d-flex">
      <b-btn class="ml-auto" variant="secondary" @click.prevent="modalShow = false">
        <fa icon="times-circle" fixed-width />關閉
      </b-btn>
      <b-btn type="submit" variant="primary" @click.prevent="onSubmit"> <fa icon="edit" fixed-width />拆分帳目 </b-btn>
    </div>
  </el-dialog>
</template>

<script>
import { colors, accountsReceivableStatus, accountsTypes } from '@/plugins/consts'
import { mapActions } from 'vuex'
import { modalControl } from '@/plugins/mixins'

export default {
  name: 'AccountsReceivableSplit',
  mixins: [modalControl],
  data() {
    return {
      id: null,
      target_id: null,
      rows: [],
      children: [],
      currency: 0,
      selected: [],
      colors,
      accountsReceivableStatus,
      accountsTypes,
    }
  },
  computed: {
    subTotal() {
      return this.selected.length > 0 ? this.selected.map((s) => s.receivable).reduce((a, b) => a + b, 0) : 0
    },
    target() {
      return this.target_id ? this.children.find((x) => x.id == this.target_id)?.sno : ''
    },
  },
  methods: {
    ...mapActions('monthlyReceivable', ['fetchOne', 'doSplit']),
    init(id) {
      this.modalShow = true
      this.id = +id
      this.getAll()
    },
    getAll() {
      this.fetchOne(this.id)
        .then((result) => {
          this.currency = result.currency
          this.children = [].concat(result.splitted)
          this.$set(this.$data, 'rows', result.list)
        })
        .catch((err) => console.log(err))
    },
    onSelected(checked) {
      this.$set(this.$data, 'selected', checked)
    },
    onSubmit() {
      if (this.selected.length < 1) {
        this.$message.error('請選擇銷帳項目')
        return false
      }

      if (this.rows.length == this.selected.length) {
        this.$message.error('帳目拆分不得為全部選擇')
        return false
      }

      this.$confirm(`是否確定拆分帳目？`, '注意', {
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      })
        .then(() => {
          let selected = this.selected.map((s) => s.id)
          this.doSplit({ id: this.id, target_id: this.target_id || null, receivables: selected }).then(() => {
            this.$message.success(`月結帳拆分完成`)
            this.selected = []
            this.isDirty = true
            this.modalShow = false
          })
        })
        .catch(() => {
          this.$message.info(`已取消`)
        })
    },
  },
}
</script>
