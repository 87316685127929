<template>
  <el-dialog :visible.sync="modalShow" :close-on-click-modal="false" :width="modalWidth" top="5px" append-to-body>
    <template #title>
      <h3 class="m-0">選擇產品</h3>
    </template>
    <div class="w-50 mb-3">
      <el-input v-model.trim="filter" placeholder="搜尋產品編號與名稱" clearable @input="doFilter" />
    </div>
    <el-table v-loading="isLoading" :data="filterProducts" stripe border>
      <el-table-column label="編號" prop="alias_id" />
      <el-table-column label="名稱" prop="name" />
      <el-table-column
        v-if="$store.getters['permission/check']([1])"
        v-slot="{ row }"
        label="單價"
        prop="unit_price"
        width="150"
        align="right"
      >
        {{ row.unit_price | currency(currency, currency ? 3 : 2) }}
      </el-table-column>
      <el-table-column v-slot="{ row }" label="現有庫存" width="90" align="right">
        <quantity :quantity="row.quantity" :required="row.required_quantity" />
      </el-table-column>
      <el-table-column v-slot="{ row }" label="選取" width="85">
        <b-btn size="sm" variant="primary" @click.prevent="onProductChosen(row)">
          <fa icon="check-circle" fixed-width />選取
        </b-btn>
      </el-table-column>
    </el-table>
    <div class="mt-3 text-right">
      <b-btn variant="secondary" @click.prevent="modalShow = false"><fa icon="times-circle" fixed-width />關閉</b-btn>
    </div>
  </el-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import { modalControl } from '@/plugins/mixins'

export default {
  name: 'ProductChoose',
  mixins: [modalControl],
  props: {
    customerId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      filter: null,
      products: [],
      filterProducts: [],
      isLoading: false,
      modalMaxWidth: 900,
      customer_id: null,
    }
  },
  computed: {
    currency() {
      return this.$store.state.customer.data.find((x) => x.id == this.customerId)?.currency || 0
    },
  },
  methods: {
    ...mapActions('product', ['fetchAll']),
    async init(pids) {
      if (!this.customerId) {
        return false
      }

      if (this.customerId !== this.customer_id) {
        this.customer_id = this.customerId
        this.products = await this.fetchAll({ customer_id: this.customerId })
      }
      this.filterProducts = this.products.filter((item) => !pids.includes(item.id))
      this.modalShow = true
    },
    doFilter(val) {
      this.filterProducts = this.products.filter((item) => {
        return (
          item.alias_id.toLowerCase().includes(val.toLowerCase()) || item.name.toLowerCase().includes(val.toLowerCase())
        )
      })
    },
    onProductChosen(product) {
      this.filter = null
      this.$emit('done', product)
      this.modalShow = false
    },
  },
}
</script>
