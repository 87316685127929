<template>
  <div>
    <el-dialog :visible.sync="modalShow" :close-on-click-modal="false" :width="modalWidth" top="5px" append-to-body>
      <template #title>
        <h3 class="m-0">選擇進貨</h3>
      </template>
      <div class="w-50 mb-3">
        <el-input v-model.trim="query.search" placeholder="搜尋進貨編號或採購編號" clearable @change="getAll()" />
      </div>
      <el-table v-loading="isLoading" :data="rows" stripe border>
        <el-table-column prop="sno" label="進貨編號" width="130" class-name="text-monospace" />
        <el-table-column prop="purchase.sno" label="採購編號" width="130" class-name="text-monospace" />
        <el-table-column v-slot="{ row }" label="材料名稱">
          {{ row.material | materialName }}
        </el-table-column>
        <el-table-column label="進貨日期" prop="date" width="120" />
        <el-table-column label="進貨數量" prop="quantity" width="85" align="right" />
        <el-table-column v-slot="{ row }" label="選取" width="85">
          <b-btn size="sm" variant="primary" @click.prevent="onChoosen(row)">
            <fa icon="check-circle" fixed-width />選取
          </b-btn>
        </el-table-column>
      </el-table>
      <pagination-modal :pagination="pagination" @page-change="(val) => (query.page = val)" />

      <div class="mt-3 text-right">
        <b-btn variant="secondary" @click.prevent="modalShow = false"><fa icon="times-circle" fixed-width />關閉</b-btn>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { fetchData, modalControl } from '@/plugins/mixins'

export default {
  name: 'ReceiveChoose',
  mixins: [fetchData, modalControl],
  data() {
    return {
      query: { customer_id: null },
      modalMaxWidth: 900,
    }
  },
  watch: {
    'query.page'() {
      this.getAll()
    },
  },
  methods: {
    ...mapActions('receive', ['fetchPage']),
    init(customerId) {
      if (customerId === '') {
        return false
      }
      this.query.page = 1
      this.query.customer_id = customerId

      this.getAll()
      this.modalShow = true
    },
    onChoosen(receive) {
      this.query.search = null
      this.$emit('done', receive)
      this.modalShow = false
    },
  },
}
</script>
