import store from '@/store'
import Pusher from 'pusher-js'
import laravelEcho from 'laravel-echo'
import { Notification } from 'element-ui'

export default () => {
  // window.io = io
  window.Pusher = Pusher
  const Echo = new laravelEcho({
    cluster: 'ap3',
    broadcaster: 'pusher',
    key: '97571687',
    wsHost: window.location.hostname,
    wsPort: 6001,
    wssPort: 6001,
    enabledTransports: ['ws', 'wss'],
    forceTLS: false,
    disableStats: true,
    // enableStats: false,
    auth: { headers: { Authorization: store.getters['auth/token'] } },
  })

  Echo.join('sync.1')
    .here((users) => {
      console.log(users)
    })
    // .joining(user => {
    //     console.log(user)
    // })
    // .leaving(user => {
    //     console.log(user)
    // })
    .listen('SyncEvent', ({ data }) => {
      data.forEach((target) => {
        store.dispatch(`${target}/fetchAll`)
      })
    })
    .listen('PermissionEvent', async ({ role_id }) => {
      if (store.getters['auth/user'].role_id == role_id) {
        try {
          let permission = await store.dispatch('auth/fetchPermission')
          await store.dispatch('permission/updatePermissions', permission)
        } catch (err) {
          console.log(err)
        }
      }
    })
    .listen('ReloadEvent', ({ seconds }) => {
      Notification({
        title: '伺服器通知',
        message: `ERP 系統已更新，瀏覽器將在 ${seconds} 秒後自動刷新頁面`,
        type: 'success',
        position: 'bottom-right',
        duration: seconds * 1000,
        onClick: () => window.location.reload(true),
        onClose: () => window.location.reload(true),
      })
    })
}
