<template>
  <div class="tags-container">
    <scroll-pane ref="scrollPane" class="bg-light">
      <router-link
        v-for="tag in visitedViews"
        ref="tag"
        :key="tag.name"
        :class="{ active: isActive(tag) }"
        :to="{ path: tag.path, query: tag.query }"
        class="tags-item"
      >
        {{ tag.meta.title }} <fa icon="times" @click.prevent="closeTag(tag)" />
      </router-link>
    </scroll-pane>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'TagsView',
  computed: {
    ...mapState('app', ['visitedViews']),
  },
  watch: {
    $route() {
      this.addVisited()
      this.moveToCurrentTag()
    },
  },
  mounted() {
    this.addVisited()
  },
  methods: {
    closeTag(view) {
      this.$store.dispatch('app/delVisitedView', view).then((views) => {
        if (this.isActive(view)) {
          const latestView = views.slice(-1)[0]
          if (latestView) {
            this.$router.push({ path: latestView.path }).catch(() => {})
          } else {
            this.$router.push('/').catch(() => {})
          }
        }
        this.moveToCurrentTag()
      })
    },
    addVisited() {
      if (this.$route.meta.hidden) {
        return false
      }
      this.$store.dispatch('app/addVisitedView', this.$route)
    },
    isActive(route) {
      return route.path === this.$route.path
    },
    moveToCurrentTag() {
      const tags = this.$refs.tag || []
      let tag = tags.find((v) => v.path === this.$route.path)
      if (tag) {
        this.$nextTick(() => {
          this.$refs.scrollPane.moveToTarget(tag)
        })
      }
    },
  },
}
</script>
