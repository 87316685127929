<template>
  <el-dialog :visible.sync="modalShow" :close-on-click-modal="false" :width="modalWidth" top="5px" append-to-body>
    <template #title>
      <h3 class="m-0">模具設定</h3>
    </template>
    <el-form v-if="modalShow" :ref="formRefs" :model="row" :rules="rules" label-width="120px">
      <div class="card">
        <div class="card-body p-3">
          <div class="form-row">
            <el-form-item class="col-md-6" label="模具名稱" prop="name">
              <el-input v-model.trim="row.name" placeholder="請輸入模具名稱" />
            </el-form-item>
            <el-form-item class="col-md-6" label="狀態">
              <h6 class="m-0">
                <b-badge :variant="colors[row.status]">{{ moldStatus[row.status] }}</b-badge>
              </h6>
            </el-form-item>
            <el-form-item
              :rules="{ required: row.status === 1, message: '請選擇存放位置', trigger: 'change' }"
              class="col-md-6"
              label="存放位置"
              prop="warehouse_id"
            >
              <el-select v-model.number="row.warehouse_id" :disabled="row.status === 2" placeholder="- 請選擇 -">
                <el-option
                  v-for="warehouse in $store.getters['warehouse/mold']"
                  :key="warehouse.id"
                  :label="warehouse.name"
                  :value="Number(warehouse.id)"
                />
              </el-select>
            </el-form-item>
            <el-form-item class="col-md-6" label="所有權人" prop="owner_id">
              <el-switch
                v-model.number="row.owner_id"
                :active-value="0"
                :inactive-value="1"
                active-text="本廠"
                inactive-text="客戶"
              />
            </el-form-item>
            <el-form-item
              :rules="{ required: formAction === 'create', message: '請選擇模具生產商', trigger: 'change' }"
              class="col-md-6"
              label="模具生產商"
              prop="vendor_id"
            >
              <el-select v-model.number="row.vendor_id" :disabled="formAction !== 'create'" placeholder="- 請選擇 -">
                <el-option
                  v-for="vendor in $store.getters['customer/molds']"
                  :key="vendor.id"
                  :label="vendor.name"
                  :value="Number(vendor.id)"
                />
              </el-select>
            </el-form-item>
            <el-form-item class="col-md-6" label="模具廠商編號" prop="vendor_alias_id">
              <el-input v-model.trim="row.vendor_alias_id" placeholder="請輸入模具廠商編號" />
            </el-form-item>
            <el-form-item class="col" label="備註" prop="comment">
              <el-input v-model.trim="row.comment" placeholder="備註" />
            </el-form-item>
            <div class="w-100"></div>
            <el-form-item class="col-sm-12" label="圖面">
              <image-upload :column="4" :image-list="row.drawings" @drawing-change="onDrawingChange" />
            </el-form-item>
          </div>
        </div>
      </div>
      <div class="form-row mt-3">
        <div class="col text-right">
          <b-btn variant="secondary" @click.prevent="modalShow = false">
            <fa icon="times-circle" fixed-width />取消
          </b-btn>
          <b-btn type="submit" variant="primary" @click.prevent="onSubmit">
            <fa icon="edit" fixed-width />{{ actionText }}
          </b-btn>
        </div>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import { colors, moldStatus } from '@/plugins/consts'
import { modify } from '@/plugins/mixins'

export default {
  name: 'MoldSetting',
  mixins: [modify],
  data() {
    return {
      structure: {
        name: null,
        status: 2,
        part_id: null,
        owner_id: 0,
        warehouse_id: null,
        vendor_id: null,
        vendor_alias_id: null,
        comment: null,
        drawings: [],
      },
      rules: {
        name: [{ required: true, message: '請輸入模具名稱', trigger: 'blur' }],
        status: [{ required: true, message: '請選擇狀態', trigger: 'change' }],
        owner_id: [{ required: true, message: '請選擇所有權人', trigger: 'change' }],
      },
      modalMaxWidth: 800,
      colors,
      moldStatus,
    }
  },
  methods: {
    ...mapActions('mold', ['fetchOne', 'doUpdate']),
    onUpdate(id) {
      this.formAction = 'edit'
      this.fetchOne(id).then((result) => {
        result.drawings = result.drawings.map((img) => {
          return { name: img.split('/').pop(), url: img }
        })

        this.$set(this.$data, 'row', result)
        this.modalShow = true
      })
    },
    onDrawingChange(drawings) {
      this.row.drawings = drawings
    },
  },
}
</script>
