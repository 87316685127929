<template>
  <el-dialog :visible.sync="isVisible" title="模具圖面" :width="modalWidth" top="5px" append-to-body>
    <template #title>
      <h3 class="m-0">模具圖面</h3>
    </template>
    <div class="row">
      <div v-for="(item, index) in drawings" :key="index" class="col-md-6">
        <img v-img:drawings :src="item" class="img-thumbnail" />
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { modalControl } from '@/plugins/mixins'
export default {
  name: 'MoldDrawings',
  mixins: [modalControl],
  props: {
    drawings: {
      type: Array,
      default() {
        return []
      },
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isVisible: this.visible,
      modalMaxWidth: 800,
    }
  },
  watch: {
    visible() {
      this.isVisible = this.visible
    },
    isVisible(val) {
      this.$emit('update:visible', val)
    },
  },
}
</script>
