<template>
  <div>
    <table v-if="units.length > 0" :class="{ 'table-sm': small }" class="table table-bordered m-0">
      <thead>
        <tr class="vm">
          <th style="width: 20%">配件名稱</th>
          <th class="text-center" style="width: 10%">狀態</th>
          <th v-if="$store.getters['permission/check']([1])" class="text-right" style="width: 15%">成本</th>
          <th style="width: 15%">建立時間</th>
          <th>備註</th>
          <th style="width: 90px" class="text-right">
            <b-btn
              v-if="$store.getters['permission/check']([20])"
              variant="success"
              size="sm"
              @click.prevent="onCreate()"
            >
              <fa icon="plus-circle" fixed-width />
            </b-btn>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in units" :key="index" class="vm">
          <td class="table-light">{{ item.name }}</td>
          <td class="text-center table-light">
            <b-badge :variant="colors[item.status]">{{ moldUnitStatus[item.status] }}</b-badge>
          </td>
          <td v-if="$store.getters['permission/check']([1])" class="text-right table-light">
            {{ item.cost | currency }}
          </td>
          <td class="table-light">{{ item.created_at }}</td>
          <td class="table-light">{{ item.comment }}</td>
          <td class="text-right table-light">
            <b-btn-group>
              <b-btn
                v-if="$store.getters['permission/check']([21])"
                variant="info"
                size="sm"
                @click.prevent="onUpdate(item.id)"
              >
                <fa icon="cog" fixed-width />
              </b-btn>
              <b-btn
                v-if="item.status == 1 && $store.getters['permission/check']([22])"
                variant="indigo"
                size="sm"
                @click.prevent="onDelete(item.id, '報廢')"
              >
                <fa icon="ban" fixed-width />
              </b-btn>
            </b-btn-group>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-else class="d-flex justify-content-between align-items-center">
      <p class="m-2 text-danger"><strong>目前尚無配件</strong></p>
      <div class="ml-auto mr-1">
        <b-btn variant="success" size="sm" @click.prevent="onCreate()">
          <fa icon="plus-circle" fixed-width />新增
        </b-btn>
      </div>
    </div>
    <mold-unit-setting :ref="settingRef" :mold-id="moldId" @done="$emit('done')" />
  </div>
</template>

<script>
import { colors, moldUnitStatus } from '@/plugins/consts'
import { fetchData } from '@/plugins/mixins'
import { mapActions } from 'vuex'

export default {
  name: 'MoldUnit',
  mixins: [fetchData],
  props: {
    moldId: {
      type: Number,
      default: null,
    },
    units: {
      type: Array,
      default() {
        return []
      },
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      settingRef: 'moldUnitSetting',
      colors,
      moldUnitStatus,
    }
  },
  methods: {
    ...mapActions('moldUnit', ['doDelete']),
  },
}
</script>
