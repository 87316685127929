<template>
  <div class="w-100">
    <table class="table table-bordered table-striped table-sm display">
      <tbody>
        <tr class="vm">
          <td style="width: 13%">進貨編號</td>
          <td style="width: 20%" class="text-monospace">
            <a href="#" @click.prevent="onReceive(target.id)">{{ target.sno }} <fa icon="edit" /></a>
          </td>
          <td style="width: 13%">進貨日期</td>
          <td style="width: 20%">{{ target.date }}</td>
          <td style="width: 13%">進貨地點</td>
          <td>{{ target.target && target.target.name }}</td>
        </tr>
        <tr>
          <td>供料商單號</td>
          <td>{{ target.customer_order_no }}</td>
          <td>材料名稱</td>
          <td colspan="3">{{ target.material | materialName }}</td>
        </tr>
        <tr>
          <td>進貨數量</td>
          <td>{{ target.quantity }}</td>
          <td>材料單重</td>
          <td>{{ target.unit_weight | measure(target.measure) }}</td>
          <td>進貨單價</td>
          <td>{{ target.unit_price | currency(customer.currency || 0) }}</td>
        </tr>
        <tr>
          <td>承辦人</td>
          <td>{{ target.user && target.user.name }}</td>
          <td>入庫倉儲</td>
          <td>{{ target.warehouse && target.warehouse.name }}</td>
          <td>建立時間</td>
          <td>{{ target.created_at }}</td>
        </tr>
        <tr>
          <td>備註</td>
          <td colspan="5">{{ target.comment }}</td>
        </tr>
      </tbody>
    </table>
    <receive-setting ref="receiveSetting" @done="$emit('done')" />
  </div>
</template>

<script>
export default {
  name: 'AccountsPayableReceive',
  props: {
    target: {
      type: Object,
      default() {
        return {
          sno: null,
        }
      },
    },
  },
  computed: {
    customer() {
      return this.$store.state.customer.data.find((x) => x.id == this.target.customer_id)?.currency || {}
    },
  },
  methods: {
    onReceive(id) {
      this.$refs.receiveSetting.init(id)
    },
  },
}
</script>
