<template>
  <table class="table table-bordered table-striped table-sm">
    <thead>
      <tr>
        <th style="width: 170px">入庫編號</th>
        <th>入庫日期</th>
        <th class="text-center" style="width: 80px">種類</th>
        <th>承辦人</th>
        <th>入庫倉儲</th>
        <th class="text-right">入庫數量</th>
        <th class="text-right">重工數量</th>
        <th class="text-right">報廢數量</th>
        <th>備註</th>
        <th style="width: 120px">操作</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in items" :key="item.id">
        <td class="text-monospace">
          <a href="#" @click.prevent="$emit('prepare-update', item.id)">{{ item.sno }} <fa icon="edit" /></a>
        </td>
        <td>{{ item.date }}</td>
        <td class="text-center">
          <b-badge v-if="item.targetable_type == 'App\\Models\\ReworkItem'" variant="primary">重工</b-badge>
          <b-badge v-else variant="success">一般</b-badge>
        </td>
        <td>{{ item.user && item.user.name }}</td>
        <td>{{ item.warehouse && item.warehouse.name }}</td>
        <td class="text-right">{{ item.quantity }}</td>
        <td class="text-right">{{ item.rework.id && item.rework.quantity }}</td>
        <td class="text-right">{{ item.scrap.id && item.scrap.quantity }}</td>
        <td>{{ item.comment }}</td>
        <td>
          <b-dropdown variant="primary" size="sm" :boundary="boundary">
            <template #button-content>
              <fa icon="tools" fixed-width />
            </template>
            <b-dropdown-item-btn @click.prevent="$emit('prepare-rework', item.id)">
              <fa icon="hammer" fixed-width /> 重工
            </b-dropdown-item-btn>
            <b-dropdown-item-btn
              v-if="item.rework && item.rework.id"
              @click.prevent="$emit('prepare-rework-delete', item.rework.id)"
            >
              <fa icon="trash-alt" fixed-width /> 刪除重工
            </b-dropdown-item-btn>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item-btn @click.prevent="$emit('prepare-scrap', item.id)">
              <fa icon="fill-drip" fixed-width /> 報廢
            </b-dropdown-item-btn>
            <b-dropdown-item-btn
              v-if="item.scrap && item.scrap.id"
              @click.prevent="$emit('prepare-scrap-delete', item.scrap.id)"
            >
              <fa icon="trash-alt" fixed-width /> 刪除報廢
            </b-dropdown-item-btn>
          </b-dropdown>
          <b-btn class="ml-1" variant="danger" size="sm" @click.prevent="$emit('prepare-delete', item.id)">
            <fa icon="trash" fixed-width />
          </b-btn>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'PrepareList',
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      boundary: null,
    }
  },
  mounted() {
    this.boundary = this.$parent.$el
  },
}
</script>
