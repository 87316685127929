<template>
  <el-dialog :visible.sync="modalShow" :close-on-click-modal="false" :width="modalWidth" top="5px" append-to-body>
    <template #title>
      <h3 class="m-0">
        {{ actionText + '產品' }}
      </h3>
    </template>
    <el-form
      :ref="formRefs"
      :model="row"
      :rules="rules"
      :disabled="!$store.getters['permission/check']([17])"
      label-width="120px"
    >
      <div class="card">
        <div class="card-body p-3">
          <div class="form-row">
            <el-form-item class="col-md-6 col-lg-4" label="客戶" prop="customer_id">
              <el-select
                v-model.number="row.customer_id"
                :disabled="formAction !== 'create'"
                placeholder="- 請選擇客戶 -"
                filterable
                @change="onCustomerChange"
              >
                <el-option
                  v-for="item in $store.getters['customer/consumers']"
                  :key="item.id"
                  :label="item.name"
                  :value="Number(item.id)"
                />
              </el-select>
            </el-form-item>
            <div class="w-100"></div>
            <el-form-item class="col-md-6 col-lg-4" label="產品編號" prop="alias_id">
              <el-input v-model.trim="row.alias_id" placeholder="請輸入產品編號" />
            </el-form-item>
            <el-form-item class="col-md-6 col-lg-8" label="產品名稱" prop="name">
              <el-input v-model.trim="row.name" placeholder="請輸入產品名稱" />
            </el-form-item>
            <div class="w-100"></div>
            <el-form-item
              v-if="$store.getters['permission/check']([1])"
              class="col-md-6 col-lg-4"
              label="產品單價"
              prop="unit_price"
            >
              <el-input
                v-model.number="row.unit_price"
                :min="0"
                type="number"
                placeholder="產品單價"
                @change="onPriceChange"
              >
                <template #prepend>
                  {{ row.customer?.currency | currencySymbol }}
                </template>
              </el-input>
            </el-form-item>
            <el-form-item
              v-show="reason"
              :rules="{ required: reason, message: '請輸入產品單價變更原因', trigger: 'blur' }"
              class="col-md-6 col-lg-4"
              label="變更原因"
              prop="reason"
            >
              <el-input v-model.trim="row.reason" placeholder="產品單價變更原因" />
            </el-form-item>
            <el-form-item class="col-md-6 col-lg-4" label="單位重量" prop="unit_weight">
              <el-input v-model.number="row.unit_weight" :min="0" type="number" placeholder="單位重量">
                <template #append>
                  <el-select v-model.number="row.measure">
                    <el-option
                      v-for="(item, index) in measureTypes"
                      :key="index"
                      :label="item"
                      :value="Number(index)"
                    />
                  </el-select>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item class="col-md-6 col-lg-4" label="預設倉儲" prop="warehouse_id">
              <el-select v-model.number="row.warehouse_id" placeholder="- 請選擇預設倉儲 -" filterable>
                <el-option
                  v-for="item in $store.getters['warehouse/product']"
                  :key="item.id"
                  :label="item.name"
                  :value="Number(item.id)"
                />
              </el-select>
            </el-form-item>
            <el-form-item class="col-md-6 col-lg-4" label="送貨方式" prop="delivery_method">
              <el-select v-model.number="row.delivery_method" placeholder="- 請選擇送貨方式 -" filterable>
                <el-option v-for="(item, index) in deliveryMethods" :key="index" :label="item" :value="Number(index)" />
              </el-select>
            </el-form-item>
            <el-form-item class="col-md-6 col-lg-4" label="安全庫存" prop="safety_stock">
              <el-input v-model.number="row.safety_stock" :min="0" type="number" placeholder="安全庫存">
                <template #append> 個 </template>
              </el-input>
            </el-form-item>
            <div class="w-100"></div>
            <el-form-item class="col" label="備註" prop="comment">
              <el-input v-model.trim="row.comment" placeholder="備註" />
            </el-form-item>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col text-right">
          <b-btn variant="secondary" @click.prevent="modalShow = false">
            <fa icon="times-circle" fixed-width />取消
          </b-btn>
          <b-btn type="submit" variant="primary" @click.prevent="onSubmit">
            <fa icon="edit" fixed-width />{{ actionText }}
          </b-btn>
        </div>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import { measureTypes, deliveryMethods } from '@/plugins/consts'
import { modify } from '@/plugins/mixins'

export default {
  name: 'ProductSetting',
  mixins: [modify],
  props: {
    customerId: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {
      structure: {
        id: null,
        customer_id: null,
        alias_id: null,
        name: null,
        cost: 0,
        unit_price: null,
        unit_weight: null,
        measure: 1,
        quantity: 0,
        safety_stock: 0,
        comment: null,
        reason: null,
        customer: {
          currency: 0,
        },
      },
      rules: {
        customer_id: [{ required: true, message: '請選擇客戶', trigger: 'change' }],
        alias_id: [
          { required: true, message: '請輸入產品編號', trigger: 'blur' },
          { validator: this.doCheckAliasId, trigger: 'blur' },
        ],
        name: [{ required: true, message: '請輸入產品名稱', trigger: 'blur' }],
        unit_price: [{ required: true, type: 'number', min: 0, message: '請輸入產品單價', trigger: 'change' }],
        unit_weight: [{ required: true, type: 'number', min: 0, message: '請輸入產品單重', trigger: 'blur' }],
        safety_stock: [{ type: 'number', min: 0, message: '請確認安全庫存', trigger: 'blur' }],
        warehouse_id: [{ required: true, message: '請選擇預設倉儲', trigger: 'change' }],
        delivery_method: [{ required: true, message: '請選擇預設送貨方式', trigger: 'change' }],
      },
      unitPrice: null,
      reason: false,
      measureTypes,
      deliveryMethods,
    }
  },
  watch: {
    customerId: {
      handler(n) {
        this.$set(this.row, 'customer_id', n)
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('product', ['fetchOne', 'doCreate', 'doUpdate', 'checkUnique']),
    async doCheckAliasId(rule, value, callback) {
      if (this.formAction === 'update' || !this.row.customer_id) {
        callback()
      } else {
        const result = await this.checkUnique({ alias_id: value, customer_id: this.row.customer_id })
        if (result !== false) {
          let error = [`編號爲 ${result} 的產品已存在，請重新確認`]
          callback(error)
        }
        callback()
      }
    },
    onAfterCreate() {
      this.reason = false
      this.unitPrice = null
    },
    onAfterUpdate(result) {
      this.reason = false
      this.unitPrice = result.unit_price
    },
    onCustomerChange(val) {
      this.$set(
        this.row,
        'customer',
        this.$store.getters['customer/all'].find((item) => item.id == +val)
      )
    },
    onPriceChange() {
      if (this.formAction !== 'create') {
        this.reason = this.unitPrice !== this.row.unit_price
      }
    },
  },
}
</script>
