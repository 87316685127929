<template>
  <div>
    <el-dialog :visible.sync="modalShow" :close-on-click-modal="false" :width="modalWidth" top="5px" append-to-body>
      <template #title>
        <h3 class="m-0">重工處理</h3>
      </template>
      <el-form v-if="modalShow" :model="row" label-width="120px">
        <div class="card">
          <div class="card-header">
            <h5 class="m-0">
              重工編號：<span class="text-monospace">{{ row.sno }}</span>
            </h5>
          </div>
          <div class="card-body p-3">
            <div class="form-row">
              <el-form-item class="col-md-6 col-lg-4" label="客戶名稱">
                <h6>{{ row.customer && row.customer.name }}</h6>
              </el-form-item>
              <el-form-item class="col-md-6 col-lg-4" label="訂單編號">
                <h6 class="text-monospace">{{ row.order && row.order.sno }}</h6>
              </el-form-item>
              <el-form-item class="col-md-6 col-lg-4" label="對象編號">
                <h6 class="text-monospace">{{ row.targetable && row.targetable.sno }}</h6>
              </el-form-item>
              <div class="w-100"></div>
              <el-form-item class="col-12" label="產品名稱">
                <h6>{{ row.product && `(${row.product.alias_id}) ${row.product.name}` }}</h6>
              </el-form-item>
              <el-form-item class="col-md-6 col-lg-4" label="入庫日期">
                <h6>{{ row.date }}</h6>
              </el-form-item>
              <el-form-item class="col-md-6 col-lg-4" label="入庫倉儲">
                <h6>{{ row.warehouse && row.warehouse.name }}</h6>
              </el-form-item>
              <el-form-item class="col-md-6 col-lg-4" label="重工數量">
                <h6>{{ row.quantity }} 個</h6>
              </el-form-item>
              <el-form-item class="col-md-6 col-lg-4" label="已完成數量">
                <h6>{{ (row.finish_quantity && row.finish_quantity) || 0 }} 個</h6>
              </el-form-item>
              <el-form-item class="col-md-6 col-lg-4" label="已報廢數量">
                <h6>{{ (row.scrap_quantity && row.scrap_quantity) || 0 }} 個</h6>
              </el-form-item>
              <el-form-item class="col" label="備註">
                <h6>{{ row.comment }}</h6>
              </el-form-item>
            </div>
          </div>
        </div>
        <h4 class="d-flex mt-3">
          <span>重工批次</span>
          <span class="ml-auto">
            <b-btn size="sm" variant="success" @click.prevent="$refs.reworkItemSetting.init()">
              <fa icon="plus-circle" fixed-width />新增
            </b-btn>
          </span>
        </h4>
        <table v-if="row.items.length > 0" class="table table-bordered table-striped">
          <thead>
            <tr>
              <th style="width: 150px">批次編號</th>
              <th style="width: 120px">日期</th>
              <th style="width: 80px">承辦人</th>
              <th class="text-right" style="width: 120px">重工完成數量</th>
              <th style="width: 100px">入庫倉儲</th>
              <th class="text-right" style="width: 120px">報廢數量</th>
              <th style="width: 100px">報廢倉儲</th>
              <th>備註</th>
              <th style="width: 90px">操作</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in row.items" :key="item.id" class="vm">
              <td class="text-monospace">{{ item.sno }}</td>
              <td>{{ item.date }}</td>
              <td>{{ item.user && item.user.name }}</td>
              <td class="text-right">{{ item.finish_quantity }}</td>
              <td>{{ item.prepare && item.prepare.warehouse.name }}</td>
              <td class="text-right">{{ item.scrap_quantity }}</td>
              <td>{{ item.scrap && item.scrap.warehouse.name }}</td>
              <td>{{ item.comment }}</td>
              <td>
                <b-btn-group>
                  <b-btn size="sm" variant="outline-dark" @click.prevent="$refs.reworkItemSetting.init(item.id)">
                    <fa icon="cog" fixed-width />
                  </b-btn>
                  <b-btn size="sm" variant="danger" @click.prevent="onDelete(item.id)">
                    <fa icon="trash-alt" fixed-width />
                  </b-btn>
                </b-btn-group>
              </td>
            </tr>
          </tbody>
        </table>
        <h6 v-else>尚無重工完成記錄</h6>
        <div class="form-row mt-3">
          <div class="col text-right">
            <b-btn variant="secondary" @click.prevent="modalShow = false">
              <fa icon="times-circle" fixed-width />取消
            </b-btn>
          </div>
        </div>
      </el-form>
    </el-dialog>
    <rework-item-setting ref="reworkItemSetting" :rework-id="row.id" :product="row.product" @done="onDone" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { modalControl } from '@/plugins/mixins'

export default {
  name: `ReworkSetting`,
  mixins: [modalControl],
  data() {
    return {
      id: null,
      row: {
        finish_quantity: null,
        scrap_quantity: null,
        comment: null,
        shipment_return: {
          sno: null,
        },
        order: {
          sno: null,
        },
        product: {
          alias_id: null,
          name: null,
          warehouse_id: null,
        },
        customer: {
          name: null,
        },
        items: [],
      },
    }
  },
  methods: {
    ...mapActions(`rework`, ['fetchOne']),
    ...mapActions(`reworkItem`, ['doDelete']),
    init(id) {
      this.id = +id
      this.fetchOne(this.id).then((result) => {
        this.$set(this.$data, 'row', result)
        this.modalShow = true
      })
    },
    onDelete(id, text = '刪除') {
      this.$confirm(`是否確定${text}？`, '注意', {
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      })
        .then(() => {
          this.doDelete(id).then(() => {
            this.$message.success(`${text}完成`)
            this.onDone()
          })
        })
        .catch(() => {
          this.$message.info(`已取消`)
        })
    },
    onDone() {
      this.isDirty = true
      this.init(this.row.id)
    },
  },
}
</script>
