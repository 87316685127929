<template>
  <div>
    <el-dialog :visible.sync="modalShow" :close-on-click-modal="false" :width="modalWidth" top="5px" append-to-body>
      <template #title>
        <h3 class="m-0">配件設定</h3>
      </template>
      <el-form v-if="modalShow" :ref="formRefs" :model="row" :rules="rules" label-width="120px">
        <div class="card">
          <div class="card-body p-3">
            <div v-show="!row.is_combined" class="form-row">
              <el-form-item
                :rules="{ required: !row.is_combined, message: '請輸入配件編號', trigger: 'blur' }"
                class="col-12 col-lg-6"
                label="配件編號"
                prop="alias_id"
              >
                <el-input v-model.trim="row.alias_id" placeholder="請輸入配件編號" />
              </el-form-item>
              <el-form-item
                :rules="{ required: !row.is_combined, message: '請輸入配件名稱', trigger: 'blur' }"
                class="col-12 col-lg-6"
                label="配件名稱"
                prop="name"
              >
                <el-input v-model.trim="row.name" placeholder="請輸入配件名稱" />
              </el-form-item>
            </div>
            <div v-show="row.is_combined" class="form-row">
              <el-form-item
                :rules="{ required: row.is_combined, message: '請選擇配件進行組合', trigger: 'change' }"
                class="col-12"
                label="組合配件"
                prop="parent_id"
              >
                <el-select v-model="row.parent_id" placeholder="請選擇配件" multiple>
                  <el-option v-for="par in parents" :key="par.id" :label="par.name" :value="Number(par.id)" />
                </el-select>
              </el-form-item>
            </div>
            <div class="form-row">
              <el-form-item
                v-if="!row.is_combined"
                :rules="{ required: !row.is_combined, message: '請選擇材料', trigger: 'change' }"
                class="col-12 col-lg-6"
                label="材料"
                prop="material_id"
              >
                <material-select v-model="row.material_id" style="width: calc(100% - 40px)" @input="onChangeMaterial" />
                <b-btn class="float-right" variant="success" size="sm" @click.prevent="$emit('add-material')">
                  <fa icon="plus-circle" fixed-width />
                </b-btn>
              </el-form-item>
              <div class="w-100"></div>
              <el-form-item class="col-md-6 col-lg-4" label="是否開模" prop="is_mold">
                <el-switch
                  v-model.number="row.is_mold"
                  :active-value="1"
                  :inactive-value="0"
                  active-text="是"
                  inactive-text="否"
                  @change="row.material_to_quantity = 1"
                />
              </el-form-item>
              <el-form-item
                v-if="!row.is_combined"
                :rules="{
                  required: !row.is_combined,
                  type: 'number',
                  min: 1,
                  message: '請輸入材料單數',
                  trigger: 'change',
                }"
                class="col-md-6 col-lg-4"
                label="材料單數"
                prop="material_to_quantity"
              >
                <el-input
                  v-model.number="row.material_to_quantity"
                  type="number"
                  :min="1"
                  :disabled="!row.is_mold"
                  placeholder="材料單數"
                >
                  <template #append>個</template>
                </el-input>
              </el-form-item>
              <el-form-item class="col-md-6 col-lg-4" label="組合用量" prop="usage">
                <el-input v-model.number="row.usage" type="number" :min="1" placeholder="組合用量">
                  <template #append>個</template>
                </el-input>
              </el-form-item>
              <el-form-item class="col-md-6 col-lg-4" label="單位重量" prop="unit_weight">
                <el-input v-model.number="row.unit_weight" :min="0" type="number" placeholder="單位重量">
                  <template #append>
                    <el-select v-model.number="row.measure">
                      <el-option
                        v-for="(item, index) in measureTypes"
                        :key="index"
                        :label="item"
                        :value="Number(index)"
                      />
                    </el-select>
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item class="col-md-6 col-lg-4" label="預設倉儲" prop="warehouse_id">
                <el-select v-model.number="row.warehouse_id" placeholder="- 請選擇預設倉儲 -" filterable>
                  <el-option
                    v-for="item in $store.getters['warehouse/part']"
                    :key="item.id"
                    :label="item.name"
                    :value="Number(item.id)"
                  />
                </el-select>
              </el-form-item>
              <el-form-item class="col-md-6 col-lg-4" label="安全庫存" prop="safety_stock">
                <el-input v-model.number="row.safety_stock" :min="0" type="number" placeholder="安全庫存">
                  <template #append>個</template>
                </el-input>
              </el-form-item>
            </div>
          </div>
        </div>
        <h4 class="mt-3">生產製程<span class="text-muted">（拖曳表格橫列進行排序）</span></h4>
        <table class="table table-bordered table-striped table-sm m-0">
          <thead>
            <tr>
              <th class="text-center" style="width: 50px">拖曳</th>
              <th style="width: 250px">製程名稱</th>
              <th style="width: 250px">預設加工廠商</th>
              <th>備註</th>
              <th style="width: 50px">
                <b-btn size="sm" variant="success" @click.prevent="onAddProcess">
                  <fa icon="plus-circle" fixed-width />
                </b-btn>
              </th>
            </tr>
          </thead>
          <draggable v-model="row.processes" ghost-class="ghost" :move="onMoveProcess" tag="tbody">
            <tr v-for="(proc, index3) in row.processes" :key="index3" class="vm">
              <td class="text-center"><fa icon="arrows-alt" fixed-width /></td>
              <td>
                <el-form-item
                  :prop="'processes[' + index3 + '].default_process_id'"
                  :rules="{ required: true, message: ' ', trigger: 'change' }"
                  label-width="0px"
                  class="m-0 p-0"
                >
                  <el-select
                    v-model.number="proc.default_process_id"
                    size="small"
                    placeholder="- 請選擇製程 -"
                    filterable
                    @change="proc.vendor_id = null"
                  >
                    <el-option-group
                      v-for="(group, index4) in $store.getters['defaultProcess/group']"
                      :key="index4"
                      :label="defaultProcessTypes[index4]"
                    >
                      <el-option v-for="item2 in group" :key="item2.id" :label="item2.name" :value="Number(item2.id)" />
                    </el-option-group>
                  </el-select>
                </el-form-item>
              </td>
              <td>
                <el-form-item :prop="'processes[' + index3 + '].vendor_id'" label-width="0px" class="m-0 p-0">
                  <el-select v-model.number="proc.vendor_id" size="small" placeholder="- 請選擇預設廠商 -" filterable>
                    <el-option
                      v-for="item3 in getVendors(proc.default_process_id, proc)"
                      :key="item3.id"
                      :label="item3.name"
                      :value="Number(item3.id)"
                    />
                  </el-select>
                </el-form-item>
              </td>
              <td>
                <el-form-item :prop="'processes[' + index3 + '].comment'" label-width="0px" class="m-0 p-0">
                  <el-input v-model="proc.comment" size="small" placeholder="備註" />
                </el-form-item>
              </td>
              <td>
                <b-btn size="sm" variant="danger" @click.prevent="onRemoveProcess(index3)">
                  <fa icon="trash-alt" fixed-width />
                </b-btn>
              </td>
            </tr>
          </draggable>
        </table>
        <div class="form-row mt-3">
          <el-form-item class="col" label="圖面" label-width="50px">
            <image-upload :image-list="drawingList" @drawing-change="onChangeDrawing" />
          </el-form-item>
        </div>
        <div v-show="row.new_drawings && row.new_drawings.length > 0" class="form-row">
          <el-form-item
            :rules="{
              required: row.new_drawings && row.new_drawings.length > 0,
              message: '請輸入圖面變更原因',
              trigger: 'blur',
            }"
            class="col col-lg-6"
            label="圖面變更原因"
            label-width="120px"
            prop="reason"
          >
            <el-input v-model="row.reason" placeholder="圖面變更原因" />
          </el-form-item>
        </div>
        <div class="form-row mt-3">
          <div class="col text-right">
            <b-btn variant="secondary" @click.prevent="modalShow = false">
              <fa icon="times-circle" fixed-width />取消
            </b-btn>
            <b-btn type="submit" variant="primary" @click.prevent="onSubmit">
              <fa icon="edit" fixed-width />{{ actionText }}
            </b-btn>
          </div>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { measureTypes, defaultProcessTypes } from '@/plugins/consts'
import { modify } from '@/plugins/mixins'
import Draggable from 'vuedraggable'

export default {
  name: 'PartSetting',
  components: { Draggable },
  mixins: [modify],
  props: {
    product: {
      type: Object,
      default() {
        return {
          id: null,
          parts: [],
        }
      },
    },
  },
  data() {
    return {
      structure: {
        alias_id: null,
        name: null,
        product_id: this.product.id,
        parent_id: [],
        child_id: null,
        material_id: null,
        warehouse_id: null,
        material_to_quantity: 1,
        is_combined: 0,
        is_mold: 0,
        quantity: [],
        measure: 1,
        unit_weight: 0,
        usage: 1,
        safety_stock: 0,
        processes: [{ part_id: null, default_process_id: 16, vendor_id: null, comment: null }],
      },
      rules: {
        is_mold: [{ required: true, message: '請選擇是否開模', trigger: 'change' }],
        usage: [{ required: true, type: 'number', min: 1, message: '請輸入組合用量', trigger: 'change' }],
        unit_weight: [{ required: true, type: 'number', min: 0, message: '請輸入單位重量', trigger: 'change' }],
        safety_stock: [{ required: false, type: 'number', min: 0, message: '請輸入安全庫存', trigger: 'change' }],
        warehouse_id: [{ required: true, message: '請選擇預設倉儲', trigger: 'change' }],
      },
      drawingList: [],
      parents: [],
      measureTypes,
      defaultProcessTypes,
    }
  },
  watch: {
    'product.id': {
      handler(n) {
        this.$set(this.structure, 'product_id', n)
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('part', ['fetchOne', 'doCreate', 'doUpdate']),
    init(isCombined, id = null) {
      const action = id !== null ? this.onUpdate : this.onCreate
      action(isCombined, id)
    },
    onCreate(isCombined) {
      this.formAction = 'create'
      this.parents = []
      this.drawingList = []
      this.resetRow()

      if (isCombined) {
        // 檢查現有配件
        this.parents = this.product.parts.filter((part) => {
          return part.child_id === null
        })

        if (this.parents.length < 2) {
          this.$message.error('已無配件可以組合')
          return false
        }

        this.row.processes[0].default_process_id = this.$store.getters['defaultProcess/combine'][0].id
        this.row.is_combined = 1
      }

      this.modalShow = true
    },
    getVendors(id, process) {
      if (+id === 16 || id === null) {
        return []
      }
      const customers = this.$store.getters['defaultProcess/all'].find((x) => x.id === +id)?.customers
      if (customers.length === 1) {
        process.vendor_id = customers[0].id
      }

      return customers
    },
    onUpdate(isCombined, id) {
      this.fetchOne(id).then((result) => {
        this.formAction = 'edit'
        this.parents = []
        result.parent_id = result.parent_id.map(Number)
        result.new_drawings = []
        result.reason = ''
        this.$set(this.$data, 'row', result)
        this.drawingList =
          result.drawings[0]?.images?.map((img) => {
            return { name: img.split('/').pop(), url: img }
          }) || []

        if (this.row.is_combined) {
          this.parents = this.product.parts.filter((p) => {
            return (
              this.row.parent_id.includes(+p.id) ||
              (p.child_id === null && p.id !== this.row.id && p.id !== this.row.child_id)
            )
          })
        }

        this.modalShow = true
      })
    },
    onChangeMaterial(id) {
      id = +id
      if (id < 1) {
        return false
      }

      this.row.material_id = id
      const material = this.$store.getters['material/all'].find((x) => x.id == id)
      this.row.is_mold = material.is_plate
      if (!material.is_plate) {
        this.row.material_to_quantity = 1
      }
    },
    onChangeDrawing(drawings) {
      this.drawingList = drawings
      this.row.new_drawings = drawings
    },
    onMoveProcess({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element
      const draggedElement = draggedContext.element
      return (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
    },
    onAddProcess() {
      this.row.processes.push({
        part_id: this.row.id,
        default_process_id: null,
        vendor_id: null,
        comment: null,
      })
    },
    onRemoveProcess(index) {
      this.row.processes.splice(index, 1)
      if (this.row.processes.length === 0) {
        this.row.processes.push({
          part_id: this.row.id,
          default_process_id: 16,
          vendor_id: null,
          comment: null,
        })
      }
    },
  },
}
</script>
