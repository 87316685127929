<template>
  <div>
    <el-dialog :visible.sync="modalShow" :close-on-click-modal="false" :width="modalWidth" top="5px" append-to-body>
      <template #title>
        <h3 class="m-0">
          {{ `${actionText}${row.name}權限` }}
        </h3>
      </template>
      <el-form
        v-if="modalShow"
        :ref="formRefs"
        :model="row"
        :rules="rules"
        :disabled="!$store.getters['permission/check']([71])"
      >
        <div class="card">
          <div class="card-body p-3">
            <div class="form-row">
              <el-tree
                ref="tree"
                :data="tree"
                :props="{ children: 'children', label: 'name' }"
                :default-checked-keys="checked"
                :default-expanded-keys="expanded"
                show-checkbox
                node-key="id"
                highlight-current
                :render-after-expand="false"
                @check-change="onCheckChange"
              />
            </div>
          </div>
        </div>
        <div class="form-row mt-3">
          <div class="col text-right">
            <b-btn variant="secondary" @click.prevent="modalShow = false">
              <fa icon="times-circle" fixed-width />取消
            </b-btn>
            <b-btn
              v-if="$store.getters['permission/check']([71])"
              type="submit"
              variant="primary"
              @click.prevent="onSubmit"
            >
              <fa icon="edit" fixed-width />{{ actionText }}
            </b-btn>
          </div>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { modify } from '@/plugins/mixins'
import { colors } from '@/plugins/consts'
import { forEach, groupBy } from 'lodash-es'

export default {
  name: `PermissionSetting`,
  mixins: [modify],
  data() {
    return {
      structure: {
        setting: [],
      },
      tree: [{ id: 1, name: null }],
      checked: [],
      expanded: [],
      rules: {},
      colors,
      modalMaxWidth: 500,
    }
  },
  methods: {
    ...mapActions(`role`, { fetchOne: 'fetchOne', doUpdate: 'syncPermission' }),
    resetRow() {
      this.$set(this.$data, 'row', JSON.parse(JSON.stringify(this.structure)))
      this.$set(this.$data, 'tree', [{ id: 1, name: null }])
      this.$set(this.$data, 'checked', [])
      this.$set(this.$data, 'expanded', [])
    },
    onAfterUpdate(result) {
      const setting = groupBy(result.setting, (s) => s.name.split('.')[0])
      let checked = []
      let tree = []
      let expanded = []
      let i = 100000
      forEach(setting, (v, k) => {
        let j = 0
        forEach(v, (child) => {
          child.name = child.name.split('.')[1]
          if (child.status) {
            checked.push(child.id)
            j++
          }
        })

        if (j === v.length) {
          checked.push(i)
        }

        if (j > 0) {
          expanded.push(`${i}`)
        }

        tree.push({
          id: `${i}`,
          name: k,
          status: j === v.length ? 1 : 0,
          children: v,
        })
        i++
      })
      this.$set(this.$data, 'tree', tree)
      this.$set(this.$data, 'checked', checked)
      this.$set(this.$data, 'expanded', expanded)
    },
    onBeforeSubmit() {
      let permissions = []
      forEach(this.tree, (tree) => {
        forEach(tree.children, (child) => {
          if (child.status || tree.status) {
            permissions.push(child)
          }
        })
      })
      this.$set(this.row, 'permissions', permissions)
      return true
    },
    onCheckChange(tree, status) {
      tree.status = +status
    },
  },
}
</script>
