<template>
  <el-dialog :visible.sync="modalShow" :close-on-click-modal="false" :width="modalWidth" top="5px" append-to-body>
    <template #title>
      <h3 class="m-0">
        {{ actionText + '進貨' }}
      </h3>
    </template>
    <el-form
      v-if="modalShow"
      :ref="formRefs"
      :model="row"
      :rules="rules"
      :disabled="!$store.getters['permission/check']([39])"
      label-width="120px"
    >
      <div class="card">
        <div v-if="row.sno" class="card-header px-2">
          <h5 class="m-0">
            進貨編號：<span class="text-monospace">{{ row.sno }}</span>
          </h5>
        </div>
        <div class="card-body p-3">
          <div class="form-row">
            <el-form-item class="col-md-6 col-lg-4" label="採購編號">
              <h6>{{ row.purchase && row.purchase.sno }}</h6>
            </el-form-item>
            <el-form-item class="col-md-6 col-lg-4" label="採購數量">
              <h6>{{ row.purchase_item && row.purchase_item.quantity }} 個</h6>
            </el-form-item>
            <div class="w-100"></div>
            <el-form-item class="col-md-6 col-lg-4" label="供料商名稱">
              <h6>{{ row.customer && row.customer.name }}</h6>
            </el-form-item>
            <el-form-item class="col" label="材料名稱">
              <h6>{{ row.purchase_item && row.purchase_item.material | materialName }}</h6>
            </el-form-item>
            <div class="w-100"></div>
            <el-form-item class="col-md-6 col-lg-4" label="供料商單號">
              <el-input v-model="row.customer_order_no" placeholder="供料商出貨單號" />
            </el-form-item>
            <el-form-item class="col-md-6 col-lg-4" label="進貨地點" prop="target_id">
              <el-select v-model.number="row.target_id" placeholder="進貨地點" filterable @change="targetChange">
                <el-option
                  v-for="item in $store.getters['customer/outsources']"
                  :key="item.id"
                  :label="item.name"
                  :value="Number(item.id)"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              v-show="row.target_id === 0"
              :rules="{ required: row.target_id === 0, message: '請選擇入庫倉儲', trigger: 'change' }"
              class="col-md-6 col-lg-4"
              label="入庫倉儲"
              prop="warehouse_id"
            >
              <el-select v-model.number="row.warehouse_id" placeholder="入庫倉儲" filterable>
                <el-option
                  v-for="item in $store.getters['warehouse/material']"
                  :key="item.id"
                  :label="item.name"
                  :value="Number(item.id)"
                />
              </el-select>
            </el-form-item>
            <div class="w-100"></div>
            <el-form-item class="col-md-6 col-lg-4" label="進貨日期" prop="date">
              <el-date-picker v-model="row.date" type="date" value-format="yyyy-MM-dd" placeholder="進貨日期" />
            </el-form-item>
            <el-form-item class="col-md-6 col-lg-4" label="進貨數量" prop="quantity">
              <el-input v-model.number="row.quantity" :min="1" type="number" placeholder="進貨數量">
                <template #append> 個 </template>
              </el-input>
            </el-form-item>
            <el-form-item class="col-md-6 col-lg-4" label="單位重量" prop="unit_weight">
              <el-input v-model.number="row.unit_weight" :min="0" type="number" placeholder="單位重量">
                <template #append>
                  <el-select v-model.number="row.measure">
                    <el-option
                      v-for="(item, index) in measureTypes"
                      :key="index"
                      :label="item"
                      :value="Number(index)"
                    />
                  </el-select>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item class="col-md-6 col-lg-4" label="單價" prop="unit_price">
              <el-input v-model.number="row.unit_price" type="number" :min="0" placeholder="單價">
                <template #prepend>
                  {{ row.customer && row.customer.currency | currencySymbol }}
                </template>
              </el-input>
            </el-form-item>
            <el-form-item class="col-md-6 col-lg-4" label="重量計費" prop="is_measure_price">
              <el-switch
                v-model.number="row.is_measure_price"
                :active-value="1"
                :inactive-value="0"
                active-text="是"
                inactive-text="否"
              />
            </el-form-item>
            <el-form-item v-if="$store.getters['permission/check']([1])" class="col-md-6 col-lg-4" label="小記金額">
              <h6>
                {{ subtotal | currency }}
              </h6>
            </el-form-item>
            <el-form-item class="col" label="備註">
              <el-input v-model="row.comment" placeholder="備註" />
            </el-form-item>
          </div>
        </div>
      </div>
      <div class="form-row mt-3">
        <div class="col text-right">
          <b-btn variant="secondary" @click.prevent="modalShow = false">
            <fa icon="times-circle" fixed-width />取消
          </b-btn>
          <b-btn
            v-if="$store.getters['permission/check']([39])"
            type="submit"
            variant="primary"
            @click.prevent="onSubmit"
          >
            <fa icon="edit" fixed-width />{{ actionText }}
          </b-btn>
        </div>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import { measureTypes } from '@/plugins/consts'
import { modify } from '@/plugins/mixins'

export default {
  name: 'ReceiveSetting',
  mixins: [modify],
  props: {
    purchase: {
      type: Object,
      default() {
        return {
          sno: null,
        }
      },
    },
    purchaseItem: {
      type: Object,
      default() {
        return {
          quantity: 0,
        }
      },
    },
    customer: {
      type: Object,
      default() {
        return {
          name: null,
          currency: 0,
        }
      },
    },
  },
  data() {
    return {
      structure: {
        sno: null,
        purchase_item_id: null,
        customer_id: null,
        customer_order_no: null,
        material_id: null,
        target_id: null,
        warehouse_id: null,
        user_id: null,
        date: null,
        quantity: null,
        unit_weight: null,
        measure: null,
        unit_price: null,
        is_measure_price: null,
        comment: null,
        purchase: this.purchase,
        purchase_item: this.purchaseItem,
        customer: this.customer,
      },
      rules: {
        target_id: [{ required: true, message: '請選擇進貨地點', trigger: 'change' }],
        date: [{ required: true, message: '請選擇進貨日期', trigger: 'blur' }],
        quantity: [
          { required: true, message: '請輸入進貨數量', trigger: 'blur' },
          { type: 'number', min: 1, message: '進貨數量最小為 1', trigger: 'blur' },
        ],
        unit_weight: [
          { required: true, message: '請輸入單位重量', trigger: 'blur' },
          { type: 'number', min: 0, message: '單位重量最小為 0', trigger: 'blur' },
        ],
        unit_price: [
          { required: true, message: '請輸入單價', trigger: 'blur' },
          { type: 'number', min: 0, message: '單價最小為 0', trigger: 'blur' },
        ],
        is_measure_price: [{ required: true, message: '請選擇是否以每一單位重量計費', trigger: 'blur' }],
      },
      measureTypes,
    }
  },
  computed: {
    subtotal() {
      if (!this.row.quantity || !this.row.unit_price || !this.row.unit_weight) {
        return
      }

      return this.row.quantity * this.row.unit_price * (this.row.is_measure_price ? this.row.unit_weight : 1)
    },
  },
  watch: {
    purchase: {
      handler(n) {
        this.$set(this.structure, 'purchase', n)
        this.$set(this.structure, 'customer_id', n.customer_id)
      },
      immediate: true,
    },
    purchaseItem: {
      handler(n) {
        this.$set(this.structure, 'purchase_item', n)
        this.$set(this.structure, 'purchase_item_id', n.id)
        this.$set(this.structure, 'material_id', n.material_id)
        this.$set(this.structure, 'target_id', n.target_id)
        this.$set(this.structure, 'warehouse_id', n.warehouse_id)
        this.$set(this.structure, 'unit_weight', n.material?.unit_weight || null)
        this.$set(this.structure, 'measure', n.material?.measure)
        this.$set(
          this.structure,
          'unit_price',
          n.receives?.length > 0 ? n.receives[n.receives.length - 1]?.unit_price : null
        )
      },
      immediate: true,
    },
    customer: {
      handler(n) {
        this.$set(this.structure, 'customer', n)
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('receive', ['fetchOne', 'doCreate', 'doUpdate']),
    targetChange(val) {
      if (val !== 0) {
        this.row.warehouse_id = null
      }
    },
    onEmit() {
      this.$emit('done', this.row.purchase_item)
    },
  },
}
</script>
