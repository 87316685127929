<template>
  <el-dialog :visible.sync="modalShow" :close-on-click-modal="false" :width="modalWidth" top="5px" append-to-body>
    <template #title>
      <h3 class="m-0">
        {{ `${actionText}材料種類` }}
      </h3>
    </template>
    <el-form
      v-if="modalShow"
      :ref="formRefs"
      :model="row"
      :rules="rules"
      :disabled="!$store.getters['permission/check']([87])"
      label-width="120px"
    >
      <div class="card">
        <div class="card-body p-3">
          <div class="form-row">
            <el-form-item class="col-md-6" label="材料種類" prop="name">
              <el-input v-model.trim="row.name" placeholder="材料種類" />
            </el-form-item>
            <el-form-item class="col-md-6" label="是否爲板材" prop="is_plate">
              <el-switch
                v-model.number="row.is_plate"
                :width="50"
                :active-value="1"
                :inactive-value="0"
                active-text="是"
                inactive-text="否"
              />
            </el-form-item>
            <el-form-item class="col-12" label="備註" prop="comment">
              <el-input v-model.trim="row.comment" placeholder="備註" />
            </el-form-item>
          </div>
        </div>
      </div>
      <div class="form-row mt-3">
        <div class="col text-right">
          <b-btn variant="secondary" @click.prevent="modalShow = false">
            <fa icon="times-circle" fixed-width />取消
          </b-btn>
          <b-btn
            v-if="$store.getters['permission/check']([87])"
            type="submit"
            variant="primary"
            @click.prevent="onSubmit"
          >
            <fa icon="edit" fixed-width />{{ actionText }}
          </b-btn>
        </div>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import { modify, unique } from '@/plugins/mixins'

export default {
  name: 'MaterialTypeSetting',
  mixins: [modify, unique],
  data() {
    return {
      structure: {
        id: null,
        is_plate: 0,
        name: null,
        comment: null,
      },
      rules: {
        name: [
          { required: true, message: '請輸入材料種類', trigger: 'blur' },
          { validator: this.doCheckUnique, message: '種類重複，請確認材料種類名稱', trigger: 'blur' },
        ],
        is_plate: [{ required: true, message: '請選擇是否為板材', trigger: 'change' }],
      },
      modalMaxWidth: 800,
    }
  },
  methods: {
    ...mapActions('materialType', ['fetchOne', 'doCreate', 'doUpdate', 'checkUnique']),
  },
}
</script>
