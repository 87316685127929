<template>
  <div>
    <el-dialog :visible.sync="modalShow" :close-on-click-modal="false" :width="modalWidth" top="5px">
      <template #title>
        <h3 class="m-0">新增包裝入庫</h3>
      </template>
      <el-form v-if="modalShow" :ref="formRefs" :model="row" :rules="rules" label-width="120px">
        <div class="card">
          <div class="card-body p-3">
            <div class="form-row">
              <el-form-item class="col-md-6 col-lg-4" label="製造訂單編號">
                <h6 class="text-monospace m-0">{{ row.sno }}</h6>
              </el-form-item>
              <el-form-item class="col-md-6 col-lg-4" label="客戶名稱">
                <h6 class="text-truncate">{{ row.customer.name }}</h6>
              </el-form-item>
              <el-form-item class="col-md-12 col-lg-8" label="產品名稱">
                <h6>{{ '(' + row.product.alias_id + ') ' + row.product.name }}</h6>
              </el-form-item>
            </div>
            <div class="form-row">
              <el-form-item class="col-md-6 col-lg-4" label="訂貨數量">
                <h6>{{ row.quantity }} 個</h6>
              </el-form-item>
              <el-form-item class="col-md-6 col" label="已入庫數量">
                <h6>{{ row.production_quantity }} 個</h6>
              </el-form-item>
              <el-form-item class="col-md-6 col-lg-4" label="入庫數量" prop="prepare_quantity">
                <el-input v-model.number="row.prepare_quantity" type="number" :min="1" placeholder="入庫數量" autofocus>
                  <template #append>個</template>
                </el-input>
              </el-form-item>
              <el-form-item class="col-md-6 col-lg-4" label="入庫日期" prop="date">
                <el-date-picker v-model="row.date" type="date" value-format="yyyy-MM-dd" placeholder="入庫日期" />
              </el-form-item>
              <el-form-item class="col-md-6 col-lg-4" label="入庫倉儲" prop="warehouse_id">
                <el-select v-model.number="row.warehouse_id" placeholder="- 請選擇倉儲 -" filterable>
                  <el-option
                    v-for="item in $store.getters['warehouse/product']"
                    :key="item.id"
                    :label="item.name"
                    :value="Number(item.id)"
                  />
                </el-select>
              </el-form-item>
            </div>
            <div class="form-row">
              <el-form-item class="col" label="備註" prop="comment">
                <el-input v-model.trim="row.comment" placeholder="備註" />
              </el-form-item>
            </div>
          </div>
        </div>
        <div class="form-row mt-3">
          <div class="col text-right">
            <b-btn variant="secondary" @click.prevent="modalShow = false">
              <fa icon="times-circle" fixed-width />取消
            </b-btn>
            <b-btn type="submit" variant="primary" @click.prevent="onSubmit">
              <fa icon="edit" fixed-width />新增
            </b-btn>
          </div>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { modify } from '@/plugins/mixins'

export default {
  name: 'OrderPrepare',
  mixins: [modify],
  data() {
    return {
      structure: {
        order_id: null,
        sno: null,
        customer: {
          name: null,
        },
        product: {
          alias_id: null,
          name: null,
        },
        quantity: null,
        prepare_quantity: null,
        production_quantity: null,
        warehouse_id: null,
        date: null,
        comment: null,
      },
      rules: {
        prepare_quantity: [{ required: true, message: '請輸入入庫數量', type: 'number', min: 1, trigger: 'blue' }],
        date: [{ required: true, message: '請選擇入庫日期', trigger: 'change' }],
        warehouse_id: [{ required: true, message: '請輸入入庫數量', trigger: 'blue' }],
      },
    }
  },
  methods: {
    ...mapActions('order', ['fetchOne']),
    ...mapActions('prepare', ['doCreate']),
    init(orderId) {
      if (!orderId) {
        return false
      }
      this.fetchOne(orderId).then((result) => {
        this.row = {
          order_id: result.id,
          sno: result.sno,
          customer: result.customer,
          product: result.product,
          production_quantity: result.production_quantity,
          quantity: result.quantity,
          prepare_quantity: null,
          warehouse_id: result.product.warehouse_id,
          date: null,
          comment: null,
        }
        this.modalShow = true
      })
    },
  },
}
</script>
