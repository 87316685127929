<template>
  <div>
    <el-dialog :visible.sync="modalShow" :close-on-click-modal="false" :width="modalWidth" top="5px" append-to-body>
      <template #title>
        <h3 class="m-0">新增出貨</h3>
      </template>
      <el-form v-if="modalShow" :ref="formRefs" :model="row" :rules="rules" label-width="120px">
        <div class="card">
          <div class="card-body p-3">
            <div class="form-row">
              <el-form-item class="col-md-6 col-lg-4" label="製造訂單編號">
                <h6 class="text-monospace m-0">{{ order.sno }}</h6>
              </el-form-item>
              <el-form-item class="col-md-6 col-lg-4" label="客戶名稱">
                <h6 class="text-truncate">{{ order.customer && order.customer.name }}</h6>
              </el-form-item>
              <div class="w-100"></div>
              <el-form-item class="col-md-6 col-lg-4" label="產品名稱">
                <h6>{{ '(' + order.product.alias_id + ') ' + order.product.name }}</h6>
              </el-form-item>
              <el-form-item class="col-md-6 col-lg-4" label="訂貨數量">
                <h6>{{ order.quantity }} 個</h6>
              </el-form-item>
              <el-form-item class="col-md-6 col-lg-4" label="已出貨數量">
                <h6>{{ order.shipment_quantity }} 個</h6>
              </el-form-item>
              <div class="w-100"></div>
              <el-form-item class="col-md-6 col-lg-4" label="種類" prop="type">
                <el-radio-group v-model="row.type">
                  <el-radio v-for="(label, index) in shipmentType" :key="index" :label="+index">{{ label }}</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item class="col-md-6 col-lg-4" label="送貨日期" prop="date">
                <el-date-picker v-model="row.date" type="date" value-format="yyyy-MM-dd" placeholder="送貨日期" />
              </el-form-item>
              <el-form-item class="col-md-6 col-lg-4" label="送貨方式" prop="delivery_method">
                <el-select v-model.number="row.delivery_method" placeholder="送貨方式">
                  <el-option
                    v-for="(item, index) in deliveryMethods"
                    :key="index"
                    :label="item"
                    :value="Number(index)"
                  />
                </el-select>
              </el-form-item>
              <el-form-item class="col" label="備註" prop="comment">
                <el-input v-model.trim="row.comment" placeholder="備註" />
              </el-form-item>
            </div>
          </div>
        </div>
        <h4 class="mt-3">產品現有庫存</h4>
        <table class="table table-bordered table-striped table-sm">
          <thead>
            <tr>
              <th style="width: 25%">倉儲名稱</th>
              <th class="text-right" style="width: 25%">庫存數量</th>
              <th style="width: 25%">出貨數量</th>
              <th>樣品數量</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(quantity, index) in row.quantity" :key="index" class="vm">
              <td>{{ $store.getters['warehouse/name'](quantity.id) }}</td>
              <td class="text-right">{{ quantity.qty }}</td>
              <td>
                <el-form-item
                  :rules="{
                    required: shipmentQuantity,
                    type: 'integer',
                    min: 1,
                    max: quantity.qty,
                    message: ' ',
                    trigger: 'change',
                  }"
                  :prop="`quantity[${index}].shipment`"
                  class="m-0 p-0"
                  label-width="0"
                >
                  <el-input
                    v-model.number="quantity.shipment"
                    :min="1"
                    :max="quantity.qty"
                    :disabled="quantity.qty < 1"
                    type="number"
                    placeholder="出貨數量"
                    size="small"
                  >
                    <template #append>個</template>
                  </el-input>
                </el-form-item>
              </td>
              <td>
                <el-form-item
                  :rules="{
                    required: shipmentQuantity,
                    type: 'integer',
                    min: 0,
                    max: quantity.qty,
                    message: ' ',
                    trigger: 'change',
                  }"
                  :prop="`quantity[${index}].sample_quantity`"
                  class="m-0 p-0"
                  label-width="0"
                >
                  <el-input
                    v-model.number="quantity.sample_quantity"
                    :min="0"
                    :max="quantity.qty"
                    :disabled="quantity.qty < 1"
                    type="number"
                    placeholder="樣品數量"
                    size="small"
                  >
                    <template #append>個</template>
                  </el-input>
                </el-form-item>
              </td>
            </tr>
          </tbody>
        </table>

        <div v-if="order.shipments.length > 0" class="mt-3">
          <h4>已出貨記錄</h4>
          <table class="table table-bordered table-striped table-sm">
            <thead>
              <tr>
                <th style="width: 145px">出貨編號</th>
                <th style="width: 110px">出貨日期</th>
                <th style="width: 85px">種類</th>
                <th style="width: 110px">出貨倉儲</th>
                <th class="text-center" style="width: 90px">送貨方式</th>
                <th class="text-right" style="width: 100px">出貨數量</th>
                <th class="text-right" style="width: 100px">樣品數量</th>
                <th style="width: 90px">承辦人</th>
                <th>備註</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="shipment in order.shipments" :key="shipment.id">
                <td class="text-monospace">{{ shipment.sno }}</td>
                <td>{{ shipment.date }}</td>
                <td class="text-center">
                  <b-badge :variant="colors[shipment.type]">{{ shipmentType[shipment.type] }}</b-badge>
                </td>
                <td>{{ $store.getters['warehouse/name'](shipment.warehouse_id) }}</td>
                <td class="text-center">
                  <b-badge :variant="colors[shipment.delivery_method + 1]">{{
                    deliveryMethods[shipment.delivery_method]
                  }}</b-badge>
                </td>
                <td class="text-right">{{ shipment.quantity }}</td>
                <td class="text-right">{{ shipment.sample_quantity }}</td>
                <td>{{ shipment.user && shipment.user.name }}</td>
                <td>{{ shipment.comment }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="form-row mt-3">
          <div class="col text-right">
            <b-btn variant="secondary" @click.prevent="modalShow = false">
              <fa icon="times-circle" fixed-width />取消
            </b-btn>
            <b-btn type="submit" variant="primary" @click.prevent="onSubmit">
              <fa icon="edit" fixed-width />新增
            </b-btn>
          </div>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { modify } from '@/plugins/mixins'
import { colors, deliveryMethods, shipmentType } from '@/plugins/consts'
import { sumBy } from 'lodash-es'

export default {
  name: 'OrderShip',
  mixins: [modify],
  data() {
    return {
      order: {
        product: {
          qutntity: [],
        },
        shipments: [],
      },
      structure: {
        quantity: [{ id: null, qty: null }],
      },
      rules: {
        type: [{ required: true, message: '請選擇送貨種類', trigger: 'change' }],
        date: [{ required: true, message: '請選擇送貨日期', trigger: 'blur' }],
        delivery_method: [{ required: true, message: '請選擇送貨日期', trigger: 'blur' }],
      },
      deliveryMethods,
      shipmentType,
      colors,
    }
  },
  computed: {
    shipmentQuantity() {
      let sum = sumBy(this.row.quantity, (q) => Number(q.shipment) || 0)
      return sum < 1
    },
  },
  methods: {
    ...mapActions('order', ['fetchOne']),
    ...mapActions('shipment', ['doCreate']),
    init(orderId) {
      if (!orderId) {
        return false
      }

      this.fetchOne(orderId).then((result) => {
        const rejects = this.$store.getters['warehouse/returns'].map((x) => x.id)
        let quantity = result.product.quantity
          .filter((q) => !rejects.includes(q.id))
          .map((q) => {
            q.shipment = null
            q.sample_quantity = 0
            return q
          })

        this.$set(this.$data, 'order', result)
        this.$set(this.$data, 'row', {
          order_id: result.id,
          quantity: quantity,
          type: 1,
          date: null,
          delivery_method: result.delivery_method,
          comment: null,
        })

        this.modalShow = true
      })
    },
  },
}
</script>
