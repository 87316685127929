<template>
  <div>
    <table
      v-for="(item, index) in parts"
      :key="index"
      :class="small ? 'table-sm' : ''"
      class="table table-bordered table-striped display"
    >
      <tbody>
        <tr class="vm">
          <td style="width: 13%">
            <span v-if="item.is_combined" class="badge badge-pink float-left">組合配件</span>配件編號
          </td>
          <td style="width: 20%">{{ item.alias_id }}</td>
          <td style="width: 13%">配件名稱</td>
          <td style="width: 20%">{{ item.name }}</td>
          <td style="width: 13%">圖面版本</td>
          <td>
            <div v-if="item.drawings && item.drawings.length > 0" class="float-left">
              <span class="badge badge-warning">{{ item.drawings[0].revision }}</span>
              <el-tooltip placement="top" content="點擊查看圖面">
                <a href="#" @click.prevent="showDrawing(item.drawings)"> <fa icon="file-image" /> </a>
              </el-tooltip>
            </div>
          </td>
        </tr>
        <template v-if="!item.is_combined">
          <tr>
            <td>是否開模</td>
            <td><yes-no :status="Boolean(item.is_mold)" is-text /></td>
            <td>組合用量</td>
            <td>
              <span class="badge badge-primary">{{ item.usage }}</span>
            </td>
            <td>配件單位重量</td>
            <td>{{ item.unit_weight | measure(item.measure) }}</td>
          </tr>
          <tr>
            <td>材料</td>
            <td colspan="3">{{ item.material | materialName }}</td>
            <td>材料單數</td>
            <td>
              <span class="badge badge-info">{{ item.material_to_quantity }}</span>
            </td>
          </tr>
        </template>
        <template v-else-if="item.is_combined">
          <tr>
            <td>是否開模</td>
            <td><yes-no :status="Boolean(item.is_mold)" is-text /></td>
            <td>組合用量</td>
            <td>
              <span class="badge badge-primary">{{ item.usage }}</span>
            </td>
            <td>配件單位重量</td>
            <td>{{ item.unit_weight | measure(item.measure) }}</td>
          </tr>
        </template>
        <tr>
          <td>製令工單</td>
          <td colspan="5" class="p-0">
            <span v-if="!item.ticket" class="badge badge-orange ml-1 mt-2">尚未安派製令工單</span>
            <template v-else>
              <table class="table table-sm m-0">
                <thead>
                  <tr>
                    <th style="width: 150px">製令工單編號</th>
                    <th class="text-center">狀態</th>
                    <th class="text-center">種類</th>
                    <th>目前進度</th>
                    <th class="text-right">安排生產數量</th>
                    <th class="text-right">實際生產數量</th>
                    <th class="text-right">交貨日期</th>
                    <th v-if="$store.getters['permission/check']([11])" style="width: 80px" />
                  </tr>
                </thead>
                <tbody>
                  <tr class="vm bg-white">
                    <td class="text-monospace">{{ item.ticket && item.ticket.sno }}</td>
                    <td class="text-center">
                      <b-badge :variant="colors[item.ticket.status]">{{ ticketStatus[item.ticket.status] }}</b-badge>
                    </td>
                    <td class="text-center">
                      <b-badge :variant="colors[item.ticket.type + 1]">{{ ticketTypes[item.ticket.type] }}</b-badge>
                    </td>
                    <td class="table-light">{{ currentProcess(item.ticket) }}</td>
                    <td class="text-right">{{ item.ticket.arrange_quantity }}</td>
                    <td class="text-right">{{ item.ticket.production_quantity || '' }}</td>
                    <td class="text-right">{{ item.ticket.delivery_date | Ymd }}</td>
                    <td v-if="$store.getters['permission/check']([11])" class="text-center">
                      <b-btn size="sm" variant="dark" @click.prevent="onTicketDetail(item.ticket.id)">
                        <fa icon="file" /> 詳細
                      </b-btn>
                    </td>
                  </tr>
                </tbody>
              </table>
            </template>
          </td>
        </tr>
      </tbody>
    </table>
    <part-drawings :drawings="drawings" :visible.sync="visible" />
  </div>
</template>

<script>
import { colors, ticketStatus, ticketTypes } from '@/plugins/consts'
export default {
  name: 'PartOrder',
  props: {
    parts: {
      type: Array,
      default() {
        return []
      },
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      drawings: [],
      visible: false,
      colors,
      ticketStatus,
      ticketTypes,
    }
  },
  methods: {
    showDrawing(drawings) {
      this.drawings = drawings
      this.visible = true
    },
    currentProcess(ticket) {
      if (ticket.status !== 3) {
        return ticketStatus[ticket.status]
      }
      const proc = []
        .concat(ticket.processes)
        .reverse()
        .find((item) => item.status > 2)

      if (proc) {
        let time = proc.begin_at ? ` (${this.$options.filters.humanDate(proc.begin_at)})` : ''
        return proc.default_process.name + time
      }
    },
    onTicketDetail(id) {
      this.$emit('ticket-detail', id)
    },
  },
}
</script>
