<template>
  <div>
    <el-dialog :visible.sync="modalShow" :close-on-click-modal="false" :width="modalWidth" top="5px" append-to-body>
      <template #title>
        <h3 class="m-0">
          {{ actionText + processIncomingText }}
        </h3>
      </template>
      <el-form v-if="modalShow" :ref="formRefs" :model="row" :rules="rules" label-width="120px">
        <div class="form-row">
          <el-form-item class="col-sm-8" :label="processIncomingText + '倉儲'" prop="warehouse_id">
            <el-select v-model.number="row.warehouse_id" :placeholder="`- 請選擇${processIncomingText}倉儲 -`">
              <el-option v-for="item in warehouses" :key="item.id" :label="item.name" :value="Number(item.id)" />
            </el-select>
          </el-form-item>
        </div>
        <div class="form-row">
          <el-form-item
            class="col-sm-8"
            :label="processIncomingText + '數量'"
            prop="quantity"
            :rules="[
              { required: true, message: '請輸入數量', trigger: 'change' },
              { type: 'number', min: 1, message: '數量最少為 1', trigger: 'change' },
            ]"
          >
            <el-input v-model.number="row.quantity" :min="1" type="number" :placeholder="processIncomingText + '數量'">
              <template #append> 個 </template>
            </el-input>
          </el-form-item>
        </div>
        <div class="form-row">
          <el-form-item class="col-sm-8" :label="processIncomingText + '日期'" prop="created_at">
            <el-date-picker
              v-model="row.created_at"
              type="date"
              value-format="yyyy-MM-dd"
              :placeholder="processIncomingText + '日期'"
            />
          </el-form-item>
        </div>
        <div class="form-row">
          <el-form-item class="col-sm-12" label="備註" prop="comment">
            <el-input v-model.trim="row.comment" placeholder="備註" />
          </el-form-item>
        </div>
        <div class="form-row">
          <el-form-item class="col-sm-12 text-right">
            <b-btn variant="secondary" @click.prevent="modalShow = false">
              <fa icon="times-circle" fixed-width />取消
            </b-btn>
            <b-btn type="submit" variant="primary" @click.prevent="onSubmit">
              <fa icon="edit" fixed-width />{{ actionText }}
            </b-btn>
          </el-form-item>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { modify } from '@/plugins/mixins'

export default {
  name: 'ProcessIncomingSetting',
  mixins: [modify],
  props: {
    proc: {
      type: Object,
      default() {
        return {
          id: null,
          default_process_id: null,
        }
      },
    },
  },
  data() {
    return {
      structure: {
        type: this.proc.default_process_id == 1 ? 1 : 0,
        process_id: this.proc.id,
        warehouse_id: null,
        quantity: null,
        created_at: null,
        source: {},
        comment: null,
      },
      rules: {
        warehouse_id: [{ required: true, message: `請選擇倉儲`, trigger: 'change' }],
        created_at: [{ required: true, message: '請選擇日期', trigger: 'blur' }],
      },
      quantities: [],
      // maxQuantity: 1,
      modalMaxWidth: 800,
    }
  },
  computed: {
    processIncomingText() {
      return this.proc.default_process_id == 1 ? '領料' : '點收'
    },
    warehouses() {
      return this.$store.getters['warehouse/all'].filter((item) =>
        this.quantities.map((x) => Number(x.id)).includes(item.id)
      )
    },
  },
  watch: {
    proc: {
      handler(val) {
        this.$set(this.structure, 'process_id', val.id)
        this.$set(this.structure, 'type', val.default_process_id)
        this.quantities = [].concat(this.proc.ticket?.material?.quantity || [])
        // let quantity = this.quantities.find(x => x.id == +this.row.warehouse_id)
        // // console.log(this.proc, this.quantities, quantity)
        // if (quantity === undefined) {
        //   this.quantities.push({ id: +this.row.warehouse_id, qty: +this.row.quantity })
        // } else {
        //   this.quantities.map(item => {
        //     if (item.id == this.row.warehouse_id) {
        //       item.qty += this.row.quantity
        //     }
        //   })
        // }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions('processIncoming', ['fetchOne', 'doCreate', 'doUpdate']),
    // onAfterUpdate(result) {
    //   this.$nextTick(() => {
    //     this.quantities = [].concat(this.proc.ticket.material.quantity)
    //     let quantity = this.quantities.find(x => x.id == +result.warehouse_id)
    //     // console.log(this.proc, this.quantities, quantity)
    //     if (quantity === undefined) {
    //       this.quantities.push({ id: +result.warehouse_id, qty: +result.quantity })
    //     } else {
    //       this.quantities.map(item => {
    //         if (item.id == result.warehouse_id) {
    //           item.qty += result.quantity
    //         }
    //       })
    //     }
    //   })
    // },
  },
}
</script>
