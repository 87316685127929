<template>
  <div>
    <el-dialog :visible.sync="modalShow" :close-on-click-modal="false" :width="modalWidth" top="5px">
      <template #title>
        <h3 class="m-0">
          {{ actionText + '報價' }}
        </h3>
      </template>
      <el-form
        v-if="modalShow"
        :ref="formRefs"
        :model="row"
        :rules="rules"
        :disabled="row.status === 0 || !$store.getters['permission/check']([5])"
        label-width="120px"
      >
        <div class="card">
          <div v-if="row.sno" class="card-header px-2">
            <h5 class="m-0">
              報價編號：<span class="text-monospace">{{ row.sno }}</span>
            </h5>
          </div>
          <div class="card-body p-3">
            <div class="form-row">
              <el-form-item class="col-md-6 col-lg-4" label="客戶" prop="customer_id">
                <el-select v-model="row.customer_id" placeholder="- 請選擇客戶 -" filterable @change="onCustomerChange">
                  <el-option
                    v-for="item in $store.getters['customer/consumers']"
                    :key="item.id"
                    :label="item.name"
                    :value="Number(item.id)"
                  />
                </el-select>
              </el-form-item>
              <el-form-item class="col-md-6 col-lg-4" label="收件人" prop="recipient">
                <el-input v-model="row.recipient" placeholder="收件人" />
              </el-form-item>
              <div class="w-100"></div>
              <el-form-item class="col-md-6 col-lg-4" label="貨幣" prop="currency">
                <el-radio-group v-model="row.currency" @change="onCurrencyChange">
                  <el-radio :label="0">臺幣</el-radio>
                  <el-radio :label="1">美金</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item class="col-md-6 col-lg-4" label="報價公司" prop="company_id">
                <el-select v-model="row.company_id" placeholder="- 請選擇 -">
                  <el-option
                    v-for="item in $store.getters['company/all']"
                    :key="item.id"
                    :label="item.name"
                    :value="Number(item.id)"
                  />
                </el-select>
              </el-form-item>
              <el-form-item class="col-md-6 col-lg-4" label="報價日期" prop="quoted_at">
                <el-date-picker v-model="row.quoted_at" type="date" value-format="yyyy-MM-dd" placeholder="報價日期" />
              </el-form-item>
              <el-form-item class="col-md-6 col-lg-4" label="承辦人" prop="operator_id">
                <el-select v-model="row.operator_id" placeholder="- 請選擇 -">
                  <el-option
                    v-for="item in $store.getters['user/all']"
                    :key="item.id"
                    :label="item.name"
                    :value="Number(item.id)"
                  />
                </el-select>
              </el-form-item>
              <el-form-item class="col-md-6 col-lg-4" label="審核人" prop="approver_id">
                <el-select v-model="row.approver_id" placeholder="- 請選擇 -" clearable>
                  <el-option
                    v-for="item in $store.getters['user/all']"
                    :key="item.id"
                    :label="item.name"
                    :value="Number(item.id)"
                  />
                </el-select>
              </el-form-item>
              <el-form-item
                v-show="row.currency === 1"
                class="col-md-6 col-lg-4"
                label="匯率"
                prop="exchange_rate"
                :rules="[
                  { required: row.currency == 1, message: '請輸入匯率', trigger: 'blur' },
                  { type: 'number', message: '請輸入正確數字', trigger: 'change' },
                ]"
              >
                <el-tooltip content="1 美金兌換多少新臺幣" placement="top">
                  <el-input
                    v-model="row.exchange_rate"
                    :disabled="row.currency === 0"
                    type="number"
                    placeholder="匯率"
                  />
                </el-tooltip>
              </el-form-item>
              <el-form-item class="col-md-6 col-lg-4" label="有效期限" prop="valid_period">
                <el-tooltip content="如不輸入則不會顯使該項說明" placement="top">
                  <el-input v-model="row.valid_period" type="number" placeholder="有效期限">
                    <template #append>天</template>
                  </el-input>
                </el-tooltip>
              </el-form-item>
              <el-form-item class="col-md-6 col-lg-4" label="每頁幾筆" prop="items">
                <el-select v-model="row.items" placeholder="- 請選擇 -">
                  <el-option v-for="(item, index3) in [2, 4, 6]" :key="index3" :label="item + ' 筆'" :value="item" />
                </el-select>
              </el-form-item>
              <el-form-item class="col-md-12 col-lg-8" label="報價備註" prop="comments">
                <el-input
                  v-model="row.comments"
                  :rows="6"
                  type="textarea"
                  placeholder="備註（可使用 Shift + Enter 換行）"
                />
              </el-form-item>
            </div>
          </div>
        </div>
        <h4 class="d-flex mt-3">
          <span>報價產品</span>
          <span v-show="row.status !== 0 && $store.getters['permission/check']([5])" class="ml-auto">
            <b-btn size="sm" variant="success" @click.prevent="onProductAdd">
              <fa icon="plus-circle" fixed-width />新增
            </b-btn>
            <b-btn size="sm" variant="info" @click.prevent="onProductChoose">
              <fa icon="crosshairs" fixed-width />選擇現有
            </b-btn>
          </span>
        </h4>
        <div v-for="(product, index) in row.products" :key="index" class="card w-100 mb-3">
          <div class="card-header d-flex align-items-center px-2 py-2">
            <div v-if="product.total_quantity !== null" class="flex-grow-1">
              <strong>{{ `(${product.alias_id}) ${product.name}` }}</strong>
              <a class="ml-1" href="#" @click.prevent="$refs.productSetting.init(product.id)"
                ><fa icon="edit" fixed-width
              /></a>
            </div>
            <div v-else class="flex-grow-1">
              <div class="row">
                <el-form-item
                  :prop="`products[${index}].alias_id`"
                  :rules="[
                    { required: true, message: ' ', trigger: 'blur' },
                    { validator: doCheckAliasId, trigger: 'blur' },
                  ]"
                  label-width="0"
                  class="col-sm-6 m-0"
                >
                  <el-input v-model="product.alias_id" placeholder="產品編號" />
                </el-form-item>
                <el-form-item
                  :prop="`products[${index}].name`"
                  :rules="{ required: true, message: ' ', trigger: 'blur' }"
                  label-width="0"
                  class="col-sm-6 m-0"
                >
                  <el-input v-model="product.name" placeholder="產品名稱" />
                </el-form-item>
              </div>
            </div>
            <div class="d-flex align-items-center ml-3">
              <span class="mr-3">庫存量：{{ product.total_quantity }}</span>
              <b-btn
                v-show="row.status !== 0 && $store.getters['permission/check']([5])"
                size="sm"
                variant="danger"
                @click.prevent="onProductRemove(index)"
              >
                <fa icon="trash-alt" fixed-width />
              </b-btn>
            </div>
          </div>
          <div class="card-body p-3">
            <div class="form-row">
              <el-form-item
                :prop="`products[${index}].pivot.unit_price`"
                :rules="[
                  { required: true, message: '請輸入單價', trigger: 'blur' },
                  { type: 'number', validator: greaterThan, message: '單價必須大於 0 的數字', trigger: 'change' },
                ]"
                class="col-md-6 col-lg-4"
                label="單價"
              >
                <el-input v-model="product.pivot.unit_price" type="number" placeholder="單價" :min="0">
                  <template #prepend>
                    {{ row.currency | currencySymbol }}
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item
                :prop="`products[${index}].pivot.amount`"
                :rules="{ type: 'number', min: 0, message: '請輸入最少生產數量', trigger: 'change' }"
                class="col-md-6 col-lg-4"
                label="最少生產量"
              >
                <el-input v-model.number="product.pivot.amount" type="number" placeholder="最少生產量" :min="0">
                  <template #append> 個 </template>
                </el-input>
              </el-form-item>
              <el-form-item
                :prop="`products[${index}].pivot.mold_price`"
                :rules="{ type: 'number', min: 0, message: '請輸入模具費用', trigger: 'change' }"
                class="col-md-6 col-lg-4"
                label="模具費用"
              >
                <el-input v-model.number="product.pivot.mold_price" type="number" placeholder="模具費用" :min="0">
                  <template #prepend>
                    {{ row.currency | currencySymbol }}
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item
                :prop="`products[${index}].pivot.is_new`"
                :rules="{ required: true, message: '請選擇是否新品', trigger: 'change' }"
                class="col-md-6 col-lg-4"
                label="新品"
              >
                <el-switch
                  v-model="product.pivot.is_new"
                  :active-value="1"
                  :inactive-value="0"
                  active-text="是"
                  inactive-text="否"
                />
              </el-form-item>
              <el-form-item :prop="`products[${index}].pivot.change`" class="col-md-6 col-lg-4" label="設計變更">
                <el-input v-model="product.pivot.change" placeholder="設計變更" />
              </el-form-item>
              <el-form-item :prop="`products[${index}].pivot.material`" class="col-md-6 col-lg-4" label="材質說明">
                <el-input v-model="product.pivot.material" placeholder="材質說明" />
              </el-form-item>
              <div class="w-100"></div>
              <el-form-item
                :prop="`products[${index}].pivot.days[0]`"
                class="col-md-6 col-lg-4"
                label="開模天數"
                :rules="[{ type: 'number', message: '請確認開模天數', trigger: 'change' }]"
              >
                <el-input v-model.number="product.pivot.days[0]" type="number" :min="0" placeholder="開模天數">
                  <template #append> 天 </template>
                </el-input>
              </el-form-item>
              <el-form-item
                :prop="`products[${index}].pivot.days[1]`"
                class="col-md-6 col-lg-4"
                label="試樣天數"
                :rules="[{ type: 'number', message: '請確認試樣天數', trigger: 'change' }]"
              >
                <el-input v-model.number="product.pivot.days[1]" type="number" :min="0" placeholder="試樣天數">
                  <template #append> 天 </template>
                </el-input>
              </el-form-item>
              <el-form-item
                :prop="`products[${index}].pivot.days[2]`"
                class="col-md-6 col-lg-4"
                label="提早下單天數"
                :rules="[{ type: 'number', message: '請確認提早下單天數', trigger: 'change' }]"
              >
                <el-input v-model.number="product.pivot.days[2]" type="number" :min="0" placeholder="提早下單天數">
                  <template #append> 天 </template>
                </el-input>
              </el-form-item>
              <div class="w-100"></div>
              <el-form-item :prop="`products[${index}].pivot.comment`" class="col-md-12 col-lg-6" label="備註">
                <el-input
                  v-model="product.pivot.comment"
                  :rows="6"
                  type="textarea"
                  placeholder="備註（可使用 Shift + Enter 換行）"
                />
              </el-form-item>
              <el-form-item class="col-md-12 col-lg-6 mb-0" label="圖面">
                <image-upload
                  :image-list="parseDrawing(product.pivot.images)"
                  :column="6"
                  @drawing-change="onDrawingChange(product.id, $event)"
                />
              </el-form-item>
            </div>
          </div>
        </div>
        <div class="form-row mt-3">
          <div class="col text-right">
            <b-btn variant="secondary" @click.prevent="modalShow = false">
              <fa icon="times-circle" fixed-width />取消
            </b-btn>
            <b-btn type="submit" variant="primary" @click.prevent="onSubmit">
              <fa icon="edit" fixed-width />{{ actionText }}
            </b-btn>
          </div>
        </div>
      </el-form>
    </el-dialog>
    <product-choose ref="productChoose" :customer-id="row.customer_id" @done="onProductChosen" />
    <product-setting ref="productSetting" :customer-id="row.customer_id" @done="onProductUpdated" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { modify, greaterThan } from '@/plugins/mixins'

export default {
  name: 'QuotationSetting',
  mixins: [modify, greaterThan],
  data() {
    return {
      structure: {
        id: null,
        sno: null,
        status: 2,
        company_id: this.$store.getters['company/default']?.id,
        customer_id: null,
        items: 6,
        recipient: null,
        operator_id: this.$store.getters['auth/user']?.id,
        approver_id: null,
        currency: 0,
        exchange_rate: 1,
        quoted_at: null,
        valid_period: 30,
        comments: null,
        products: [],
      },
      rules: {
        customer_id: [{ required: true, message: '請選擇客戶', trigger: 'change' }],
        company_id: [{ required: true, message: '請選擇公司', trigger: 'change' }],
        quoted_at: [{ required: true, message: '請選擇報價日期', trigger: 'blur' }],
        currency: [{ required: true, message: '請選擇貨幣', trigger: 'change' }],
        operator_id: [{ required: true, message: '請選擇承辦人', trigger: 'change' }],
        valid_period: [{ required: false, message: '請選擇有效期限', trigger: 'change' }],
        items: [{ required: true, message: '請選擇每頁幾筆', trigger: 'change' }],
      },
    }
  },
  mounted() {
    if (this.$store.state.app.usdToTwd === null) {
      this.fetchExchangeRate()
    }
  },
  methods: {
    ...mapActions('quotation', ['fetchOne', 'doCreate', 'doUpdate']),
    ...mapActions('product', ['checkUnique']),
    ...mapActions('app', ['fetchExchangeRate']),
    async doCheckAliasId(rule, value, callback) {
      if (this.formAction === 'update' || !this.row.customer_id) {
        callback()
      } else {
        const result = await this.checkUnique({ alias_id: value, customer_id: this.row.customer_id })
        if (result !== false) {
          let error = [`編號爲 ${result} 的產品已存在，請重新確認`]
          callback(error)
        }
        callback()
      }
    },
    onDrawingChange(id, images) {
      let product = this.row.products.find((pdt) => pdt.id == +id)
      product.pivot.images = images.map((img) => img.url)
    },
    onProductChoose() {
      if (!this.row.customer_id) {
        this.$message.error('請先選擇客戶')
        return false
      }

      this.$refs.productChoose.init([])
    },
    onProductChosen(product) {
      product.pivot = {
        quotation_id: this.row.id,
        product_id: product.id,
        is_new: 0,
        change: null,
        unit_price: product.unit_price,
        mold_price: null,
        amount: null,
        days: [null, null, null],
        material: null,
        images: [],
        comment: null,
      }

      this.row.products.push(Object.assign({}, product))
    },
    onProductAdd() {
      if (!this.row.customer_id) {
        this.$message.error('請先選擇客戶')
        return false
      }
      const pid = Date.now()
      this.row.products.push({
        id: pid,
        customer_id: this.row.customer_id,
        alias_id: null,
        name: null,
        cost: 0,
        unit_price: 0,
        unit_weight: 0,
        measure: 1,
        quantity: [],
        total_quantity: null,
        safety_stock: 0,
        comment: null,
        pivot: {
          quotation_id: this.row.id,
          product_id: pid,
          is_new: 1,
          change: null,
          unit_price: null,
          mold_price: null,
          amount: null,
          days: [null, null, null],
          material: null,
          images: [],
          comment: null,
        },
      })
    },
    onProductRemove(index) {
      this.$confirm('是否要刪除該產品', '注意', {
        confirmButtonText: '刪除',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.row.products.splice(index, 1)
        })
        .catch(() => {})
    },
    onProductUpdated(product) {
      let pdt = this.row.products.find((p) => p.id == +product.id)
      pdt.alias_id = product.alias_id
      pdt.name = product.name
      this.isDirty = true
    },
    onCustomerChange(id) {
      let customer = this.$store.getters['customer/consumers'].find((item) => item.id == +id)
      this.$set(this.row, 'currency', customer.currency)
      this.$set(this.row, 'recipient', customer.contact || null)
      this.$set(this.row, 'products', [])
      this.onCurrencyChange(customer.currency)
    },
    onCurrencyChange(val) {
      this.$set(this.row, 'exchange_rate', +val == 0 ? 1 : this.$store.getters['app/usdToTwd'])
    },
    parseDrawing(images) {
      return images.length > 0 ? images.map((img) => ({ name: img.split('/').pop(), url: img })) : []
    },
    onBeforeSubmit() {
      if (this.row.products.length < 1) {
        this.$message.error('請新增或選擇產品')
        return false
      }
    },
  },
}
</script>
